<template>
  <div class="main">
    <div class="modal fade" tabindex="-1" aria-hidden="true" ref="errorModal" data-bs-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header border-0 fs-4 pt-3 pb-0">
            <h6 class="modal-title w-100 text-center fw-bold">このメールアドレスは以前使用されていたようです。</h6>
            <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-3 pb-1">
            <p class="text-center login-modal-message">再度利用を希望する場合は<a :href=contact_url>こちら</a>よりお問い合わせください。</p>
          </div>
          <div class="modal-footer py-1 border-0">
            <button type="button" class="btn" data-bs-dismiss="modal">キャンセル</button>
          </div>
        </div>
      </div>
    </div>

    <div class="login">
      <div class="logo">
        <img src="../../assets/img/texta-logo.svg" alt="">
      </div>
      <div class="form">
        <div class="form-header">
          <div class="title mb-0 pb-0">ログイン</div>
          <a href="/landing_page" class="subtitle mt-0 pt-0 text-decoration-underline">Textaとは?</a>
          <hr class="opacity-25 border-secondary">
        </div>
        <div class="form-body">
          <form>
            <div class="input-section">
              <label for="inputEmail" class="form-label input-label">メールアドレス</label>
              <input id="inputEmail" type="email" class="form-control form-input" aria-describedby="emailHelp" v-model="email" placeholder="name@company.com">
              <div v-if="is_authentication" class="w-100 text-danger text-end small">メールアドレスが正しくありません</div>
            </div>
            <div class="input-section">
              <label for="inputPassword" class="form-label input-label">パスワード</label>
              <div class="input-group">
                <input id="inputPassword" :type="is_passwordVisible ? 'text' : 'password'" class="password-input form-control form-input border-end-0" v-model="password">
                <button class="btn form-input border-start-0 border" type="button">
                  <span class="material-symbols-outlined" @click="passwordVisibility">{{ is_passwordVisible ? 'visibility_off' : 'visibility' }}</span>
                </button>
              </div>
              <div v-if="is_authentication" class="w-100 text-danger text-end small">パスワードが正しくありません</div>
            </div>
            <div class="text-section text-end">
              <router-link class="password-reset-link" to="/reset">パスワードをお忘れですか？</router-link>
            </div>
            <button type="submit" @click="performSignIn" class="btn btn-primary login-button">ログインする</button>
            <div class="text-section">
              アカウントがありませんか？<br class="d-sm-none"><a class="link" :href="contact_url">お問い合わせする</a>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="footer p-3">© TECHNODIGITAL All Right Reserved.</div>
  </div>
</template>

<script>
import { inject } from 'vue';
import authentication from "../mixin/authentication";
import { Modal } from 'bootstrap';
export default {
  name: "SignIn",
  mixins: [authentication],
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    return {
      showLoading,
      hideLoading,
    };
  },
  data() {
    return {
      // 判定
      is_authentication: false,
      is_passwordVisible: false,

      // 変数
      email: "",
      password: "",
      error_message: "",
      contact_url: process.env.VUE_APP_CONTACT_URL,
      errorModal: null,
    };
  },
  mounted() {
    this.errorModal = new Modal(this.$refs.errorModal);
    this.hideLoading(); // 認証エラー時にログイン画面を表示する場合の前画面のローディングを消す
  },
  methods: {
    async performSignIn(event){
      const self = this;
      try {
        self.showLoading();
        self.is_authentication = false;
        await self.signIn(event);
      } catch (e) {
        self.hideLoading();
      }
    },
    show: async function() {
      this.errorModal.show();
    },
    hide: function() {
      this.errorModal.hide();
    },
    passwordVisibility() {
      this.is_passwordVisible = !this.is_passwordVisible;
    },
  },
  beforeRouteLeave (to, form, next) {
    this.errorModal.hide();
    next();
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  padding: 25px;
  background-color: var(--white);
  width: 475px;
  border-radius: 10px;
  box-shadow: 30px 30px 50px 0 rgba(158, 159, 168, 0.7);
}

.password-reset-link {
  font-size: 16px;
  color: var(--sub-text);
  text-decoration: underline;
}

.login-button {
  width: 415px;
  height: 45px;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  background-color: var(--primary);
}

.input-section .input-group button {
  color: var(--sub-text);
}
.input-section .input-group button:hover {
  background-color: #fafbff;
}
.input-group:focus-within {
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
  border-radius: 4px;
}
.input-group:focus-within .btn {
  background-color: var(--white);
  border: none!important;
}
.input-group:focus-within .password-input {
  background-color: var(--white);
  border: none!important;
  box-shadow: none!important;
}
.input-group:focus-within .btn:hover {
  background-color: var(--white);
}
.password-input:focus {
  box-shadow: none!important;
  border: none;
}
</style>
