import { useUserInfoStore } from '@/stores/index';
import axios from "@/axios";

export function useUserInfo() {
  const userInfoStore = useUserInfoStore();

  const getUserInfo = async () => {
    await axios
      .get(
        process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_user_info",
      )
      .then((response) => {
        if (response.data.status) {
          userInfoStore.setUserInfo(response.data.user_info);
        }
      })
      .catch((error) => {
      })
  };

  return {
    getUserInfo,
  };
}