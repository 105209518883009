<template>
  <div class="modal fade" id="addUsageTimeModal" ref="confirmModal" tabindex="-1"
    aria-labelledby="addUsageTimeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header fs-4 py-2">
          <h5 class="modal-title">
            プランの開始
          </h5>
          <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-3 pb-1">
          <div v-if="nextMethod == 'dawnPlan'" class="ms-4">
            <p>{{ nextPlanName }}プランへ移行します。</p>
            <p v-if="!isUnpaid">現在のプランは {{ subscriptionPeriodEndDate }} まで利用可能です。</p>
          </div>
          <div v-else class="ms-4">
            <p>決済元クレジットカード</p>
            <p>{{ cardNumber }}（{{ expMonth }}/{{ expYear }}）</p>
            <p v-if="nextMethod == 'startPlan'">{{ planName }}プランを開始します。</p>
            <p v-else>{{ nextPlanName }}プランへ変更します。</p>
            <p v-if="nextMethod == 'startPlan'">契約期間の途中でのプランのダウングレードは出来ません。</p>
            <p v-else>月の途中でのプランのダウングレードは出来ません。</p>
          </div>
        </div>
        <div class="modal-footer py-1 border-0">
          <button type="button" class="btn" @click="hideConfirmModal()">
            キャンセル
          </button>
          <button v-if="nextMethod == 'startPlan'" type="button" class="btn btn-primary" @click="startPlan()">サブスクリプション開始</button>
          <button v-else-if="nextMethod == 'updatePlan'" type="button" class="btn btn-primary" @click="updatePlan()">アップグレード</button>
          <button v-else-if="nextMethod == 'dawnPlan'" type="button" class="btn btn-primary" @click="dawnPlan()">変更</button>
        </div>
      </div>
    </div>
  </div>
  <div class="settings-content">
    <div class="content">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-x-setting" role="tabpanel" aria-labelledby="nav-x-setting-tab">
          <div class="setting-item border-bottom-0">
            <div class="tab-pane" id="personal-payment-plan-setting" role="tabpanel"
              aria-labelledby="personal-payment-plan-setting-tab">
              <div class="page-title ps-0 mb-2">
                <h3>料金プラン</h3>
              </div>
              <div class="setting-titles">
                <a class="settings-selector btn w-100 d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse" href="#settingsCollapse" role="button" aria-expanded="false"
                  aria-controls="settingsCollapse">
                  <h5 class="mb-0">設定: 料金プラン</h5>
                  <span class="material-symbols-outlined fs-1">
                    keyboard_arrow_down
                  </span>
                </a>
                <SettingCollapseList @componentChanged="changeComponent" currentComponent="EditPlan"/>
                <hr class="mt-2">
              </div>
              <div v-if="isEnterprisePlan" class="row w-100 px-2">
                <div v-for="(plan, index) in enterprisePlan" :key="plan.plan_mst_id" class="col-lg-3 col-md-6 p-2">
                  <div class="card plan-card shadow p-3" :class="planStyle(index)">
                    <div class="card-body d-flex flex-column justify-content-start">
                      <h5 class="card-title w-100 text-center">{{ plan.display_plan_name }}</h5>
                      <p v-if="plan.stripe_unit_amount == '0'" class="w-100 text-center mt-1 mb-3"><span class="fw-bold fs-4">要お見積もり</span></p>
                      <p v-else class="w-100 text-center mt-1 mb-3">{{ stripeInterval }}額 <span class="fs-4 fw-bold">{{ plan.stripe_unit_amount }}</span>
                        円（税抜）
                      </p>
                      <div class="d-flex justify-content-around">
                        <p class="col-7 text-start">通常文字起こし</p>
                        <p class="col-5 text-start">無制限</p>
                      </div>
                      <div class="d-flex border-top pt-2 justify-content-around">
                        <p class="col-7 text-start">音声ファイル<br>補正文字起こし</p>
                        <p v-if="plan.stripe_unit_amount == '0'" class="col-5 text-start">要相談</p>
                        <p v-else class="col-5 text-start">{{ plan.usage_add_time }}時間</p>
                      </div>
                      <div class="d-flex border-top pt-2 mb-3 justify-content-around">
                        <p class="col-7 text-start">最大ユーザー数</p>
                        <p v-if="plan.stripe_unit_amount == '0'" class="col-5 text-start">要相談</p>
                        <p v-else class="col-5 text-start">{{ plan.maximum_members_count }}人</p>
                      </div>
                      <div class="mt-auto w-100 d-flex justify-content-center">
                        <a type="button" v-if="isUnpaid" class="btn btn-outline-primary w-75" @click="showConfirmModal('startPlan', plan.display_plan_name, plan.plan_mst_id)">開始</a>
                        <a type="button" v-else-if="planId == plan.plan_mst_id" class="btn border-0 btn-primary pt-2 w-75 pe-none">現在のプラン</a>
                        <a type="button" v-else class="btn btn-outline-primary w-75"
                          @click="requestInformation()">資料請求する</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="Object.keys(privatePlan).length != 0" class="setting-item border-bottom-0">
                <div class="row w-100 px-2">
                  <div class="col-lg-3 col-md-6 p-2">
                    <div class="card plan-card shadow p-3" :class="planStyle(1)">
                      <div class="card-body d-flex flex-column justify-content-start">
                        <h5 class="card-title w-100 text-center">{{ privatePlan[1].display_plan_name }}</h5>
                        <p class="w-100 text-center mt-1 mb-3 fs-4">無料</p>
                        <div class="d-flex justify-content-around">
                          <p class="col-7 text-start">通常文字起こし</p>
                          <p class="col-5 text-start">無制限</p>
                        </div>
                        <div class="mt-auto w-100 d-flex justify-content-center">                          
                          <a type="button" v-if="!isPaidPlan" class="btn border-0 text-primary w-75 pe-none">現在のプラン</a>
                          <a type="button" v-else-if="isNextFreePlan" class="btn border-0 text-primary w-75 pe-none">{{ subscriptionPeriodEndDate }}に自動移行します</a>
                          <a type="button" v-else class="btn btn-outline-primary w-75" @click="showConfirmModal('dawnPlan', privatePlan[1].display_plan_name)">変更</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 p-2">
                    <div class="card plan-card shadow p-3" :class="planStyle(2)">
                      <div class="card-body d-flex flex-column justify-content-start">
                        <h5 class="card-title w-100 text-center">{{ privatePlan[2].display_plan_name }}</h5>
                        <p class="w-100 text-center mt-1 mb-3">月額 <span class="fs-4 fw-bold">{{ privatePlan[2].stripe_unit_amount }}</span> 円（税抜）
                        </p>
                        <div class="d-flex justify-content-around">
                          <p class="col-7 text-start">通常文字起こし</p>
                          <p class="col-5 text-start">無制限</p>
                        </div>
                        <div class="d-flex border-top pt-2 justify-content-around">
                          <p class="col-7 text-start">音声ファイル<br>補正文字起こし</p>
                          <p class="col-5 text-start">{{ privatePlan[2].usage_add_time }}時間</p>
                        </div>
                        <div class="mt-auto w-100 d-flex justify-content-center">
                          <a type="button" v-if="!isPaidPlan" class="btn btn-primary pt-2 w-75" @click="showConfirmModal('updatePlan', privatePlan[2].display_plan_name, privatePlan[2].plan_mst_id)">アップグレード</a>
                          <a type="button" v-else-if="planId == privatePlan[2].plan_mst_id" class="btn border-0 text-primary w-75 pe-none">現在のプラン</a>
                          <a type="button" v-else class="btn border-0 text-primary w-75 pe-none">フリープランへ移行した後に、再度申込ください</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 p-2">
                    <div class="card plan-card shadow p-3" :class="planStyle(3)">
                      <div class="card-body d-flex flex-column justify-content-start">
                        <h5 class="card-title w-100 text-center">{{ privatePlan[3].display_plan_name }}</h5>
                        <p class="w-100 text-center mt-1 mb-3">月額 <span class="fs-4 fw-bold">{{ privatePlan[3].stripe_unit_amount }}</span> 円（税抜）
                        </p>
                        <div class="d-flex justify-content-around">
                          <p class="col-7 text-start">通常文字起こし</p>
                          <p class="col-5 text-start">無制限</p>
                        </div>
                        <div class="d-flex border-top pt-2 justify-content-around">
                          <p class="col-7 text-start">音声ファイル<br>補正文字起こし</p>
                          <p class="col-5 text-start">{{ privatePlan[3].usage_add_time }}時間</p>
                        </div>
                        <div class="mt-auto w-100 d-flex justify-content-center">
                          <a type="button" v-if="planId == privatePlan[3].plan_mst_id" class="btn border-0 text-primary w-75 pe-none">現在のプラン</a>
                          <a type="button" v-else class="btn btn-primary pt-2 w-75" @click="showConfirmModal('updatePlan', privatePlan[3].display_plan_name, privatePlan[3].plan_mst_id)">アップグレード</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 p-2">
                    <div class="card plan-card enterprise shadow p-3">
                      <div class="card-body d-flex flex-column justify-content-start">
                        <h5 class="card-title w-100 text-center">エンタープライズ</h5>
                        <p class="w-100 text-center mt-1 mb-3"><span class="fw-bold fs-4">要お見積もり</span></p>
                        <div class="d-flex justify-content-around">
                          <p class="col-7 text-start">通常文字起こし</p>
                          <p class="col-5 text-start">無制限</p>
                        </div>
                        <div class="d-flex border-top pt-2 justify-content-around">
                          <p class="col-7 text-start">音声ファイル<br>補正文字起こし</p>
                          <p class="col-5 text-start">要相談</p>
                        </div>
                        <div class="mt-auto w-100 d-flex justify-content-center">
                          <a type="button" class="btn btn-outline-primary w-75" @click="requestInformation()">資料請求する</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { usePlanStore } from '@/stores/index';
import { usePlanInfo } from "@/components/composables/planInfo";
import { Modal } from 'bootstrap';
// modules
import SettingCollapseList from "@/components/modules/SettingCollapseList.vue";

// mixin
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import StripeFunc from "@/components/mixin/StripeFunc.js";
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import UsageTime from "@/components/mixin/usageTime.js";

import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import axios from "@/axios";

export default {
  mixins: [BaseFunctions, ArrangeDate, StripeFunc, UsageTime],
  name: "EditPlan",
  emits: ['componentChanged'],
  components: {
    SettingCollapseList,
  },
  data() {
    return {
      confirmModal: null,
      nextPlanName:"",
      nextMethod:"",
      nextPlanMstId: "",

      // plans
      enterprisePlan: {},
      privatePlan: {},
      stripeInterval: "月",
      planStyles: ['light', 'standard', 'pro', 'enterprise'],

      // stripe
      cardNumber: "",
      expYear: "",
      expMonth: "",
    };
  },
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    const planStore = usePlanStore();
    const {
      isEnterprisePlan,
      isCardPayment,
      isUnpaid,
      planId,
      planName,
      isPaidPlan,
      isCardRegistration,
      isNextFreePlan,
      stripeUnitAmount,
      usageAddTime,
      maximumMembersCount,
      subscriptionPeriodEndDate,
    } = storeToRefs(planStore);
    
    return {
      showLoading,
      hideLoading,
      isEnterprisePlan,
      isCardPayment,
      isUnpaid,
      planId,
      planName,
      isPaidPlan,
      isCardRegistration,
      isNextFreePlan,
      stripeUnitAmount,
      usageAddTime,
      maximumMembersCount,
      subscriptionPeriodEndDate,
    }
  },
  async created() {
    await this.getPlans();
  },
  mounted() {
    this.confirmModal = new Modal(this.$refs.confirmModal);
  },
  methods: {
    changeComponent(componentName) {
      this.$emit('componentChanged', componentName);
    },
    // プラン情報取得
    async getPlans() {
      this.showLoading();
      await axios
        .get(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/get_plans",
        )
        .then((response) => {
          if (response.data.status) {
            const plans = response.data.plans;
            plans.forEach(plan => {
              if (this.isEnterprisePlan && this.isCardPayment) {
                // 企業型クレジットカード払いの場合（現在のプランしか表示しない && 年額表示）
                if (this.planId == plan.plan_mst_id) {
                  const calcUsageAddTime = this.usageAddTime / 3600;
                  this.enterprisePlan[plan.display_order - 4] = {
                    display_plan_name: this.planName,
                    maximum_members_count: this.maximumMembersCount.toLocaleString(),
                    plan_mst_id: this.planId,
                    stripe_unit_amount: this.stripeUnitAmount.toLocaleString(),
                    usage_add_time: calcUsageAddTime.toLocaleString()
                  };
                  this.stripeInterval = "年";
                }
              } else if (this.isCardPayment && plan.display_plan_category == 1) {
                // 個人型クレジットカード払いの場合
                this.privatePlan[plan.display_order] = plan;
              } else if (this.isEnterprisePlan && plan.display_plan_category == 2 && plan.stripe_interval == "month") {
                // 企業型請求書払いの場合
                this.enterprisePlan[plan.display_order] = plan;
              }
            });
          } else {
            alert("プラン情報の取得に失敗しました。\n再度お試しください。");
          }
        })
        .catch(() => {
          alert("プラン情報の取得に失敗しました。\n再度お試しください。");
        });
      this.hideLoading();
    },
    requestInformation() {
      const contactUrl = `${process.env.VUE_APP_LP_URL}/contact/`;
      window.open(contactUrl, '_blank')
    },
    
    planStyle(index) {
      if (index <= this.planStyles.length) {
        return this.planStyles[index-1];
      } else {
        return this.planStyles[this.planStyles.length - 1];
      }
    },
    // 企業型プランの開始
    async startPlan() {
      if (!this.isCardRegistration) {
        this.requestRegisterCard();
      } else {
        this.showLoading();
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/start_plan"
          )
          .then((response) => {
            if (response.data.status) {
              this.successEditPlan();
            } else {
              alert("予期せぬエラーが発生しました。\n決済は中断されます。");
            }
          })
          .catch(() => {
            alert("予期せぬエラーが発生しました。\n決済は中断されます。");
          });
        this.hideConfirmModal();
        this.hideLoading();
      }
    },
    // 個人型プランのアップグレード
    async updatePlan() {
      if (!this.isCardRegistration) {
        this.requestRegisterCard();
      } else {
        this.showLoading();
        const params = {
          ongoing_subscription: !this.isUnpaid,
          next_plan_mst_id: this.nextPlanMstId,
        };
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/update_plan",
            params
          )
          .then((response) => {
            if (response.data.status) {
              this.successEditPlan(response.data.usage_add_time);
            } else {
              alert("予期せぬエラーが発生しました。\n決済は中断されます。");
            }
          })
          .catch(() => {
            alert("予期せぬエラーが発生しました。\n決済は中断されます。");
          });
        this.hideConfirmModal();
        this.hideLoading();
      }
    },
    // 個人型プランのフリーへの変更
    async dawnPlan() {
      this.showLoading();
      const params = {
        is_unpaid: this.isUnpaid,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/stop_plan",
          params
        )
        .then((response) => {
          if (response.data.status) {
            this.successChangeFreePlan();
          } else {
            alert("予期せぬエラーが発生しました。\n決済は中断されます。");
          }
        })
        .catch(() => {
          alert("予期せぬエラーが発生しました。\n決済は中断されます。");
        });
      this.hideConfirmModal();
      this.hideLoading();
    },
    async successEditPlan(usage_add_time = null) {
      await usePlanInfo().getPlanInfo();
      if (usage_add_time != null) {
        await this.addUsageTime(usage_add_time);
      }
      alert("プランが開始されました。");
      this.changeComponent("ContractDetails");
    },
    async successChangeFreePlan() {
      await usePlanInfo().getPlanInfo();
      alert("フリープランへ移行しました。");
      this.changeComponent("EditProfile");
    },
    async showConfirmModal(method, planName, planMstId = null) {
      if (!this.isCardRegistration) {
        this.requestRegisterCard();
      } else {
        if (planMstId != null) {
          // stripe情報取得
          this.showLoading();
          await this.getStripeInfo().then((res) => {
            if (res.status) {
              this.cardNumber = res.cardNumber;
              this.expYear = res.expYear;
              this.expMonth = res.expMonth;
            } else {
              alert("カード情報の取得に失敗しました。");
              return;
            }
          });
          this.hideLoading();
        }
        this.nextMethod = method;
        this.nextPlanName = planName;
        if (method == "updatePlan") {
          this.nextPlanMstId = planMstId;
        }
        this.confirmModal.show();
      }
    },
    hideConfirmModal() {
      this.confirmModal.hide();
    },
    requestRegisterCard() {
      alert("カード情報の登録が必要です。");
      // 支払い情報登録画面へ遷移
      this.changeComponent("PaymentManagement");
    },
  }
};
</script>

<style scoped>
.setting-item .setting-name {
  font-size: 16px;
}

.setting-item .text-secondary {
  font-size: 0.8rem;
}

.setting-item {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--actived);
}

.plan-card {
  border: none;
  border-top: 12px solid;
  height: calc(45 * var(--screen-height));
  min-height: 400px;
}

.plan-card .border-top {
  align-items: center;
}

.plan-card.recommend-plan .border-top {
  border-top: 1px solid var(--sub-primary-text) !important;
}

.plan-card p {
  font-size: 0.8rem;
}

.plan-card h5 {
  font-size: 1.6rem;
}

.plan-card.light {
  border-color: var(--hoved);
}

.plan-card.standard {
  border-color: var(--standard);
}

.plan-card.pro {
  border-color: var(--primary);
}

.plan-card.enterprise {
  border-color: var(--icon-target);
}
</style>
