<template>
  <div class="main">
    <div class="login">
      <div class="logo">
        <img src="../../assets/img/texta-logo.svg" alt="">
      </div>
      <div class="form">
        <div class="form-header">
          <div class="title mb-0 pb-0">パスワードをお忘れの方</div>
          <div class="subtitle mt-0 pt-0">登録済みのメールアドレスを入力してください</div>
          <hr class="opacity-25 border-secondary">
        </div>
        <div class="form-body">
          <div class="input-section">
            <label for="inputEmail" class="form-label input-label">メールアドレス</label>
              <input type="email" v-model="email" class="form-control form-input" id="inputEmail" aria-describedby="emailHelp"
                placeholder="name@company.com">
                <div v-if="is_authentication" class="w-100 text-danger text-end small">メールアドレスが正しくありません</div>
          </div>
          <div class="text-section text-end">
            <router-link to="/signin" class="password-reset-link">ログインに戻る</router-link>
          </div>
          <button class="btn btn-primary login-button" @click="passwordReset()">再設定のためのメールを送信する</button>
          <div class="text-section">
            アカウントがありませんか？<br class="d-sm-none">
            <a class="link" href="https://texta.cloud/contact/">お問い合わせする</a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer p-3">© TECHNODIGITAL All Right Reserved.</div>
  </div>
</template>

<script>
import { inject } from 'vue';
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

export default {
  name: "ResetPassword",
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    return {
      showLoading,
      hideLoading,
    };
  },
  data() {
    return {
      email: "",
      is_authentication: false,
    };
  },
  methods: {
    async passwordReset() {
      const self = this;
      self.showLoading();
      var auth = firebase.auth();
      var emailAddress = this.email;
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function() {
          // Email sent.
          alert("パスワードリセットのメールを送信しました");
        })
        .catch(function(error) {
          // An error happened.        
          if (error.code == "auth/invalid-email") {
            self.is_authentication = true;
          } else if (error.code == "auth/user-not-found") {
            self.is_authentication = true;
          } else {
            alert(error.message);
          }
        }).finally(() => {
          self.hideLoading();
        });
    },
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form {
  padding: 25px;
  background-color: var(--white);
  width: 475px;
  border-radius: 10px;
  box-shadow: 30px 30px 50px 0 rgba(158, 159, 168, 0.7);
}

.password-reset-link {
  font-size: 16px;
  color: var(--sub-text);
  text-decoration: underline;
}

.login-button {
  width: 415px;
  height: 45px;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  background-color: var(--primary);
}

.input-section .input-group button {
  color: var(--sub-text);
}
.input-section .input-group button:hover {
  background-color: #fafbff;
}
.input-group:focus-within {
  box-shadow: 0 0 0 .25rem rgba(13, 110, 253, .25);
  border-radius: 4px;
}
.input-group:focus-within .btn {
  background-color: var(--white);
  border: none!important;
}
.input-group:focus-within .password-input {
  background-color: var(--white);
  border: none!important;
  box-shadow: none!important;
}
.input-group:focus-within .btn:hover {
  background-color: var(--white);
}
.password-input:focus {
  box-shadow: none!important;
  border: none;
}
</style>
