<template>
  <div class="main-container">
    <div class="page-title">
      <h3>{{ pageTitle }}</h3>
    </div>
    <div class="page-main">
      <div class="result-transcribe d-flex">
        <div class="details-section">
          <div class="data-tabs">
            <nav>
              <div
                class="nav nav-tabs d-flex justify-content-between"
                id="nav-tab"
                role="tablist"
              >
                <div class="left-tabs d-flex">
                  <button
                    class="nav-link active"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    議事録
                  </button>
                  <button
                    class="nav-link"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    文字起こし翻訳
                  </button>
                </div>
                <div class="right-tabs d-flex">
                  <button
                    class="nav-link"
                    id="nav-contact-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-contact"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    詳細情報
                  </button>
                </div>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div class="tab-description mt-2 mb-1">
                  <span
                    >作成した議事録は取り消しすることで再度設定を変更し作成し直すことができます。</span
                  >
                </div>
                <div class="meeting-minutes-content">
                  <MeetingMinutes
                    :isMeetingMinutesLoading="isMeetingMinutesLoading"
                    :fileInfo="fileInfo"
                    :errors="errors"
                    :minutesLanguage="minutesLanguage"
                    :isMinutesEdit="isMinutesEdit"
                    :meetingMinutesToHtml="meetingMinutesToHtml"
                    :meetingMinutesContent="meetingMinutesContent"
                    :isPaidPlan="isPaidPlan"
                    @setMinutesLanguage="setMinutesLanguage"
                    @createMinutes="createMinutes"
                    @downloadMinutes="downloadMinutes"
                    @copyMinutesToClipboard="copyMinutesToClipboard"
                    @deleteMinutes="deleteMinutes"
                    @editMinutes="editMinutes"
                    @cancelEditMinutes="cancelEditMinutes"
                    @saveEditMinutes="saveEditMinutes"
                    @editMinutesContent="editMinutesContent"
                  />
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div class="tab-description mt-2 mb-1">
                  <span>翻訳する言語を設定できます。</span>
                </div>
                <TranscribeTranslation
                  :fileInfo="fileInfo"
                  :isTranslationLoading="isTranslationLoading"
                  :translationResults="translationResults"
                  :translateLanguage="translateLanguage"
                  :participantDisplay="participantDisplay"
                  :timestampDisplay="timestampDisplay"
                  :isPaidPlan="isPaidPlan"
                  :isAudioLoading="isAudioLoading"
                  @seekAudioByBlock="seekAudioByBlock"
                  @setTranslateLanguage="setTranslateLanguage"
                  @createTranslation="createTranslation"
                  @deleteTranslation="deleteTranslation"
                  @copyTranslationToClipboard="copyTranslationToClipboard"
                  @downloadTranslation="downloadTranslation"
                />
              </div>
              <div
                class="tab-pane fade"
                id="nav-contact"
                role="tabpanel"
                aria-labelledby="nav-contact-tab"
              >
                <div class="tab-description mt-2 mb-1">
                  <span>ファイル詳細</span>
                </div>
                <FileInfo
                  :fileInfo="fileInfo"
                  :errors="errors"
                  :isDetailInfoUpdate="isDetailInfoUpdate"
                  :inputMeetingName="inputMeetingName"
                  :isFileInfoLoading="isFileInfoLoading"
                  @editFileInfo="editFileInfo"
                  @cancelUpdateFileInfoDetail="cancelUpdateFileInfoDetail"
                  @updateFileInfoDetail="updateFileInfoDetail"
                />
              </div>
            </div>
          </div>
        </div>
        <TranscribeResult
          ref="transcribeResult"
          :fileInfo="fileInfo"
          :textContents="textContents"
          :isTranscribeEdit="isTranscribeEdit"
          :timestampDisplay="timestampDisplay"
          :participantDisplay="participantDisplay"
          :isPlaying="isPlaying"
          :currentTime="currentTime"
          :audioDuration="audioDuration"
          :isTranscribeLoading="isTranscribeLoading"
          :isAudioLoading="isAudioLoading"
          @togglePlayPause="togglePlayPause"
          @downloadAudio="downloadAudio"
          @updateProgress="updateProgress"
          @updateAudioDuration="updateAudioDuration"
          @seekAudio="seekAudio"
          @transcribeEdit="transcribeEdit"
          @cancelTranscribeEdit="cancelTranscribeEdit"
          @saveTranscribeEdit="saveTranscribeEdit"
          @downloadTranscribe="downloadTranscribe"
          @copyTranscribeToClipboard="copyTranscribeToClipboard"
          @seekAudioByBlock="seekAudioByBlock"
          @playAudioByBlock="playAudioByBlock"
          @draftTranscribeEdit="draftTranscribeEdit"
          @setTimedisplay="setTimedisplay"
          @setParticipantDisplay="setParticipantDisplay"
        >
        </TranscribeResult>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { marked } from "marked";
import { remark } from "remark";
import { useForm, useField } from "vee-validate";
import axios from "@/axios";
import toggleFunctions from "../mixin/toggleFunctions";
import Authority from "@/components/mixin/authority.js";
import UsageTime from "../mixin/usageTime";
import * as yup from "yup";
import stripMarkdown from "strip-markdown";
import DOMPurify from "dompurify";
import TranscribeResult from "@/components/modules/TranscribeResult.vue";
import MeetingMinutes from "@/components/modules/MeetingMinutes.vue";
import FileInfo from "@/components/modules/FileInfo.vue";
import TranscribeTranslation from "@/components/modules/TranscribeTranslation.vue";
import { usePlanStore } from '@/stores/index';

export default {
  name: "Detail",
  mixins: [toggleFunctions, Authority, UsageTime],
  components: {
    TranscribeResult,
    MeetingMinutes,
    FileInfo,
    TranscribeTranslation,
  },
  setup() {
    const schema = yup.object({
      inputMeetingName: yup
        .string()
        .required("会議タイトルは必須です")
        .max(50, "50文字以内で設定してください。"),
      inputParticipants: yup
        .string()
        .max(100, "100文字以内で設定してください。")
        .nullable(),
      inputLocation: yup
        .string()
        .max(50, "50文字以内で設定してください。")
        .nullable(),
      inputPurpose: yup
        .string()
        .max(100, "100文字以内で設定してください。")
        .nullable(),
    });

    const { validate, errors } = useForm({
      validationSchema: schema,
    });

    const { value: inputMeetingName } = useField("inputMeetingName");
    const { value: inputParticipants } = useField("inputParticipants");
    const { value: inputLocation } = useField("inputLocation");
    const { value: inputPurpose } = useField("inputPurpose");

    const planStore = usePlanStore();
    const isPaidPlan = planStore.isPaidPlan;

    return {
      validate,
      errors,
      inputMeetingName,
      inputParticipants,
      inputLocation,
      inputPurpose,
      isPaidPlan,
    };
  },
  data() {
    return {
      pageTitle: "ファイル詳細",
      language: "ja-JP",
      fileId: "",
      fileInfo: {},
      draftFileInfo: {},
      transcriptionResults: [],
      minutesResults: [],
      translationResults: [],
      isDetailInfoUpdate: false,
      isPlaying: false,
      currentTime: 0,
      audioDuration: 0,
      participantDisplay: true,
      timestampDisplay: true,
      textContents: {},
      draftContents: {},
      isTranscribeEdit: false,
      minutesLanguage: "日本語",
      isMinutesEdit: false,
      isFileInfoLoading: true,
      isMeetingMinutesLoading: true,
      isTranslationLoading: true,
      isTranscribeLoading: true,
      isAudioLoading: true,
      meetingMinutesContent: "",
      draftMeetingMinutes: "",
      playingBlockIndex: 0,
      translateLanguage: "en",
      meetingMinutesIntervalId: null,
      translationIntervalId: null,
    };
  },
  computed: {
    recordedLanguage() {
      const languageMap = {
        "ja-JP": "日本語",
        "en-US": "英語",
        "zh-CN": "中国語",
        "zh-HK": "香港",
        "zh-TW": "台湾",
        "ko-KR": "韓国語",
        "hi-IN": "ヒンディー語",
        "es-ES": "スペイン語",
        "ru-RU": "ロシア語",
        "pt-BR": "ポルトガル語",
        "fr-FR": "フランス語",
        "de-DE": "ドイツ語",
      };

      return languageMap[this.fileInfo.recorded_language] || "--";
    },
    meetingMinutesToHtml() {
      const html = marked.parse(this.meetingMinutesContent);
      return DOMPurify.sanitize(html);
    },
    meetingMinutesToPlainText() {
      // markdown形式を削除
      let plainText = remark()
        .use(stripMarkdown)
        .processSync(this.meetingMinutesContent)
        .toString();
      // エスケープ文字列（\）を削除
      plainText = plainText
        .replace(/([^\\])\\([^\\])/g, "$1$2")
        .replace(/\\\\/g, "\\");
      return plainText;
    },
  },
  methods: {
    async getInitialData() {
      await this.getFileDetail();
      if (this.fileInfo.audio_path) {
        await this.$refs.transcribeResult.updateAudioSource(this.fileInfo.audio_path);
      }
    },
    async getFileDetail() {
      const params = {
        file_id: this.fileId,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_file_detail",
          params
        )
        .then((response) => {
          const { text_contents, minutes_content, translation_contents,...fileInfo } = response.data;
          this.fileInfo = fileInfo;
          this.pageTitle = this.fileInfo.meeting_name;
          this.textContents = text_contents.map((item, index) => ({
            ...item,
            id: index,
          }));
          this.meetingMinutesContent =
            minutes_content !== null
              ? minutes_content
              : this.meetingMinutesContent;
          this.translationResults = 
            translation_contents !== null
              ? translation_contents
              : this.translationResults;
          this.isFileInfoLoading = false;
          this.isTranscribeLoading = false;
          if (!this.fileInfo.audio_path) {
            this.isAudioLoading = false;
          }
          if (this.fileInfo.translation_status === 0) {
            this.isTranslationLoading = true;
            // 10秒ごとに翻訳が完了しているかチェック
            this.translationIntervalId = setInterval(this.checkCompleteTranslation, 10000);
          } else {
            this.isTranslationLoading = false;
          }
          if (this.fileInfo.minutes_status === 0) {
            this.isMeetingMinutesLoading = true;
            // 10秒ごとに議事録が完了しているかチェック
            this.meetingMinutesIntervalId = setInterval(this.checkCompleteMeetingMinutes, 10000);
          } else {
            this.isMeetingMinutesLoading = false;
          }
          this.participantDisplay =
            this.fileInfo.participants_display === 1 ? true : false;
          this.timestampDisplay =
            this.fileInfo.time_display === 1 ? true : false;
          this.inputMeetingName = this.fileInfo.meeting_name;
          this.inputParticipants = this.fileInfo.meeting_participants;
          this.inputLocation = this.fileInfo.meeting_location;
          this.inputPurpose = this.fileInfo.meeting_purpose;
        });
    },
    async checkCompleteMeetingMinutes() {
      const params = {
          file_id: this.fileId,
        };
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
              "/texta-basic-api/get_meeting_minutes",
            params
          )
          .then((response) => {
            if (response.data.status) {
              if (response.data.meeting_minutes_status === 1) {
                // 議事録作成完了
                this.meetingMinutesContent = response.data.minutes_content;
                this.fileInfo.minutes_path = response.data.file_path;
                this.fileInfo.meeting_minutes_updated_at = response.data.meeting_minutes_updated_at;

                clearInterval(this.meetingMinutesIntervalId);
                this.isMeetingMinutesLoading = false;
              }
            } else {
              // 議事録が存在しない場合
              clearInterval(this.meetingMinutesIntervalId);
              this.isMeetingMinutesLoading = false;
            }
          });
    },
    async checkCompleteTranslation() {
      const params = {
          file_id: this.fileId,
        };
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
              "/texta-basic-api/get_translation",
            params
          )
          .then((response) => {
            if (response.data.status) {
              if (response.data.translation_status === 1) {
                // 翻訳作成完了
                this.translationResults = response.data.translation_contents;
                this.fileInfo.translation_path = response.data.file_path;
                this.fileInfo.translation_updated_at = response.data.translation_updated_at;

                clearInterval(this.translationIntervalId);
                this.isTranslationLoading = false;
              }
            } else {
              // 翻訳が存在しない場合
              clearInterval(this.translationIntervalId);
              this.isTranslationLoading = false;
            }
          });
    },
    async updateFileInfoDetail() {
      this.isFileInfoLoading = true;
      const result = await this.validate();
      if (result.valid) {
        const params = {
          file_id: this.fileId,
          file_info: this.fileInfo,
        };
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
              "/texta-basic-api/update_file_detail",
            params
          )
          .then((response) => {
            if (response.data.status) {
              this.isDetailInfoUpdate = false;
              this.pageTitle = this.fileInfo.meeting_name;
              this.fileInfo.updated_at = response.data.file.updated_at;
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            alert("ファイル情報の更新に失敗しました");
          })
          .finally(() => {
            this.isFileInfoLoading = false;
          });
      } else {
        this.isFileInfoLoading = false;
      }
    },
    async updateTextDisplaySetting() {
      this.isTranscribeLoading = true;
      const params = {
        file_id: this.fileId,
        time_display: this.timestampDisplay === true ? 1 : 0,
        participants_display: this.participantDisplay === true ? 1 : 0,
        updated_at: this.fileInfo.text_updated_at,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/update_text_display_setting",
          params
        )
        .then((response) => {
          if (response.data.status) {
            this.fileInfo.text_updated_at = response.data.file.updated_at;
          } else {
            alert(response.data.message);
          }
        })
        .finally(() => {
          this.isTranscribeLoading = false;
        });
    },
    editFileInfo() {
      this.draftFileInfo = Object.assign({}, this.fileInfo);
      this.isDetailInfoUpdate = true;
    },
    cancelUpdateFileInfoDetail() {
      this.fileInfo = this.draftFileInfo;
      this.isDetailInfoUpdate = false;
    },
    togglePlayPause() {
      const audio = this.$refs.transcribeResult.$refs.player;
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
      } else {
        audio.play();
        this.isPlaying = true;
      }
    },
    downloadAudio() {
      const link = document.createElement("a");
      link.href = this.fileInfo.audio_path;
      link.download = "audio.webm";
      link.click();
    },
    updateProgress() {
      const audio = this.$refs.transcribeResult.$refs.player;
      this.currentTime = audio.currentTime;
      this.updatePlayingBlockIndex();
    },
    updateAudioDuration() {
      // 音声ファイルがある場合
      const audio = this.$refs.transcribeResult.$refs.player;
      this.seekAudio(7*24*60*1000);
      audio.onseeked = () => {
        audio.onseeked = undefined;        
        this.seekAudio(0);
        this.playingBlockIndex = 0;
        this.audioDuration = Math.floor(audio.duration);
        this.isAudioLoading = false;
      };
    },
    seekAudio(time) {
      this.currentTime = time;
      const audio = this.$refs.transcribeResult.$refs.player;
      audio.currentTime = this.currentTime;
    },
    formatTime(seconds) {
      if (isNaN(seconds) || seconds === Infinity) {
        return "-";
      }
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    },
    async downloadTranscribe() {
      const content = this.textContents
        .map((item) => {
          let textContent = "";
          if (this.timestampDisplay) {
            textContent += `[${item.time}] `;
          }
          if (this.participantDisplay) {
            textContent += `${item.participant}: `;
          }
          textContent += `${item.dialogue}\n`;

          return textContent;
        })
        .join("");

      const blob = new Blob([content], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "transcribe.txt";
      link.click();
    },
    transcribeEdit() {
      const audio = this.$refs.transcribeResult.$refs.player;
      if (this.isPlaying) {
        audio.pause();
        this.isPlaying = false;
      }
      this.draftContents = this.textContents.map((item) => {
        return {
          id: item.id,
          time: item.time,
          participant: item.participant,
          dialogue: item.dialogue,
        };
      });
      this.isTranscribeEdit = !this.isTranscribeEdit;
    },
    async cancelTranscribeEdit() {
      this.textContents = this.draftContents.map((item) => {
        return {
          id: item.id,
          time: item.time,
          participant: item.participant,
          dialogue: item.dialogue,
        };
      });
      this.isTranscribeEdit = false;
      this.isPlaying = false;
      const blocks = document.querySelectorAll(".transcribe-block");
      blocks.forEach((block) => block.classList.remove("active"));
    },
    async saveTranscribeEdit() {
      this.isTranscribeLoading = true;
      const updatedContent = this.textContents
        .map((item) => {
          return `[${item.time}] ${item.participant}: ${item.dialogue}\n`;
        })
        .join("");
      const params = {
        file_id: this.fileId,
        text_contents: updatedContent,
        updated_at: this.fileInfo.text_updated_at,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/update_transcribe",
          params
        )
        .then((response) => {
          if (response.data.status) {
            this.fileInfo.text_updated_at = response.data.file.updated_at;
            this.isTranscribeEdit = false;
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("文字起こしの更新に失敗しました");
        })
        .finally(() => {
          this.isTranscribeLoading = false;
        });
    },
    copyTranscribeToClipboard() {
      const content = this.textContents
        .map((item) => {
          let textContent = "";
          if (this.timestampDisplay) {
            textContent += `[${item.time}] `;
          }
          if (this.participantDisplay) {
            textContent += `${item.participant}: `;
          }
          textContent += `${item.dialogue}\n`;

          return textContent;
        })
        .join("");
      navigator.clipboard.writeText(content);
      this.$toasted.show("文字起こしをクリップボードへコピーしました!", {
        className: "bg-primary rounded",
        icon: "fa-copy",
      });
    },
    async createMinutes() {
      this.isMeetingMinutesLoading = true;
      const result = await this.validate();
      if (result.valid) {
        const params = {
          file_id: this.fileId,
          minutes_language: this.minutesLanguage,
          file_info: this.fileInfo,
          updated_at: this.fileInfo.updated_at,
        };
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
              "/texta-basic-api/create_meeting_minutes",
            params
          )
          .then((response) => {
            if (response.data.status) {
              this.pageTitle = this.fileInfo.meeting_name;
              this.fileInfo.updated_at = response.data.file.updated_at;
              // 10秒ごとに議事録が完了しているかチェック
              this.meetingMinutesIntervalId = setInterval(this.checkCompleteMeetingMinutes, 10000);
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            alert("議事録の作成に失敗しました");
            this.isMeetingMinutesLoading = false;
          });
      } else {
        this.isMeetingMinutesLoading = false;
      }
    },
    downloadMinutes() {
      const blob = new Blob([this.meetingMinutesToPlainText], {
        type: "text/plain",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "meeting_minutes.txt";
      link.click();
    },
    copyMinutesToClipboard(mode) {
      if (mode === "plain") {
        navigator.clipboard.writeText(this.meetingMinutesToPlainText);
      } else {
        navigator.clipboard.writeText(this.meetingMinutesContent);
      }
      this.$toasted.show("議事録をクリップボードへコピーしました!", {
        className: "bg-primary rounded",
        icon: "fa-copy",
      });
    },
    deleteMinutes() {
      if (confirm(this.fileInfo.meeting_name + "の議事録を削除しますか？")) {
        this.isMeetingMinutesLoading = true;
        const params = {
          file_id: this.fileId,
          updated_at: this.fileInfo.meeting_minutes_updated_at,
        };
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT +
              "/texta-basic-api/delete_meeting_minutes",
            params
          )
          .then((response) => {
            if (response.data.status) {
              this.meetingMinutesContent = "";
              this.fileInfo.minutes_path = false;
              this.fileInfo.meeting_minutes_updated_at = null;
              this.fileInfo.minutes_content = null;
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            alert("議事録の削除に失敗しました");
          })
          .finally(() => {
            this.isMeetingMinutesLoading = false;
          });
      }
    },
    convertToSeconds(timeString) {
      let [minutes, seconds] = timeString.split(":").map(Number);
      return minutes * 60 + seconds;
    },
    seekAudioByBlock(time) {
      const audio = this.$refs.transcribeResult.$refs.player;
      this.currentTime = this.convertToSeconds(time);
      if (!this.isTranscribeEdit) {
        audio.currentTime = this.currentTime;
        if (!this.isPlaying) {
          audio.play();
          this.isPlaying = true;
        }
      }
    },
    playAudioByBlock(time, index) {
      const audio = this.$refs.transcribeResult.$refs.player;
      this.currentTime = this.convertToSeconds(time);
      audio.currentTime = this.currentTime;
      if (this.isPlaying && this.playingBlockIndex == index) {
        audio.pause();
        this.isPlaying = false;
      } else if (!this.isPlaying) {
        audio.play();
        this.isPlaying = true;
        this.playingBlockIndex = index;
      }
    },
    scrollToIndex(index) {
      this.$nextTick(() => {
        const blocks = document.querySelectorAll(".transcribe-block, .translation-block");
        blocks.forEach((block) => block.classList.remove("active"));
        const playingBlock = document.getElementById(`transcribeBlock${index}`);
        const translateBlock = document.getElementById(`translationBlock${index}`);
        if (playingBlock) {
          playingBlock.classList.add("active");
          if(window.innerWidth > 839) {
            playingBlock.scrollIntoView({ behavior: "smooth" });
          }
          if (translateBlock) {
            translateBlock.classList.add("active");
            if(window.innerWidth > 839) {
              translateBlock.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
      });
    },
    updatePlayingBlockIndex() {
      const audio = this.$refs.transcribeResult.$refs.player;
      let next_time = this.textContents[this.playingBlockIndex + 1]?.time;
      this.textContents.forEach((content, index) => {
        if (this.convertToSeconds(content.time) <= this.currentTime) {
          this.playingBlockIndex = index;
        }
      });
      this.scrollToIndex(this.playingBlockIndex);
      if (next_time && this.isTranscribeEdit) {
        if (audio.currentTime >= this.convertToSeconds(next_time) - 0.5) {
          audio.pause();
          this.isPlaying = false;
        }
      }
    },
    draftTranscribeEdit(index, attr, $event) {
      this.textContents[index][attr] = $event.target.value;
    },
    editMinutes() {
      this.draftMeetingMinutes = this.meetingMinutesContent;
      this.isMinutesEdit = true;
    },
    cancelEditMinutes() {
      this.meetingMinutesContent = this.draftMeetingMinutes;
      this.isMinutesEdit = false;
    },
    editMinutesContent(content) {
      this.meetingMinutesContent = content;
    },
    saveEditMinutes() {
      this.isMeetingMinutesLoading = true;
      const params = {
        file_id: this.fileId,
        minutes_content: this.meetingMinutesContent,
        updated_at: this.fileInfo.meeting_minutes_updated_at,
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_meeting_minutes",
          params
        )
        .then((response) => {
          if (response.data.status) {
            this.fileInfo.meeting_minutes_updated_at = response.data.file.meeting_minutes_updated_at;
            this.isMinutesEdit = false;
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("議事録の更新に失敗しました");
        })
        .finally(() => {
          this.isMeetingMinutesLoading = false;
        });
    },
    setMinutesLanguage(language) {
      this.minutesLanguage = language;
    },
    setTranslateLanguage(language) {
      this.translateLanguage = language;
    },
    setTimedisplay(timeDisplay) {
      this.timestampDisplay = timeDisplay;
      this.updateTextDisplaySetting();
    },
    setParticipantDisplay(participantDisplay) {
      this.participantDisplay = participantDisplay;
      this.updateTextDisplaySetting();
    },
    createTranslation() {
      this.isTranslationLoading = true;
      const params = {
        file_id: this.fileId,
        translation_language: this.translateLanguage,
        text_contents: this.textContents
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/create_translation_file",
          params
        )
        .then((response) => {
          if (response.data.status) {
            // 10秒ごとに翻訳が完了しているかチェック
            this.translationIntervalId = setInterval(this.checkCompleteTranslation, 10000);
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("翻訳の作成に失敗しました");
          this.isTranslationLoading = false;
        });
    },
    deleteTranslation() {
      if (confirm(this.fileInfo.meeting_name + "の翻訳を削除しますか？")) {
        this.isTranslationLoading = true;
        const params = {
          file_id: this.fileId,
          updated_at: this.fileInfo.translation_updated_at,
        };
        axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/delete_translation_file",
            params
          )
          .then((response) => {
            if (response.data.status) {
              this.translationResults = [];
              this.fileInfo.translation_path = false;
              this.fileInfo.translation_updated_at = null;
              this.fileInfo.translation_contents = null;
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
            alert("翻訳の削除に失敗しました");
          })
          .finally(() => {
            this.isTranslationLoading = false;
          });
      }
    },
    copyTranslationToClipboard() {
      const content = this.translationResults
        .map((item) => {
          let textContent = "";
          if (this.timestampDisplay) {
            textContent += `[${item.time}] `;
          }
          if (this.participantDisplay) {
            textContent += `${item.participant}: `;
          }
          textContent += `${item.dialogue}\n`;

          return textContent;
        })
        .join("");
      navigator.clipboard.writeText(content);
      this.$toasted.show("翻訳をクリップボードへコピーしました!", {
        className: "bg-primary rounded",
        icon: "fa-copy",
      });
    },
    downloadTranslation() {
      const content = this.translationResults
        .map((item) => {
          let textContent = "";
          if (this.timestampDisplay) {
            textContent += `[${item.time}] `;
          }
          if (this.participantDisplay) {
            textContent += `${item.participant}: `;
          }
          textContent += `${item.dialogue}\n`;

          return textContent;
        })
        .join("");

      const blob = new Blob([content], { type: "text/plain" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "translation.txt";
      link.click();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isMinutesEdit || this.isTranscribeEdit) {
      if (confirm('編集中の内容は保存されません。よろしいですか？')) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  unmounted() {
    if (this.meetingMinutesIntervalId != null) {
      clearInterval(this.meetingMinutesIntervalId);
    }
    if (this.translationIntervalId != null) {
      clearInterval(this.translationIntervalId);
    }
  },
  watch: {
    inputMeetingName(newVal) {
      this.fileInfo.meeting_name = newVal;
    },
    "fileInfo.meeting_name": function (newVal) {
      this.inputMeetingName = newVal;
    },
    inputParticipants(newVal) {
      this.fileInfo.meeting_participants = newVal;
    },
    "fileInfo.meeting_participants": function (newVal) {
      this.inputParticipants = newVal;
    },
    inputLocation(newVal) {
      this.fileInfo.meeting_location = newVal;
    },
    "fileInfo.meeting_location": function (newVal) {
      this.inputLocation = newVal;
    },
    inputPurpose(newVal) {
      this.fileInfo.meeting_purpose = newVal;
    },
    "fileInfo.meeting_purpose": function (newVal) {
      this.inputPurpose = newVal;
    },
    '$route.query.fileId': {
      handler(newQuery) {
        if (this.meetingMinutesIntervalId != null) {
          clearInterval(this.meetingMinutesIntervalId);
        }
        if (this.translationIntervalId != null) {
          clearInterval(this.translationIntervalId);
        }
        Object.assign(this.$data, this.$options.data());
        this.fileId = newQuery;
        this.getInitialData();
      },
      immediate: true
    },
  },
};
</script>

<style scoped>
.result-transcribe {
  padding: 0 20px;
}

.details-section {
  width: 60%;
  padding-top: 15px;
  border: solid 1px var(--actived);
  border-radius: 8px 0 0 0;
  border-bottom: none;
}

.transcribe-section {
  width: 40%;
  padding-top: 15px;
  border: solid 1px var(--actived);
  border-radius: 0 8px 0 0;
  border-bottom: none;
  border-left: none;
  height: calc(100 * var(--screen-height) - 160px);
}

.dropdown-item .material-symbols-outlined {
  vertical-align: -5px;
  font-size: 1.5rem;
}

.minutes-input::placeholder {
  font-size: 14px;
  color: var(--sub-text);
}

.time-divider {
  line-height: 38px;
}

.language-selector {
  height: 40px;
  border-radius: 3px;
  padding-left: 10px;
}

.language-selector .language-list {
  width: auto;
}

.language-selector label {
  line-height: 38px;
}

.language-selector .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: -3px;
}

.translate-content,
.minutes-content {
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 325px);
  max-height: calc(100 * var(--screen-height) - 325px);
}

.translate-content.result,
.minutes-content.result {
  padding-left: 10px;
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 300px);
  max-height: calc(100 * var(--screen-height) - 300px);
}

.result-button-group {
  background-color: var(--background);
  border-radius: 3px;
}

.details-dropdown {
  font-size: 0.9rem;
}

.details-dropdown .material-symbols-outlined {
  vertical-align: middle;
  font-size: 1.3rem;
}

.details-dropdown .details-remove {
  color: var(--removed);
}

.minutes-content textarea {
  overflow: hidden;
  border: 0;
  background-color: var(--editable);
  resize: none;
}

.result-button-group.editing {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.minutes-content.editing {
  max-height: calc(100 * var(--screen-height) - 335px);
}

.block-detail .hide {
  visibility: hidden;
  opacity: 0;
  width: 0 !important;
  height: 0;
}

.minutes-form,
.file-info {
  height: calc(100 * var(--screen-height) - 295px);
  overflow-y: auto;
}

.file-info .form-control:disabled {
  background-color: var(--white);
  border: 0;
}

.file-info .border.form-control:disabled {
  border: 0 !important;
}

.detail-info-table {
  font-size: 14px;
}

.detail-info-table th,
.detail-info-table td {
  color: var(--secondary);
}

textarea.file-info-input,
textarea.minutes-input {
  resize: none;
}
</style>
