<template>
  <div class="settings-content">
    <div class="content">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-x-setting" role="tabpanel" aria-labelledby="nav-x-setting-tab">
          <div class="setting-item border-bottom-0">
            <div class="tab-pane" id="personal-payment-plan-setting" role="tabpanel"
              aria-labelledby="personal-payment-plan-setting-tab">
              <div class="page-title ps-0 mb-2">
                <h3>通知の設定</h3>
              </div>
              <div class="setting-titles">
                <a class="settings-selector btn w-100 d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse" href="#settingsCollapse" role="button" aria-expanded="false"
                  aria-controls="settingsCollapse">
                  <h5 class="mb-0">設定: 通知</h5>
                  <span class="material-symbols-outlined fs-1">
                    keyboard_arrow_down
                  </span>
                </a>
                <SettingCollapseList @componentChanged="changeComponent" currentComponent="NotificationsSetting" />
                <hr class="mt-2">
              </div>
              <div class="d-flex">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0" for="notificationsSetting1">ファイル読み込み完了</label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="notificationsSetting1"
                    v-model="isCheckFileLoadCompNotification"
                    :disabled="isFreeAccount()"
                    @change="updateFileLoadCompNotification(isCheckFileLoadCompNotification)">
                </div>
              </div>
              <div class="d-flex">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0"
                    for="notificationsSetting2">メンバーのファイル共有</label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start form-check form-switch">
                  <input class="form-check-input" type="checkbox" id="notificationsSetting2"
                    v-model="isCheckFileShareNotification"
                    :disabled="isPersonalAccount()"
                    @change="updateFileShareNotification(isCheckFileShareNotification)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import axios from "@/axios";
import firebase from "firebase/app";
import "firebase/auth"
import { usePlanStore } from '@/stores/index';

// modules
import SettingCollapseList from "@/components/modules/SettingCollapseList.vue";

export default {
  name: "NotificationsSetting",
  components: {
    SettingCollapseList,
  },
  data() {
    return {
      isCheckFileLoadCompNotification: false,
      isCheckFileShareNotification: false,
      tempIsCheckFileLoadCompNotification: false,
      tempIsCheckFileShareNotification: false,
      currentUser: firebase.auth().currentUser,
    };
  },
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    const planStore = usePlanStore();
    const isPaidPlan = planStore.isPaidPlan;
    const isEnterprisePlan = planStore.isEnterprisePlan;

    return {
      showLoading,
      hideLoading,
      isPaidPlan,
      isEnterprisePlan
    }
  },
  async created() {
    this.showLoading();
    await this.getNotificationsSetting();
    this.hideLoading();
  },
  methods: {
    changeComponent(componentName) {
      this.$emit('componentChanged', componentName);
    },
    async getNotificationsSetting() {
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/get_notifications_setting",
        )
        .then((response) => {
          if (response.data.status) {
            this.isCheckFileLoadCompNotification = Boolean(response.data.notifications_setting.file_load_comp_notification);
            this.isCheckFileShareNotification = Boolean(response.data.notifications_setting.file_share_notification);
            this.tempIsCheckFileLoadCompNotification = Boolean(response.data.notifications_setting.file_load_comp_notification);
            this.tempIsCheckFileShareNotification = Boolean(response.data.notifications_setting.file_share_notification);
          }
        });
    },
    async updateFileLoadCompNotification(fileLoadCompNotification) {
      if(this.isFreeAccount()) {
        this.resetCheckFileLoadCompNotification();
        return;
      }
      this.showLoading();
      const params = {
        file_load_comp_notification: fileLoadCompNotification,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/update_notifications_setting",
          params,
        )
        .then((response) => {
          if (!response.status) {
            alert("ファイル読み込み完了通知の有効処理に失敗しました。");
            this.resetCheckFileLoadCompNotification();
          }
        })
        .catch((error) => {
          alert("ファイル読み込み完了通知の有効処理に失敗しました。");
          this.resetCheckFileLoadCompNotification();
        }).finally(() => {
          this.hideLoading();
        });
    },
    async updateFileShareNotification(fileShareNotification) {
      if(this.isPersonalAccount()) {
        this.resetCheckFileShareNotification();
        return;
      }
      this.showLoading();
      const params = {
        file_share_notification: fileShareNotification,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/update_notifications_setting",
          params,
        )
        .then((response) => {
          if (!response.status) {
            alert("メンバーのファイル共有通知の有効処理に失敗しました。");
            this.resetCheckFileShareNotification();
          }
        })
        .catch((error) => {
          alert("メンバーのファイル共有通知の有効処理に失敗しました。");
          this.resetCheckFileShareNotification();
        }).finally(() => {
          this.hideLoading();
        });
    },
    resetCheckFileLoadCompNotification() {
      this.isCheckFileLoadCompNotification = this.tempIsCheckFileLoadCompNotification;
    },
    resetCheckFileShareNotification() {
      this.isCheckFileShareNotification = this.tempIsCheckFileShareNotification;
    },
    isFreeAccount() {
      return !this.isPaidPlan;
    },
    isPersonalAccount() {
      return !this.isEnterprisePlan;
    }
  }
};
</script>

<style scoped>
.setting-item {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--actived);
}
</style>
