<template>
  <div class="settings-content">
    <div class="content">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-profile-setting" role="tabpanel"
          aria-labelledby="nav-profile-setting-tab">
          <div class="page-title ps-0 mb-2">
            <h3>プロフィールの設定</h3>
          </div>
          <div class="setting-titles">
            <a class="settings-selector btn w-100 d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse" href="#settingsCollapse" role="button" aria-expanded="false"
              aria-controls="settingsCollapse">
              <h5 class="mb-0">設定: プロフィール</h5>
              <span class="material-symbols-outlined fs-1">
                keyboard_arrow_down
              </span>
            </a>
            <SettingCollapseList @componentChanged="changeComponent" currentComponent="EditProfile" />
            <hr class="mt-2">
          </div>
          <div class="setting-item">
            <div class="setting-name mb-3">
              <span class="fw-bold">プロフィールのアイコン</span>
            </div>
            <div class="d-flex">
              <ProfileIcon :profileIconPath="profileIconPath" :iconName="iconName" :userName="userName"
                :iconSize="'large'" />
              <div class="col-lg-2 col-md-3 d-flex  flex-column justify-content-between align-items-start">
                <button class="btn btn-outline-primary" @click="setProfileIcon()">
                  アイコン変更
                  <input @change="selectedFileTrans" ref="fileInput" type="file" accept="image/png, image/jpeg"
                    name="file" class="file-input" id="file-input" style="display: none;" />
                </button>
                <button v-if="profileIconPath" @click="deleteProfileIcon()"
                  class="text-secondary bg-transparent border-0 text-decoration-underline p-0">
                  アイコンを取り消し
                </button>
              </div>
            </div>
          </div>
          <div class="setting-item">
            <div class="setting-name mb-3">
              <span class="fw-bold">アカウント情報</span>
            </div>
            <form class="form">
              <div class="d-flex">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0">アイコン表示名</label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <div class="input-group">
                    <input type="text" class="profile-info-input form-control border-end-0 form-control-plaintext"
                      id="profileFormInput1" placeholder="アイコン表示名" v-model="iconName" maxlength="2" autocomplete="off"
                      @focus="focusIconNameForm()" @blur="blurIconNameForm()" />
                    <span id="editButton1" class="btn pe-none order-start-0 border-0 edit-setting"
                      :class="{ 'd-none': editButton1 }">
                      <span class="material-symbols-outlined">
                        border_color
                      </span>
                    </span>
                  </div>
                  <span v-if="iconNameError" class="editable-error col-12">
                    {{
                      iconNameError
                    }}
                  </span>
                </div>
                <div class="col-2 d-flex align-items-start" id="profileFormButton1" v-if="isShowProfileFormButton1"
                  @mouseover="mouseOverIconNameFormButton" @mouseleave="mouseLeaveIconNameFormButton">
                  <button class="btn ms-2 px-0" type="button" :disabled="iconNameError" @click="saveIconName(iconName)">
                    <span class="material-symbols-outlined text-primary">
                      save
                    </span>
                  </button>
                  <button class="btn ms-1 px-0" type="button" @click="cancelIconName()">
                    <span class="material-symbols-outlined">
                      close
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <form class="form">
              <div class="d-flex">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0">ユーザー名
                  </label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <div class="input-group">
                    <input type="text" class="profile-info-input form-control border-end-0 form-control-plaintext"
                      id="profileFormInput2" placeholder="ユーザー名" v-model="userName" autocomplete="off"
                      @focus="focusUserNameForm()" @blur="blurUserNameForm()" />
                    <span id="editButton2" class="btn pe-none border-start-0 border-0 edit-setting"
                      :class="{ 'd-none': editButton2 }">
                      <span class="material-symbols-outlined">
                        border_color
                      </span>
                    </span>
                  </div>
                  <span v-if="userNameError" class="editable-error col-12">
                    {{
                      userNameError
                    }}
                  </span>
                </div>
                <div class="col-2 d-flex align-items-start" id="profileFormButton2" v-if="isShowProfileFormButton2"
                  @mouseover="mouseOverUserNameFormButton" @mouseleave="mouseLeaveUserNameFormButton">
                  <button class="btn ms-2 px-0" type="button" :disabled="userNameError" @click="saveUserName(userName)">
                    <span class="material-symbols-outlined text-primary">
                      save
                    </span>
                  </button>
                  <button class="btn ms-1 px-0" type="button" @click="cancelUserName()">
                    <span class="material-symbols-outlined">
                      close
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <form class="form">
              <div class="d-flex">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0"
                    @click="checkEmailVerification()">メールアドレス</label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <div class="input-group">
                    <input type="text" class="profile-info-input form-control border-end-0 form-control-plaintext"
                      id="profileFormInput3" placeholder="メールアドレス" v-model="userMailAddress" autocomplete="off"
                      @focus="focusMailAddressForm()" @blur="blurMailAddressForm()" />
                    <span id="editButton3" class="btn pe-none border-start-0 border-0 edit-setting"
                      :class="{ 'd-none': editButton3 }">
                      <span class="material-symbols-outlined">
                        border_color
                      </span>
                    </span>
                  </div>
                  <span v-if="userMailAddressError" class="editable-error col-12">
                    {{
                      userMailAddressError
                    }}
                  </span>
                </div>
                <div class="col-2 d-flex align-items-start" id="profileFormButton3" v-if="isShowProfileFormButton3"
                  @mouseover="mouseOverMailAddressFormButton" @mouseleave="mouseLeaveMailAddressFormButton">
                  <button class="btn ms-2 px-0" type="button" :disabled="userMailAddressError"
                    @click="saveMailAddress(userMailAddress)" v-on:click="checkEmailVerification()">
                    <span class="material-symbols-outlined text-primary">
                      save
                    </span>
                  </button>
                  <button class="btn ms-1 px-0" type="button" @click="cancelUserMailAddress()">
                    <span class="material-symbols-outlined">
                      close
                    </span>
                  </button>
                </div>
              </div>
            </form>
            <div class="d-flex email-verification" :class="{ 'd-none': showEmailVerification }">
              <div class="col-5">
                <span class="setting-description">メールアドレスが未認証です</span>
              </div>
              <div class="col-lg-2 col-md-3 d-flex  flex-column justify-content-between align-items-start pt-1">
                <button class="btn btn-outline-danger btn-sm" type="button" @click="resendEmail()">
                  メールアドレスを認証
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="setting-item">
        <div class="setting-name mb-3">
          <span class="fw-bold">パスワード</span>
        </div>
        <div class="collapse" id="collapsePassword">
          <div class="p-0">
            <form @submit.prevent>
              <div class="d-flex mb-4">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0">現在のパスワード</label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <input type="password" class="form-control file-info-input" id="profileFormInput4"
                    placeholder="現在のパスワード" v-model="userPassword" autocomplete="off" />
                  <span v-if="userPasswordError" class="editable-error col-12">
                    {{
                      userPasswordError
                    }}
                  </span>
                </div>
              </div>
              <div class="d-flex">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0">新しいパスワード</label>
                  <span class="setting-description">12文字以上で、英大文字・英小文字・数字・記号のうち3種以上を含めてください</span>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <input type="password" class="form-control file-info-input" id="profileFormInput5"
                    placeholder="新しいパスワード" v-model="newUserPassword" autocomplete="off" />
                  <span v-if="newUserPasswordError" class="editable-error col-12">
                    {{
                      newUserPasswordError
                    }}
                  </span>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0">新しいパスワード（確認）</label>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <input type="password" class="form-control file-info-input" id="profileFormInput6"
                    placeholder="新しいパスワード（確認）" v-model="confirmNewUserPassword" autocomplete="off" />
                  <span v-if="confirmNewUserPasswordError" class="editable-error col-12">
                    {{
                      confirmNewUserPasswordError
                    }}
                  </span>
                </div>
              </div>
              <div class="d-flex mb-3">
                <div class="col-md-5"></div>
                <div class="col-10 col-md-5 d-flex btn-sm-group justify-content-end">
                  <button class="change-password-btn btn col col-md-5" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapsePassword" aria-expanded="false" aria-controls="collapsePassword"
                    @click="changePasswordCancel()" :class="{ 'btn-actived': showChangePasswordBtn }">
                    キャンセル
                  </button>
                  <button class="change-password-btn btn btn-primary col col-md-5" type="button" :disabled="isPasswordFormDisabled()"
                    @click="updateProfilePassword()" :class="{ 'btn-actived': showChangePasswordBtn }">
                    保存
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex change-password" :class="{ 'd-none': showChangePassword }">
          <div class="col-5">
            <label class="setting-label form-control border-0 px-0">パスワードの設定</label>
          </div>
          <div class="col-lg-2 col-md-3 d-flex  flex-column justify-content-between align-items-starts">
            <button class="change-password-form-btn btn btn-outline-primary" type="button" data-bs-toggle="collapse"
              data-bs-target="#collapsePassword" aria-expanded="false" aria-controls="collapsePassword"
              @click="changePasswordForm()" :class="{ 'btn-actived': showChangePassword }">
              パスワード変更
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button v-if="!isEnterprisePlan" class="btn btn-sm btn-outline-danger me-3 px-4"
          @click="withdrawal()">
          アカウントの退会
        </button>
        <button class="btn btn-sm btn-danger me-3 px-4" @click="signOut">
          ログアウト
        </button>
      </div>
    </div>
    <div class="tab-pane fade" id="nav-notifications-setting" role="tabpanel"
      aria-labelledby="nav-notifications-setting-tab">
      <div class="table-responsive">2</div>
    </div>
    <div class="tab-pane fade" id="nav-x-setting" role="tabpanel" aria-labelledby="nav-x-setting-tab">
      <div class="table-responsive">3</div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import axios from "@/axios";
import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"

import authentication from "../mixin/authentication.js";
import ProfileIcon from "../modules/ProfileIcon.vue";
import SettingCollapseList from "../modules/SettingCollapseList.vue";
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { usePlanStore } from '@/stores/index';

export default {
  name: "EditProfile",
  mixins: [authentication],
  components: {
    ProfileIcon,
    SettingCollapseList
  },
  data() {
    return {
      profileIconPath: "",
      ext: "",
      uploadFile: null,
      iconName: '',
      userName: '',
      userMailAddress: '',
      tempIconName: '',
      tempUserName: '',
      tempUserMailAddress: '',
      userPassword: "",
      newUserPassword: "",
      confirmNewUserPassword: "",
      currentUser: firebase.auth().currentUser,
      isMailVerified: firebase.auth().currentUser.emailVerified,
      corpMstId: '',
      isFocusIconNameForm: false,
      isMouseOverIconNameFormButton: false,
      editButton1: false,
      isFocusUserNameForm: false,
      isMouseOverUserNameFormButton: false,
      editButton2: false,
      isFocusMailAddressForm: false,
      isMouseOverMailAddressFormButton: false,
      editButton3: false,
      showChangePassword: false,
      showChangePasswordBtn: false,
      showEmailVerification: true,
    };
  },
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    // パスワード用カスタムバリデーションルール
    yup.addMethod(yup.string, "password", function () {
      return this.test('password', 'パスワードは、英大文字・英小文字・数字・記号のうち3種以上を含めてください。', function (value) {
        if (!value) return true;
        const ret = authentication.methods.validatePassword(value);
        return ret.status;
      });
    });

    const schema = yup.object({
      iconName: yup.string().max(2, 'アイコン表示名は2文字以内にしてください。').nullable(),
      userName: yup.string().max(50, 'ユーザー名は50文字以内にしてください。').required('ユーザー名は必須項目です。'),
      userMailAddress: yup.string().email('メールアドレスは有効なメールアドレスではありません。').max(50, 'メールアドレスは50文字以内にしてください。').required('メールアドレスは必須項目です。'),
      userPassword: yup.string().required('現在のパスワードは必須項目です。'),
      newUserPassword: yup.string().password().required('新しいパスワードは必須項目です。'),
      confirmNewUserPassword: yup.string().oneOf([yup.ref('newUserPassword'), null], '新パスワードが一致しません。').required('新しいパスワード（確認）は必須項目です。'),
    });

    useForm({
      validationSchema: schema,
      initialValues: {
        iconName: '',
        userName: '',
        userMailAddress: '',
      }
    });
    const { errors: passwordFormErrors } = useForm({
      validationSchema: schema,
      initialValues: {
        userPassword: '',
        newUserPassword: '',
        confirmNewUserPassword: '',
      }
    });
    const { value: iconName, errorMessage: iconNameError } = useField('iconName');
    const { value: userName, errorMessage: userNameError } = useField('userName');
    const { value: userMailAddress, errorMessage: userMailAddressError } = useField('userMailAddress');
    const { value: userPassword, errorMessage: userPasswordError } = useField('userPassword');
    const { value: newUserPassword, errorMessage: newUserPasswordError } = useField('newUserPassword');
    const { value: confirmNewUserPassword, errorMessage: confirmNewUserPasswordError } = useField('confirmNewUserPassword');
    
    const planStore = usePlanStore();
    const isEnterprisePlan = planStore.isEnterprisePlan;
    return {
      iconName,
      iconNameError,
      userName,
      userNameError,
      userMailAddress,
      userMailAddressError,
      userPassword,
      userPasswordError,
      newUserPassword,
      newUserPasswordError,
      confirmNewUserPassword,
      confirmNewUserPasswordError,
      passwordFormErrors,
      showLoading,
      hideLoading,
      isEnterprisePlan,
    }
  },
  computed: {
    isShowProfileFormButton1() {
      if (this.isFocusIconNameForm) {
        return this.iconName != this.tempIconName;
      } else {
        return false;
      }
    },
    isShowProfileFormButton2() {
      if (this.isFocusUserNameForm) {
        return this.userName != this.tempUserName;
      } else {
        return false;
      }
    },
    isShowProfileFormButton3() {
      if (this.isFocusMailAddressForm) {
        return this.userMailAddress != this.tempUserMailAddress;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    this.showLoading();
    await this.getUserProfileInfo();
    this.checkEmailVerification();
    this.hideLoading();
  },
  methods: {
    changeComponent(componentName) {
      this.$emit('componentChanged', componentName);
    },
    focusIconNameForm() {
      this.isFocusIconNameForm = true;
    },
    focusUserNameForm() {
      this.isFocusUserNameForm = true;
    },
    focusMailAddressForm() {
      this.isFocusMailAddressForm = true;
    },
    blurIconNameForm() {
      if (!this.isMouseOverIconNameFormButton) {
        this.cancelIconName();
      }
    },
    blurUserNameForm() {
      if (!this.isMouseOverUserNameFormButton) {
        this.cancelUserName();
      }
    },
    blurMailAddressForm() {
      if (!this.isMouseOverMailAddressFormButton) {
        this.cancelUserMailAddress();
      }
    },
    mouseOverIconNameFormButton() {
      this.isMouseOverIconNameFormButton = true;
    },
    mouseLeaveIconNameFormButton() {
      this.isMouseOverIconNameFormButton = false;
    },
    mouseOverUserNameFormButton() {
      this.isMouseOverUserNameFormButton = true;
    },
    mouseLeaveUserNameFormButton() {
      this.isMouseOverUserNameFormButton = false;
    },
    mouseOverMailAddressFormButton() {
      this.isMouseOverMailAddressFormButton = true;
    },
    mouseLeaveMailAddressFormButton() {
      this.isMouseOverMailAddressFormButton = false;
    },
    changePasswordForm() {
      this.showChangePassword = true;
      this.showChangePasswordBtn = false;
    },
    changePasswordCancel() {
      this.showChangePassword = false;
      this.showChangePasswordBtn = true;
    },
    checkEmailVerification() {
      if (!this.isMailVerified) {
        this.showEmailVerification = false;
      } else {
        this.showEmailVerification = true;
      }
    },
    // メンバーマスタからユーザプロフィール取得
    async getUserProfileInfo() {
      await axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_user_profile_info",
        )
        .then((response) => {
          if (response.data.status) {
            const userProfileInfo = response.data.user_profile_info;
            if (userProfileInfo) {
              this.corpMstId = userProfileInfo['corp_mst_id'];
              this.iconName = userProfileInfo['icon_name'];
              this.userName = userProfileInfo['member_name'];
              this.userMailAddress = userProfileInfo['mail_address'];
              this.tempIconName = userProfileInfo['icon_name'];
              this.tempUserName = userProfileInfo['member_name'];
              this.tempUserMailAddress = userProfileInfo['mail_address'];
              this.profileIconPath = userProfileInfo['profile_icon_path'];
            }
          }
        })
        .catch((error) => {
        })
        .finally(() => {
        });
    },
    setProfileIcon() {
      this.$refs.fileInput.click();
    },
    // アイコン選択
    selectedFileTrans: function (e) {
      e.preventDefault();
      this.showLoading();
      let files = e.target.files;
      this.uploadFile = files[0];
      let filename = files[0].name;
      let filesize = files[0].size;
      let type = filename.split(".");
      let available = [
        "png", "jpg", "jpeg"
      ];
      this.ext = type.pop().toLowerCase();
      if (filesize > 1048576) {
        alert("1MB以下の画像を選択してください。")
        this.onReset();
      } else {
        if (!available.includes(this.ext)) {
          alert("未対応のファイル形式(拡張子)です。対応ファイルはpng, jpgです。");
          this.onReset();
        } else {
          // firebase storageにupload
          this.uploadProfileIcon();
        }
      }
    },
    onReset() {
      this.hideLoading();
      this.uploadFile = null;
      this.ext = "";
    },
    // アイコン画像更新
    uploadProfileIcon() {
      this.showLoading();

      let storage = firebase.app().storage(process.env.VUE_APP_STORAGE_PATH);
      const newProfileIconPath = `${this.corpMstId}/profile_icon/${this.currentUser.uid}.${this.ext}`;
      let dataRef = storage.ref(newProfileIconPath);
      let uploadTask = dataRef.put(this.uploadFile);

      let self = this;

      uploadTask.on(
        "state_changed",
        null,
        function (error) {
          // アップロード失敗時の処理
          this.hideLoading();
          alert(
            "アイコン変更に失敗しました。\nサービスが混み合っている可能性があります。時間をあけて再度お試しください。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
        },
        function () {
          // データを更新
          const params = {
            profile_icon_path: newProfileIconPath,
          };
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_profile",
              params,
            )
            .then((response) => {
              if (response.data.status) {
                self.toReload();
              } else {
                alert("アイコン変更に失敗しました。");
              }
            })
            .catch((error) => {
              alert("アイコン変更に失敗しました。");
            })
            .finally(() => {
              this.hideLoading();
            });
        }
      );
    },
    // アイコン取り消し
    deleteProfileIcon() {
      this.showLoading();

      axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/delete_profile_icon",
        )
        .then((response) => {
          if (response.data.status) {
            this.toReload();
          } else {
            alert("アイコン取り消しに失敗しました。");
          }
        })
        .catch((error) => {
          alert("アイコン取り消しに失敗しました。");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    // アイコン表示名更新
    async saveIconName(profileIconName) {
      if (profileIconName == '') {
        profileIconName = null;
      }
      const params = {
        icon_name: profileIconName,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_profile",
          params,
        )
        .then((response) => {
          if (response.data.status) {
            this.toReload();
          } else {
            alert("アイコン表示名の変更に失敗しました。");
          }
        })
        .catch((error) => {
          alert("アイコン表示名の変更に失敗しました。");
        });
    },
    // アイコン表示名更新キャンセル
    cancelIconName() {
      this.iconName = this.tempIconName;
      this.isFocusIconNameForm = false;
      this.isMouseOverIconNameFormButton = false;
    },
    // ユーザー名更新
    async saveUserName(profileUserName) {
      const params = {
        member_name: profileUserName,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_profile",
          params,
        )
        .then((response) => {
          if (response.data.status) {
            this.toReload();
          } else {
            alert("ユーザー名変更に失敗しました。");
          }
        })
        .catch((error) => {
          alert("ユーザー名変更に失敗しました。");
        });
    },
    // ユーザー名更新キャンセル
    cancelUserName() {
      this.userName = this.tempUserName;
      this.isFocusUserNameForm = false;
      this.isMouseOverUserNameFormButton = false;
    },
    // メールアドレス更新
    async saveMailAddress(profileMailAddress) {
      this.showLoading();
      const user = this.currentUser;
      let changeable = true;
      if (user.email != profileMailAddress) {
        await user.updateEmail(profileMailAddress)
          .catch(error => {
            if (
              error.message ==
              "This operation is sensitive and requires recent authentication. Log in again before retrying this request."
            ) {
              alert(
                "メールアドレスが変更されませんでした。\n一度ログインをし直して再度お試しください。"
              );
            } else if (
              error.message ==
              "The user's credential is no longer valid. The user must sign in again."
            ) {
              alert(
                "メールアドレスが変更されませんでした。\n一度ログインをし直して再度お試しください。"
              );
            } else if (error.code == "auth/email-already-in-use") {
              alert("すでに登録されているメールアドレスです。");
            } else if (error.code == "auth/invalid-email") {
              alert(
                "メールアドレスは'***@***.com'、'***@***.ne.jp'、'***@***.co.jp'などの形にしてください。"
              );
            } else {
              alert("メールアドレスの更新に失敗しました。");
            }
            changeable = false;
            this.hideLoading();
          });
        // 確認メール送信
        if (changeable) {
          await user.sendEmailVerification()
            .catch(error => {
              alert("確認メールが送れませんでした。", error.message);
              changeable = false;
              this.hideLoading();
            });
          // Stripeの登録変更
          const functions = firebase.app().functions("asia-northeast1");
          const updateFunc = functions.httpsCallable("updateEmailCustomer");
          await updateFunc({
            email: this.email
          })
            .catch(error => {
              alert('メールの更新に失敗しました。', error.message)
              return false
            })
            .then(() => {
              if (changeable == true) {
                alert("認証メールが送信されました。認証メール記載のリンクは72時間で失効します。\n届かない場合または失効した場合は認証メールを再送してください。");
                this.toReload();
              }
            }).finally(() => {
              this.hideLoading();
            });
        }
      } else {
        alert("新しいメールアドレスを入力してください。");
        this.hideLoading();
        return false;
      }
    },
    // メールアドレス更新キャンセル
    cancelUserMailAddress() {
      this.userMailAddress = this.tempUserMailAddress;
      this.isFocusMailAddressForm = false;
      this.isMouseOverMailAddressFormButton = false;
    },
    isPasswordFormDisabled() {
      if (this.userPassword && this.newUserPassword && this.confirmNewUserPassword) {
        return Object.keys(this.passwordFormErrors).length;
      } else {
        return true;
      }
    },
    // 認証メール再送
    resendEmail: function () {
      this.currentUser
        .sendEmailVerification()
        .then(() => {
          alert("認証メールを再送しました。");
          this.toReload();
        })
        .catch((error) => {
          if (error.code == "auth/too-many-requests") {
            alert(
              "短時間に送信できるリクエストの上限を超えました。\n時間をおいてもう一度お試しください。"
            );
          }
          alert("確認メールが送れませんでした。", error);
        });
    },
    // パスワード更新
    updateProfilePassword() {
      if (this.newUserPassword == this.confirmNewUserPassword) {
        const judge = this.validatePassword(this.newUserPassword);
        if (!judge["status"]) {
          alert(judge["message"]);
          return false;
        } else {
          const user = this.currentUser;
          var credential = firebase.auth.EmailAuthProvider.credential(
            user.email,
            this.userPassword
          );
          user
            .reauthenticateWithCredential(credential)
            .then(() => {
              user
                .updatePassword(this.newUserPassword)
                .then(() => {
                  alert("パスワードを変更しました。");
                  this.toReload();
                })
                .catch((error) => {
                  if (error.code == "auth/weak-password") {
                    alert(
                      "パスワードが脆弱です。12文字以上で、英大文字・英小文字・数字・記号のうち3種以上を含めてください。"
                    );
                  } else {
                    alert("パスワード変更に失敗しました。");
                  }
                });
            })
            .catch((error) => {
              alert("現在のパスワードが誤っています。");
            });
        }
      } else {
        alert("新パスワードが一致していません。");
      }
    },
    // アカウント退会
    async withdrawal() {
      this.showLoading();
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/withdrawal_account",
        )
        .then(async (response) => {
          if (response.data.status) {
            alert("アカウントを退会しました。");
            await this.logout();
            this.toLandingPage();
          } else {
            alert("アカウント退会に失敗しました。");
          }
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    toReload() {
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },

    toLandingPage() {
      window.location = process.env.VUE_APP_LP_URL;
    },
  }
}
</script>

<style scoped>
.setting-item .setting-name {
  font-size: 16px;
}

.setting-item .text-secondary {
  font-size: 0.8rem;
}

.setting-item {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--actived);
}

.setting-description {
  font-size: 0.73rem;
  color: var(--sub-text);
  display: inline-block;
  transform: translateY(-10px);
}

.input-group input {
  padding-left: 10px;
}

.input-group {
  border-radius: 5px;
}

.input-group button:hover {
  background-color: #fafbff;
}

.input-group:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-radius: 4px;
}

.input-group .edit-setting {
  display: none;
}

.input-group:hover {
  background-color: var(--background);
}

.input-group:hover .edit-setting {
  display: block;
}

.input-group:focus-within .btn {
  background-color: var(--white);
  border: none !important;
}

.input-group:focus-within .profile-info-input {
  background-color: var(--white);
  border: none !important;
  box-shadow: none !important;
}

.input-group:focus-within .btn:hover {
  background-color: var(--white);
}

.profile-info-input:focus,
.profile-info-input:focus-visible {
  box-shadow: none !important;
  border: none !important;
  outline: none;
}

.editable-error {
  margin: 5px 0 0;
  color: #cc0000;
}
</style>
