<template>
  <div class="maintenance main">
    <div class="error-section">
      <div class="logo">
        <img src="../../assets/img/texta-logo.svg" alt="">
      </div>
      <div class="error-description flex-column d-flex align-items-center">
        <div class="error-content flex-column justify-content-center py-4">
          <div class="col left px-0 justify-content-center d-flex align-items-center">
            <img src="../../assets/img/maintenance.svg">
          </div>
          <div class="col right px-0 d-flex flex-column justify-content-center  align-items-center">
            <h1 class="fs-1 fw-bolder mt-0 mb-2">メンテナンス中</h1>
            <div class="fs-3 text-secondary text-center">ただいまシステムメンテナンスを行なっているため、<br>ご利用できません</div>
          </div>
        </div>
        <a class="btn pt-2 btn-primary w-75 fw-bold" :href="infoUrl" target="_blank">
          最新情報
        </a>
      </div>
    </div>
    <div class="footer p-3">
      © TECHNODIGITAL All Right Reserved.
    </div>
  </div>
</template>

<script>
import { inject, onMounted } from 'vue';

export default {
  setup() {
    const infoUrl = process.env.VUE_APP_LP_URL + "/info";

    return {
      infoUrl,
    }
  },
}
</script>

<style scoped>
.error-section {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-description {
  padding: 25px;
  background-color: #fff;
  width: 42vw;
  border-radius: 10px;
  box-shadow: 30px 30px 50px 0 rgba(158, 159, 168, 0.7);
}
/* 古いバージョンでないCSSを追加 */
.footer {
  position: absolute;
  bottom: 0%;
  font-size: 12px;
  width: 100%;
  text-align: right;
}
.w-75 {
  width: 75%;
}
.btn-primary {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
  background-image: none;
  border-radius: 8px;
}
.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  background-image: none;
}
.btn-primary:active {
  background-color: #0056b3;
  border-color: #004e98;
  background-image: none;
}
.fw-bold {
  font-weight: bold;
}
</style>
