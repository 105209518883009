import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import { createRouter, createWebHistory } from "vue-router";
import { useFileSearchStore } from '@/stores/index';
import { useUserInfoStore } from '@/stores/index';
import { useUserInfo } from "@/components/composables/userInfo";
import { usePlanStore } from '@/stores/index';
import { usePlanInfo } from "@/components/composables/planInfo";
import SystemMaintenance from "../components/pages/SystemMaintenance.vue";
import Home from "../components/pages/Home";
import FileManagement from "../components/pages/FileManagement";
import ResetPassword from "../components/pages/ResetPassword.vue";
import SignIn from "../components/pages/SignIn.vue";
import AuthAction from "../components/pages/AuthAction.vue";
import Setting from "../components/pages/Setting.vue";
import Main from "../components/pages/Main.vue";
import PageNotFound from "../components/pages/PageNotFound.vue";
import PreSubscription from "../components/pages/PreSubscription.vue";
import SystemError from "../components/pages/SystemError.vue";
import Detail from "../components/pages/Detail.vue";
import RealtimeRecording from "../components/pages/RealtimeRecording.vue";


const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    children: [
      {
        path: '',
        name: "Home",
        component: Home,
        meta: {
          requiresAuth: true,
          requiresVerified: true,
          requiredPaidUsage: false,
        },
      },
      {
        path: "setting",
        name: "Setting",
        component: Setting,
        meta: { requiresAuth: true },
      },
      {
        path: "file-management",
        name: "FileManagement",
        component: FileManagement,
        meta: {
          requiresAuth: true,
          requiresVerified: true,
          requiredPaidUsage: true,
        },
      },
      {
        path: "detail",
        name: "Detail",
        component: Detail,
        meta: { 
          requiresAuth: true,
          requiresVerified: true,
        },
      },
      {
        path: "realtimeRecording",
        name: "RealtimeRecording",
        component: RealtimeRecording,
        meta: { 
          requiresAuth: true,
          requiresVerified: true,
        },
      },
    ],
  },
  {
    path: "/signin",
    name: "SignIn",
    component: SignIn,
  },
  {
    path: "/landing_page",
    name: "LandingPage",
    // LPは外部サービスでホスティング
    beforeEnter() {
      window.location = process.env.VUE_APP_LP_URL;
    },
  },
  {
    path: "/reset",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/authaction",
    name: "AuthAction",
    component: AuthAction
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
  },
  {
    path: "/500",
    name: "SystemError",
    component: SystemError,
  },
  {
    path: "/pre-subscription",
    name: "PreSubscription",
    component: PreSubscription,
  },
  {
    path: "/503",
    name: "SystemMaintenance",
    component: SystemMaintenance,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

let authStateChanged = null;

router.beforeEach((to, from, next) => {
  if (authStateChanged) {
    authStateChanged();
    authStateChanged = null;
  }

  // ページ遷移の時、表示されているツールチップをクリア
  const tooltips = document.querySelectorAll('.tooltip');
  const popovers = document.querySelectorAll('.popover');

  tooltips.forEach(tooltip => tooltip.remove());
  popovers.forEach(popover => popover.remove());

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresVerified = to.matched.some(
    (record) => record.meta.requiresVerified
  );
  const requiredPaidUsage = to.matched.some(
    (record) => record.meta.requiredPaidUsage
  );
    
  // 検索条件のstoreをクリア
  const fileSearchStore = useFileSearchStore();
  if (to.name != 'FileManagement') {
    fileSearchStore.clearFileSearch();
  }

  // requiresAuth がtrueならログイン認証が必要！
  if (requiresAuth) {
    authStateChanged = firebase.auth().onAuthStateChanged(async function (user) {
      if (user) {
        // ログインしているときの処理
        const userInfoStore = useUserInfoStore();
        if (!userInfoStore.memberMstId) {
          // リロード時にユーザ情報がなければ再取得
          await useUserInfo().getUserInfo();
        }
        const planStore = usePlanStore();
        if (!planStore.planId) {
          // プラン情報取得
          await usePlanInfo().getPlanInfo();
        } else {
          planStore.updateToday();
          if (planStore.isUnpaid) {
            // 未払いの場合、最新のプランを再取得
            await usePlanInfo().getPlanInfo();
          }
        }

        // メール未認証の機能制限
        if (requiresVerified && !user.emailVerified) {
          // メール未認証
          alert(
            "メールアドレスが未認証です。\n「設定」>「プロフィール」から、メールアドレスを認証してください"
          );
          next({
            path: "/setting",
          });
          return;
        }

        // 未払いの機能制限
        if (planStore.isCardPayment && planStore.isUnpaid) {
          if (planStore.isEnterprisePlan) {
            // エンタープライズプラン
            if (planStore.isAdmin) {
              // 代表者
              if (to.name != 'Setting') {
                planStore.setErrorMessage("「設定」>「料金プラン」から、サブスクリプションを開始してください");
                next({
                  path: "/setting",
                });
                return;
              }
            } else {
              // 代表者以外
              // 未払いの為使えない画面へ遷移させる
              next({
                path: "/pre-subscription",
              });
              return;
            }
          } else {
            // 個人プラン
            if (planStore.isPaidPlan) {
              // 有料プラン
              if (to.name != 'Setting') {
                planStore.setErrorMessage("「設定」>「料金プラン」から、サブスクリプションを開始してください");
                next({
                  path: "/setting",
                });
                return;
              }
            } else {
              // 無料プラン
              if (requiredPaidUsage) {
                planStore.setErrorMessage("現在のプランではご利用できません");
                next({
                  path: "/",
                });
                return;
              }
            }
          }
        }

        next();

      } else {
        const userInfoStore = useUserInfoStore();
        if (userInfoStore.memberMstId) {
          // 未ログインでストアが残っている場合、ストアをリセット（ログアウト時の処理）
          userInfoStore.$reset();
          const planStore = usePlanStore();
          planStore.$reset();
          return;
        } else {
          // 未ログインでサインインにリダイレクト
          next({
            path: "/signin",
            query: {
              redirect: to.fullPath,
            },
          });
        }
      }
    });
  } else {
    // 未ログインでもそのまま使える機能
    next();
  }
});

router.afterEach((to, from) => {
  const planStore = usePlanStore();

  // Firestoreからメンテナンス情報を取得
  const db = firebase.firestore();
  db.collection("system_status")
    .doc("system_mode")
    .get()
    .then((doc) => {
      const maintenance = doc.data();
      const maintenanceUrls = maintenance.maintenance_urls;
      const isMaintenanceURL = maintenanceUrls.some(url => window.location.href.includes(url));
      // メンテナンス中の場合、503ページへリダイレクト
      if (maintenance.is_maintenance && (isMaintenanceURL || maintenance.is_preview)) {
        router.push({
          path: "/503",
        });
        return;
      }
    });
  if (planStore.errorMessage != null) {
    alert(planStore.errorMessage);
    planStore.setErrorMessage(null);
  }
});

export default router;
