import { usePlanStore } from '@/stores/index';
import axios from "@/axios";

export function usePlanInfo() {
  const planStore = usePlanStore();

  const getPlanInfo = async () => {
    await axios
      .get(
        process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_plan_info",
      )
      .then((response) => {
        if (response.data.status) {
          planStore.setPlan(response.data.plan);
        }
      })
      .catch((error) => {
      })
  };

  return {
    getPlanInfo,
  };
}