<template>
  <div class="main">
    <div class="error-section">
      <div class="logo">
        <img src="../../assets/img/texta-logo.svg" alt="">
      </div>
      <div class="error-description flex-column d-flex align-items-center">
        <div class="error-content row justify-content-center py-4">
          <div class="col left py-4">
            <img src="../../assets/img/404.svg">
          </div>
          <div class="col right ms-4 py-4 d-flex flex-column justify-content-center">
            <h1 class="fs-1 fw-bolder">404</h1>
            <div class="fs-3 text-secondary">ページが見つかりません</div>
          </div>
        </div>
        <router-link class="btn pt-2 btn-primary w-75 fw-bold" to="/">
          ホームに戻る
        </router-link>
      </div>
    </div>
    <div class="footer p-3">
      © TECHNODIGITAL All Right Reserved.
    </div>
  </div>
</template>

<script>
import { inject, onMounted } from 'vue';

export default {
  setup() {
    const loadingState = inject('loading');

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    onMounted(() => {
      // 画面遷移前から表示しているローディングがあれば非表示にする
      hideLoading();
    });
  },
}
</script>

<style scoped>
.error-section {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-description {
  padding: 25px;
  background-color: var(--white);
  width: 42vw;
  border-radius: 10px;
  box-shadow: 30px 30px 50px 0 rgba(158, 159, 168, 0.7);
}
</style>
