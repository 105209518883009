<template>
  <div class="modal modal-xl fade" id="searchModal" tabindex="-1" aria-labelledby="searchModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header fs-4 py-2 d-flex">
          <div class="col-8 d-flex">
            <span class="material-symbols-outlined search-icon">search</span>
            <input type="text" class="form-control keyword-search-input" id="keywordSearchInput"
              aria-describedby="ファイルを検索" placeholder="キーワードを入力" v-model="keyword" />
          </div>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-3 pb-1 px-4 files-search-modal">
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">収録方式</div>
            <div class="search-option d-flex my-2">
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="1" id="searchCheck1a" v-model="fileTypes" />
                <label class="form-check-label" for="searchCheck1a">
                  リアルタイム収録
                </label>
              </div>
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="2" id="searchCheck1b" v-model="fileTypes" />
                <label class="form-check-label" for="searchCheck1b">
                  ファイルインポート
                </label>
              </div>
            </div>
          </div>
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">状態</div>
            <div class="search-option d-flex my-2">
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="4" id="searchCheck2b" v-model="statuses" />
                <label class="form-check-label" for="searchCheck2b">
                  共有済
                </label>
              </div>
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="3" id="searchCheck2a" v-model="statuses" />
                <label class="form-check-label" for="searchCheck2a">
                  文字起こし済み
                </label>
              </div>
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="2" id="searchCheck2c" v-model="statuses" />
                <label class="form-check-label" for="searchCheck2c">
                  処理中
                </label>
              </div>
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="1" id="searchCheck2d" v-model="statuses" />
                <label class="form-check-label" for="searchCheck2d">
                  エラー発生
                </label>
              </div>
            </div>
          </div>
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">変換</div>
            <div class="search-option d-flex my-2">
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="0" id="searchCheck3a" v-model="conversions" />
                <label class="form-check-label" for="searchCheck3a">
                  議事録
                </label>
              </div>
              <div class="form-check col-3">
                <input class="form-check-input" type="checkbox" value="1" id="searchCheck3b" v-model="conversions" />
                <label class="form-check-label" for="searchCheck3b">
                  翻訳
                </label>
              </div>
            </div>
          </div>
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">作成者</div>
            <div class="search-option d-flex my-2">
              <div class="dropend position-static">
                <button class="btn border" type="button" id="creatorSelectMenu" data-bs-toggle="dropdown"
                  data-bs-auto-close="outside" aria-expanded="false">
                  <span class="material-symbols-outlined icon-fill fs-5">group</span>
                  <span class="selected-creaters">{{createdMembersLabel}}</span>
                  <span class="material-symbols-outlined">chevron_right</span>
                </button>
                <ul class="dropdown-menu user-select-dropdown pt-0" aria-labelledby="creatorSelectMenu">
                  <div class="d-flex creater-search-input py-1">
                    <input type="text" class="form-control" id="createrSearchInput" placeholder="ユーザを入力" v-model="createdMemberCondition" /><span
                      class="material-symbols-outlined">search</span>
                  </div>
                  <div v-if="memberLoading">Loading...</div>
                  <div v-for="(member, index) in createdMembers" :key="member.member_mst_id" class="d-flex dropdown-item align-items-center">
                    <input class="form-check-input" type="checkbox" :value="member.member_mst_id" :id="'createrCheck' + index" v-model="createdMemberMstIds" />
                    <label class="form-check-label" :for="'createrCheck' + index">
                      <div class="ms-2 d-flex user-select-option sm">
                        <ProfileIcon :profileIconPath="member.profile_icon_path" :iconName="member.icon_name" :userName="member.member_name" />
                        <div class="user-info">
                          <div class="user-name">{{member.member_name}}</div>
                          <div class="user-email">{{member.email}}</div>
                        </div>
                      </div>
                    </label>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">作成日付</div>
            <div class="search-option d-flex my-2">
              <div class="row w-75 align-items-center">
                <div class="col col-5">
                  <input type="date" class="form-control minutes-input" placeholder="yyyy/mm/dd"
                    id="minutesFormInput3"  aria-label="開始" v-model="createdAtFrom" />
                </div>
                <div class="col col-1 text-center time-divider">〜</div>
                <div class="col col-5">
                  <input type="date" class="form-control minutes-input" placeholder="yyyy/mm/dd"
                    aria-label="終了" v-model="createdAtTo" />
                </div>
              </div>
            </div>
          </div>
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">更新者</div>
            <div class="search-option d-flex my-2">
              <div class="dropend position-static">
                <button class="btn border" type="button" id="updatedSelectMenu" data-bs-toggle="dropdown"
                  data-bs-auto-close="outside" aria-expanded="false">
                  <span class="material-symbols-outlined icon-fill fs-5">group</span>
                  <span class="selected-updated">{{updatedMembersLabel}}</span>
                  <span class="material-symbols-outlined">chevron_right</span>
                </button>
                <ul class="dropdown-menu user-select-dropdown pt-0" aria-labelledby="creatorSelectMenu">
                  <div class="d-flex updated-search-input py-1">
                    <input type="text" class="form-control" id="updatedSearchInput" placeholder="ユーザを入力" v-model="updatedMemberCondition" /><span
                      class="material-symbols-outlined">search</span>
                  </div>
                  <div v-if="memberLoading">Loading...</div>
                  <div v-for="(member, index) in updatedMembers" :key="member.member_mst_id"  class="d-flex dropdown-item align-items-center">
                    <input class="form-check-input" type="checkbox" :value="member.member_mst_id" :id="'updatedCheck' + index" v-model="updatedMemberMstIds" />
                    <label class="form-check-label" :for="'updatedCheck' + index">
                      <div class="ms-2 d-flex user-select-option sm">
                        <ProfileIcon :profileIconPath="member.profile_icon_path" :iconName="member.icon_name" :userName="member.member_name" />
                        <div class="user-info">
                          <div class="user-name">{{member.member_name}}</div>
                          <div class="user-email">{{member.email}}</div>
                        </div>
                      </div>
                    </label>
                  </div>
                </ul>
              </div>
            </div>
          </div>
          <div class="search-section mb-3">
            <div class="search-option-name fw-bold">更新日付</div>
            <div class="search-option d-flex my-2">
              <div class="row w-75 align-items-center">
                <div class="col col-5">
                  <input type="date" class="form-control minutes-input" placeholder="yyyy/mm/dd"
                    id="minutesFormInput2"  aria-label="開始" v-model="updatedAtFrom" />
                </div>
                <div class="col col-1 text-center time-divider">〜</div>
                <div class="col col-5">
                  <input type="date" class="form-control minutes-input" placeholder="yyyy/mm/dd" aria-label="終了" v-model="updatedAtTo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer py-1 border-0">
          <button type="button" class="btn" @click="clearConditions">リセット</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="toSearchResults">条件で絞り込み</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useFileSearchStore } from '@/stores/index';
import axios from "@/axios";
import ProfileIcon from "../modules/ProfileIcon.vue";

export default {
  name: "CommonFileSearch",
  components: {ProfileIcon},
  setup() {
    const router = useRouter();

    const fileSearchStore = useFileSearchStore();

    // 検索条件
    const keyword = ref('');
    const fileTypes = ref([]);
    const statuses = ref([]);
    const conversions = ref([]);
    const createdMemberMstIds = ref([]);
    const createdAtFrom = ref('');
    const createdAtTo = ref('');
    const updatedMemberMstIds = ref([]);
    const updatedAtFrom = ref('');
    const updatedAtTo = ref('');

    // 作成者・更新者
    const members = ref([])
    const createdMembers = ref([])
    const updatedMembers = ref([])
    const memberLoading = ref(false)
    const createdMemberCondition = ref('')
    const updatedMemberCondition = ref('')
    const createdMembersLabel = ref('条件なし');
    const updatedMembersLabel = ref('条件なし');

    onMounted(async () => {
      getMembers();
    });

    const toSearchResults = () => {
      // 検索条件
      fileSearchStore.setKeyword(keyword.value);
      fileSearchStore.setFileTypes(fileTypes.value);
      fileSearchStore.setStatuses(statuses.value);
      fileSearchStore.setConversions(conversions.value);
      fileSearchStore.setCreatedMemberMstIds(createdMemberMstIds.value);
      fileSearchStore.setCreatedAtFrom(createdAtFrom.value);
      fileSearchStore.setCreatedAtTo(createdAtTo.value);
      fileSearchStore.setUpdatedMemberMstIds(updatedMemberMstIds.value);
      fileSearchStore.setUpdatedAtFrom(updatedAtFrom.value);
      fileSearchStore.setUpdatedAtTo(updatedAtTo.value);

      // ファイル管理画面へ遷移
      router.push({
        name: 'FileManagement',
        query: {
          // クエリパラメータの変更で検索実行する
          searchDate: Date.now(),
        }
      });
    };

    const getMembers = async () => {
      memberLoading.value = true;

      await axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_file_search_members",
        )
        .then((response) => {
          members.value = response.data.members;
          createdMembers.value = response.data.members;
          updatedMembers.value = response.data.members;
        })
        .catch((error) => {
        })
        .finally(() => {
          memberLoading.value = false;
        });
    };

    watch(createdMemberMstIds, (newIds) => {
      if (newIds.length == 0) {
        createdMembersLabel.value = '条件なし';
      } else if (newIds.length == 1 && members.value.length > 0) {
        const member = members.value.find(member => member.member_mst_id == newIds[0]);
        createdMembersLabel.value = member.member_name;
      } else {
        createdMembersLabel.value = `+${newIds.length}件`;
      }
    }, {
      immediate: true
    });

    watch(updatedMemberMstIds, (newIds) => {
      if (newIds.length == 0) {
        updatedMembersLabel.value = '条件なし';
      } else if (newIds.length == 1 && members.value.length > 0) {
        const member = members.value.find(member => member.member_mst_id == newIds[0]);
        updatedMembersLabel.value = member.member_name;
      } else {
        updatedMembersLabel.value = `+${newIds.length}件`;
      }
    }, {
      immediate: true
    });

    watch(createdMemberCondition, (newCondition) => {
      if (newCondition == '') {
        createdMembers.value = members.value;
      } else {
        createdMembers.value = members.value.filter(member => {
          return member.member_name.includes(newCondition) || member.email.includes(newCondition);
        });
      }
    });

    watch(updatedMemberCondition, (newCondition) => {
      if (newCondition == '') {
        updatedMembers.value = members.value;
      } else {
        updatedMembers.value = members.value.filter(member => {
          return member.member_name.includes(newCondition) || member.email.includes(newCondition);
        });
      }
    });

    const clearConditions = () => {
      keyword.value = '';
      fileTypes.value = [];
      statuses.value = [];
      conversions.value = [];
      createdMemberMstIds.value = [];
      createdAtFrom.value = '';
      createdAtTo.value = '';
      updatedMemberMstIds.value = [];
      updatedAtFrom.value = '';
      updatedAtTo.value = '';
    };

    return {
      keyword,
      fileTypes,
      statuses,
      conversions,
      createdMemberMstIds,
      createdAtFrom,
      createdAtTo,
      updatedMemberMstIds,
      updatedAtFrom,
      updatedAtTo,
      toSearchResults,
      createdMembers,
      updatedMembers,
      memberLoading,
      createdMembersLabel,
      updatedMembersLabel,
      createdMemberCondition,
      updatedMemberCondition,
      clearConditions,
    };
  },
}
</script>

<style scoped>
.user-select-dropdown {
  max-height: calc(23 * var(--screen-height));
  overflow-y: auto;
}
</style>