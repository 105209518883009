<template>
  <div id="app">
    <vue-element-loading
      background-color="rgba(255, 255, 255, .7)"
      :active="loading"
      is-full-screen
      spinner="line-scale"
      color="#278bff"
    />
    <router-view />
  </div>
</template>

<script>
import { ref, provide } from 'vue';

export default {
  setup() {
    const loading = ref(false);
    const updateLoading = (newVal) => {
      loading.value = newVal;
    };

    provide('loading', {
      loading: loading,
      updateLoading: updateLoading,
    });

    return {
      loading
    };
  },
}
</script>
