<template>
  <div class="translation-content">
    <div v-if="isTranslationLoading" class="translation-loading mt-4 text-center">
      <vue-element-loading
        background-color="rgba(255, 255, 255, .7)"
        :active="isTranslationLoading"
        :fullscreen="false"
        spinner="spinner"
        color="#7e858c"
      />
    </div>
    <div v-else-if="fileInfo.translation_path == false" class="translate-form">
      <div class="tab-button-group mt-0 mb-3 d-flex justify-content-between">
        <div class="right">
          <div class="language-selector border border-dark border-2 d-flex">
            <label for="translateLanguageList">出力言語：</label>
            <select
              class="language-list form-select border-0"
              id="translateLanguageList"
              aria-label=".form-select example"
              v-model="currentTranslateLanguage"
              @change="$emit('setTranslateLanguage', $event.target.value)"
            >
                <option value="ja">日本語</option>
                <option selected value="en">英語</option>
                <option value="zh-CN">中国語</option>
                <option value="zh-HK">香港</option>
                <option value="zh-TW">台湾</option>
                <option value="ko">韓国語</option>
                <option value="hi">ヒンディー語</option>
                <option value="es">スペイン語</option>
                <option value="ru">ロシア語</option>
                <option value="pt">ポルトガル語</option>
                <option value="fr">フランス語</option>
                <option value="de">ドイツ語</option>
            </select>
          </div>
        </div>
        <div class="left">
          <button
            @click="$emit('createTranslation')"
            class="translate-creater btn btn-primary"
            id="translateCreateButton"
            :disabled="
              fileInfo.permission?.write_permission == false ||
              isPaidPlan == false
            "
          >
            <span class="material-symbols-outlined icon-fill">g_translate</span>
            <span class="btn-title">翻訳</span>
          </button>
        </div>
      </div>
      <button
        @click="$emit('createTranslation')"
        class="translate-creater-sm w-100 btn btn-primary"
        id="translateCreateButtonSm"
        :disabled="
          fileInfo.permission?.write_permission == false ||
          isPaidPlan == false
        "
      >
        <span class="material-symbols-outlined icon-fill">g_translate</span>
        <span class="btn-title">翻訳</span>
      </button>
    </div>
    <div v-else class="translate-result">
      <div
        class="
          tab-button-group
          mt-0
          d-flex
          justify-content-between
          result-button-group
        "
      >
        <div class="right">
          <button class="translate-download btn" @click="$emit('downloadTranslation')">
            <span class="material-symbols-outlined">download</span>
            <span class="btn-title">ダウンロード</span>
          </button>
        </div>
        <div class="left">
          <button
            type="button"
            class="translate-result-menu btn"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="material-symbols-outlined">more_vert</span>
          </button>
          <ul class="details-dropdown dropdown-menu dropdown-menu-end">
            <li>
              <a class="dropdown-item py-2" type="button" @click="$emit('copyTranslationToClipboard')">
                <span class="material-symbols-outlined">content_copy</span>
                クリップボードへコピー
              </a>
            </li>
            <li>
              <a
                class="dropdown-item py-2 details-remove"
                type="button"
                id="translateRemoveButton"
                @click="$emit('deleteTranslation')"
                :class="{disabled: fileInfo.permission?.write_permission == false}"
              >
                <span class="material-symbols-outlined">ink_eraser</span>
                翻訳の取り消し
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="translated-content">
        <div v-for="(translation_content, index) in translationResults" :key="index">
          <div :id="'translationBlock' + index" class="translation-block" 
              @click="seekAudioByBlock(translation_content.time)"
          >
            <div
              class="
                block-detail
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="d-flex mb-1">
                <span
                  class="timestamp"
                  :class="{
                    'd-none': !timestampDisplay,
                  }"
                >
                  {{ translation_content.time }}
                </span>
                <span
                  class="participant"
                  :class="{
                    'd-none': !participantDisplay
                  }"
                >{{translation_content.participant}}</span>
              </div>
            </div>
            <p
              class="block-content"
            >{{translation_content.dialogue}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TranscribeTranslation",
  props: {
    isTranslationLoading: Boolean,
    timestampDisplay: Boolean,
    participantDisplay: Boolean,
    fileInfo: Object,
    translationResults: Array,
    translateLanguage: String,
    isPaidPlan: Boolean,
    isAudioLoading: Boolean,
  },
  data() {
    return {
      currentTranslateLanguage: "en",
    };
  },
  methods: {
    seekAudioByBlock(time) {
      if(!this.isAudioLoading) {
        this.$emit("seekAudioByBlock", time);
      }
    },
  },
};
</script>

<style scoped>
.language-selector {
  height: 40px;
  border-radius: 3px;
  padding-left: 10px;
}

.language-selector .language-list {
  width: auto;
}

.language-selector label {
  line-height: 38px;
}

.language-selector .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: -3px;
}

.translate-content {
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 325px);
  max-height: calc(100 * var(--screen-height) - 325px);
}

.translate-content.result {
  padding-left: 10px;
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 300px);
  max-height: calc(100 * var(--screen-height) - 300px);
}

.result-button-group {
  background-color: var(--background);
  border-radius: 3px;
}

.details-dropdown {
  font-size: 0.9rem;
}

.details-dropdown .material-symbols-outlined {
  vertical-align: middle;
  font-size: 1.3rem;
}

.details-dropdown .details-remove {
  color: var(--removed);
}

.translated-content {
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 300px);
  max-height: calc(100 * var(--screen-height) - 300px);
}

.translation-block {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  transition: background-color 0.5s ease;
}

.translation-block  .block-detail {
  font-size: 12px;
}

.translation-block  .block-detail .timestamp {
  font-size: 14px;
  margin-right: 10px;
  width: 55px;
  color: var(--sub-text);
}

.translation-block .active .block-detail .timestamp {
  background-color: transparent;
}

.translation-block  .block-detail .participant {
  font-size: 14px;
  font-weight: 600;
}

.translation-block  .block-content {
  font-size: 14px;
  width: 100%;
  resize: none;
}

.translation-block:hover {
  background-color: var(--actived);
}

.translation-block.active {
  background-color: var(--sub-primary);
}
</style>
