<template>
  <div class="collapse setting-list-collapse shadow" id="settingsCollapse">
    <div class="list">
      <div class="list-groups">
        <div class="list-group group-items">
          <div class="group-title">アカウント情報</div>
          <div class="list-group-item list-group-item-action item d-flex align-items-center"
            v-bind:class="{ 'active': currentComponent === 'EditProfile' }" id="nav-profile-setting-tab" type="button"
            @click="selectComponent('EditProfile')">
            <span class="setting-icon material-symbols-outlined">
              person
            </span>
            <span class="setting-title">プロフィール</span>
          </div>
          <div class="list-group-item list-group-item-action item d-flex align-items-center"
            v-bind:class="{ 'active': currentComponent === 'NotificationsSetting' }" id="nav-notifications-setting-tab"
            type="button" @click="selectComponent('NotificationsSetting')">
            <span class="setting-icon material-symbols-outlined">
              notifications
            </span>
            <span class="setting-title">通知</span>
          </div>
          <div v-if="isEnterpriseMenuDisplay()" class="group-title mt-4 for-enterprise">企業情報</div>
          <div v-if="isMemberMenuDisplay()" class="list-group-item list-group-item-action item d-flex align-items-center for-enterprise"
            id="nav-member-setting-tab" v-bind:class="{ 'active': currentComponent === 'MemberManagement' }" type="button" @click="selectComponent('MemberManagement')">
            <span class="setting-icon material-symbols-outlined">
              group
            </span>
            <span class="setting-title">メンバー</span>
          </div>
          <!-- 第二フェーズ以降に実装予定 -->
          <!-- <div
            v-if="planStore.isEnterprisePlan" class="list-group-item list-group-item-action item d-flex align-items-center for-enterprise"
            id="nav-usage-setting-tab" type="button">
            <span class="setting-icon material-symbols-outlined">
              data_usage
            </span>
            <span class="setting-title">利用状況</span>
          </div> -->
          <div v-if="isContractInfoMenuDisplay()" class="group-title mt-4 for-personal">契約情報</div>
          <div v-if="isContractDetailsMenuDisplay()" class="list-group-item list-group-item-action item d-flex align-items-center for-personal"
            v-bind:class="{ 'active': currentComponent === 'ContractDetails' }" 
            id="nav-contract-setting-tab" type="button" @click="selectComponent('ContractDetails')">
            <span class="setting-icon material-symbols-outlined">
              contract_edit
            </span>
            <span class="setting-title">契約内容</span>
          </div>
          <div v-if="isPaymentManagementMenuDisplay()" class="list-group-item list-group-item-action item d-flex align-items-center for-personal"
            v-bind:class="{ 'active': currentComponent === 'PaymentManagement' }" 
            id="nav-payment-isNotEnterprise-setting-tab" type="button" @click="selectComponent('PaymentManagement')">
            <span class="setting-icon material-symbols-outlined">
              payments
            </span>
            <span class="setting-title">お支払い情報</span>
          </div>
          <div v-if="isEditPlanMenuDisplay()" class="list-group-item list-group-item-action item d-flex align-items-center for-personal"
            v-bind:class="{ 'active': currentComponent === 'EditPlan' }" 
            id="payment-plan-setting-tab" type="button" @click="selectComponent('EditPlan')">
            <span class="setting-icon material-symbols-outlined">
              credit_card
            </span>
            <span class="setting-title">料金プラン</span>
          </div>
          <!-- 第二フェーズ以降に実装予定 -->
          <!-- <div class="group-title mt-4">一般</div>
          <div class="list-group-item list-group-item-action item d-flex align-items-center"
            id="nav-language-setting-tab" type="button">
            <span class="setting-icon material-symbols-outlined">
              translate
            </span>
            <span class="setting-title">言語</span>
          </div>
          <div class="list-group-item list-group-item-action item d-flex align-items-center"
            id="nav-automatic-setting-tab" type="button">
            <span class="setting-icon material-symbols-outlined">
              rotate_auto
            </span>
            <span class="setting-title">自動化</span>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Authority from "@/components/mixin/authority.js";
import { usePlanStore } from '@/stores/index';

export default {
  name: "SettingCollapseList",
  mixins: [Authority],
  props: {
    currentComponent: {
      type: String,
      required: true
    }
  },
  setup() {
    const planStore = usePlanStore();

    return {
      planStore,
    };
  },
  data() {
    return {
      hasUserOperationAuthority: false,
    }
  },
  async mounted() {
    const userOperationAuthority = await this.getUserOperationAuthority();
    this.hasUserOperationAuthority = Boolean(userOperationAuthority);
  },
  methods: {
    selectComponent(componentName) {
      this.$emit('componentChanged', componentName);
    },
    isCard() {
      if (this.planStore.isCardPayment && this.planStore.isCardRegistration) {
        return true;
      }
      return false;
    },
    // 「企業情報」内のメニューが表示されていれば「企業情報」を表示
    isEnterpriseMenuDisplay() {
      if (this.isMemberMenuDisplay()) {
        return true;
      }
      return false;
    },
    // 「メンバー」表示条件
    isMemberMenuDisplay() {
      if (this.planStore.isEnterprisePlan && this.hasUserOperationAuthority) {
        return true;
      }
      return false;
    },
    // 「契約情報」内のメニューが表示されていれば「契約情報」を表示
    isContractInfoMenuDisplay() {
      if (
        this.isContractDetailsMenuDisplay() ||
        this.isPaymentManagementMenuDisplay() ||
        this.isEditPlanMenuDisplay()
      ) {
        return true;
      }
      return false;
    },
    // 「契約内容」表示条件
    isContractDetailsMenuDisplay() {
      if (this.planStore.isEnterprisePlan) {
        if (this.planStore.isAdmin && this.isCard() && !this.planStore.isUnPaid) {
          return true;
        }
      } else {
        if (this.isCard() && !this.planStore.isUnPaid && this.planStore.isPaidPlan) {
          return true;
        }
      }
      return false;
    },
    // 「お支払い情報」表示条件
    isPaymentManagementMenuDisplay() {
      if (this.planStore.isEnterprisePlan) {
        if (this.planStore.isAdmin && this.isCard()) {
          return true;
        }
      } else {
        if (this.isCard()) {
          return true;
        }
      }
      return false;
    },
    // 「料金プラン」表示条件
    isEditPlanMenuDisplay() {
      if (this.planStore.isEnterprisePlan) {
        if (this.planStore.isAdmin) {
          return true;
        }
      } else {
        return true;
      }
      return false;
    },
  }
};
</script>

<style scoped>
.list-groups {
  font-size: 16px;
  padding: 0 5px;
}

.list-groups .group-title {
  color: var(--sub-text);
  padding-left: 15px;
}

.list-groups .group-items .item {
  padding: 8px 0;
  border: 0;
  padding-left: 15px;
  color: var(--default-text);
  border-radius: 5px;
}

.list-groups .group-items .item.active {
  background-color: transparent;
  font-weight: bold;
}

.list-groups .group-items .item .setting-icon {
  font-size: 21px;
  margin-right: 5px;
}

.list-groups .group-items .item .setting-title {
  padding-top: 4px;
}
</style>