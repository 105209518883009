<template>
  <div class="file-info-content">
    <div v-if="isFileInfoLoading" class="minutes-loading mt-4 text-center">
      <vue-element-loading
        background-color="rgba(255, 255, 255, .7)"
        :active="isFileInfoLoading"
        :fullscreen="false"
        spinner="spinner"
        color="#7e858c"
      />
    </div>
    <div v-else>
      <div class="file-info-form">
        <div
          class="
            tab-button-group
            mt-0
            d-flex
            justify-content-between
            result-button-group
          "
        >
          <div class="right">
            <button
              class="file-info-edit btn"
              id="fileInfoEditorButton"
              @click="$emit('editFileInfo')"
              :class="{ 'd-none': isDetailInfoUpdate }"
              :disabled="fileInfo.permission?.write_permission == false"
            >
              <span class="material-symbols-outlined"> edit_square </span>
              <span class="btn-title">編集</span>
            </button>
            <button
              class="file-info-cancel btn"
              id="fileInfoCancel"
              @click="$emit('cancelUpdateFileInfoDetail')"
              :class="{ 'd-none': !isDetailInfoUpdate }"
            >
              <span class="material-symbols-outlined"> undo </span>
              <span class="btn-title">戻る</span>
            </button>
            <button
              class="file-info-save btn"
              :class="{ 'd-none': !isDetailInfoUpdate }"
              id="fileInfoSave"
              :disabled="
                errors.inputMeetingName ||
                errors.inputParticipants ||
                errors.inputLocation ||
                errors.inputPurpose
              "
              @click="$emit('updateFileInfoDetail')"
            >
              <span class="material-symbols-outlined"> save </span>
              <span class="btn-title">保存</span>
            </button>
          </div>
          <div class="left"></div>
        </div>
      </div>
      <div class="file-info">
        <div class="mb-2">
          <label for="fileInfoFormInput1" class="form-label fw-bold"
            >会議タイトル</label
          >
          <input
            autocomplete="off"
            type="text"
            class="form-control file-info-input"
            id="fileInfoFormInput1"
            placeholder="--"
            :class="{ 'form-control-plaintext': !isDetailInfoUpdate }"
            :readonly="!isDetailInfoUpdate"
            :disabled="!isDetailInfoUpdate"
            v-model="fileInfo.meeting_name"
          />
          <span v-if="errors.inputMeetingName" class="text-danger">{{
            errors.inputMeetingName
          }}</span>
        </div>
        <div class="mb-2">
          <label class="form-label fw-bold">開催日時</label>
          <div class="row" id="fileInfoFormInput2">
            <div class="col col-12 col-md-5 mb-1">
              <input
                autocomplete="off"
                type="date"
                class="form-control file-info-input border py-2"
                placeholder="yyyy/mm/dd"
                id="fileInfoFormInput2a"
                aria-label="開催日"
                v-model="fileInfo.meeting_date"
                :class="{
                  'form-control-plaintext': !isDetailInfoUpdate,
                }"
                :readonly="!isDetailInfoUpdate"
                :disabled="!isDetailInfoUpdate"
              />
            </div>
            <div class="col col-md-3">
              <input
                autocomplete="off"
                type="time"
                class="form-control file-info-input border py-2"
                placeholder="--:--"
                aria-label="開始"
                id="fileInfoFormInput2b"
                v-model="fileInfo.meeting_start_time"
                :class="{
                  'form-control-plaintext': !isDetailInfoUpdate,
                }"
                :readonly="!isDetailInfoUpdate"
                :disabled="!isDetailInfoUpdate"
              />
            </div>
            <div class="col col-1 text-center time-divider">〜</div>
            <div class="col col-md-3">
              <input
                autocomplete="off"
                type="time"
                class="form-control file-info-input border py-2"
                placeholder="--:--"
                aria-label="終了"
                id="fileInfoFormInput2c"
                v-model="fileInfo.meeting_end_time"
                :class="{
                  'form-control-plaintext': !isDetailInfoUpdate,
                }"
                :readonly="!isDetailInfoUpdate"
                :disabled="!isDetailInfoUpdate"
              />
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label for="fileInfoFormInput3" class="form-label fw-bold"
            >参加者</label
          >
          <input
            autocomplete="off"
            type="text"
            class="form-control file-info-input"
            id="fileInfoFormInput3"
            placeholder="--"
            v-model="fileInfo.meeting_participants"
            :class="{ 'form-control-plaintext': !isDetailInfoUpdate }"
            :readonly="!isDetailInfoUpdate"
            :disabled="!isDetailInfoUpdate"
          />
          <span v-if="errors.inputParticipants" class="text-danger">{{
            errors.inputParticipants
          }}</span>
        </div>
        <div class="mb-2">
          <label for="fileInfoFormInput4" class="form-label fw-bold">場所</label>
          <input
            autocomplete="off"
            type="text"
            class="form-control file-info-input"
            id="fileInfoFormInput4"
            placeholder="--"
            v-model="fileInfo.meeting_location"
            :class="{ 'form-control-plaintext': !isDetailInfoUpdate }"
            :readonly="!isDetailInfoUpdate"
            :disabled="!isDetailInfoUpdate"
          />
          <span v-if="errors.inputLocation" class="text-danger">{{
            errors.inputLocation
          }}</span>
        </div>
        <div class="mb-2">
          <label for="fileInfoFormControlTextarea1" class="form-label fw-bold"
            >目的</label
          >
          <textarea
            class="form-control file-info-input"
            id="fileInfoFormControlTextarea1"
            rows="3"
            placeholder="--"
            v-model="fileInfo.meeting_purpose"
            :class="{ 'form-control-plaintext': !isDetailInfoUpdate }"
            :readonly="!isDetailInfoUpdate"
            :disabled="!isDetailInfoUpdate"
          ></textarea>
          <span v-if="errors.inputPurpose" class="text-danger">{{
            errors.inputPurpose
          }}</span>
        </div>
        <hr />
        <div class="tab-description mt-2 mb-1">
          <span>ファイル情報</span>
        </div>
        <table class="table table-borderless px-0 table-sm detail-info-table">
          <tbody>
            <tr>
              <th width="75px" scope="row">作成日時</th>
              <td class="detail-info">{{ fileInfo.created_at }}</td>
            </tr>
            <tr>
              <th scope="row">作成者</th>
              <td class="detail-info">
                {{ fileInfo.created_member_name }}
              </td>
            </tr>
            <tr>
              <th scope="row">更新日時</th>
              <td class="detail-info">{{ fileInfo.updated_at }}</td>
            </tr>
            <tr>
              <th scope="row">更新者</th>
              <td class="detail-info">
                {{ fileInfo.updated_member_name }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileInfo",
  props: {
    isFileInfoLoading: Boolean,
    fileInfo: Object,
    errors: Object,
    isDetailInfoUpdate: Boolean,
    inputMeetingName: String,
  },
};
</script>

<style scoped>
.file-info {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100* var(--screen-height) - 300px);
}
.file-info .form-control:disabled {
  background-color: var(--white);
  border: 0;
}

.file-info .border.form-control:disabled {
  border: 0 !important;
}

.detail-info-table {
  font-size: 14px;
}

.detail-info-table th,
.detail-info-table td {
  color: var(--secondary);
}

textarea.file-info-input,
textarea.minutes-input {
  resize: none;
}

.time-divider {
  line-height: 38px;
}

.result-button-group {
  background-color: var(--background);
  border-radius: 3px;
}
</style>
