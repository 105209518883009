<template>
  <div class="modal fade" id="addUsageTimeModal" ref="confirmModal" tabindex="-1"
    aria-labelledby="addUsageTimeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header fs-4 py-2">
          <h5 class="modal-title">
            利用時間の追加
          </h5>
          <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-3 pb-1">
          <div class="ms-4">
            <p>決済元クレジットカード</p>
            <p>{{ cardNumber }}（{{ expMonth }}/{{ expYear }}）</p>
            <p>利用時間の追加を開始します</p>
            <p>時間：<span class="fw-bold">{{ addTime }}</span>時間</p>
            <p>料金：<span class="fw-bold">{{ calculatePrice().toLocaleString() }}</span>円</p>
          </div>
        </div>
        <div class="modal-footer py-1 border-0">
          <button type="button" class="btn" @click="hideConfirmModal()">
            キャンセル
          </button>
          <button type="button" class="btn btn-primary" @click="createSubscription()">利用時間の追加</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="addUsageTimeSuccessModal" ref="completeModal" tabindex="-1"
    aria-labelledby="addUsageTimeSuccessModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header fs-4 py-2">
          <h5 class="modal-title w-100 text-center">
            完了
          </h5>
          <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-3 pb-1">
          <div class="text-center">
            <p>利用時間の追加が完了しました。</p>
          </div>
        </div>
        <div class="modal-footer py-1 border-0">
          <button type="button" class="btn btn-primary" data-bs-toggle="collapse" data-bs-target="#collapseAddUsageTime"
            aria-expanded="false" aria-controls="collapseAddUsageTime" @click="hideCompleteModal()">設定画面へ戻る</button>
        </div>
      </div>
    </div>
  </div>
  <div class="settings-content">
    <div class="content">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-contract-setting" role="tabpanel"
          aria-labelledby="nav-contract-setting-tab">
          <div class="plan-contract-detail">
            <div class="page-title ps-0 mb-2">
              <h3>契約内容</h3>
            </div>
            <div class="setting-titles">
              <a class="settings-selector btn w-100 d-flex justify-content-between align-items-center"
                data-bs-toggle="collapse" href="#settingsCollapse" role="button" aria-expanded="false"
                aria-controls="settingsCollapse">
                <h5 class="mb-0">設定: 契約内容</h5>
                <span class="material-symbols-outlined fs-1">
                  keyboard_arrow_down
                </span>
              </a>
              <SettingCollapseList @componentChanged="changeComponent" currentComponent="ContractDetails" />
              <hr class="mt-2">
            </div>
            <div class="setting-item py-2 pe-4">
              <div class="plan-detail px-3 py-2 rounded-2">
                <div class="d-flex current-plan justify-content-between">
                  <div class="plan-name">現在のプラン：
                    <span class="fw-bold fs-4 plan-standard">
                      {{ planName }}
                    </span>
                    <div v-if="!isEnterprisePlan" class="subtitle fw-normal">
                      プランをアップグレードすると、さらに多くの時間と機能をご利用いただけます。
                    </div>
                  </div>
                  <button v-if="!isEnterprisePlan" id="planChangeButton" class="plan-change-btn btn btn-primary pt-2"
                    @click="changeComponent('EditPlan')">プラン変更</button>
                </div>
                <div class="subtitle fw-normal mt-4 mb-2">残り：{{ displayUsageTime }} / {{ displayPlanUsageTime }}</div>
                <div class="progress my-2">
                  <div class="progress-bar bg-personal-plan" role="progressbar" :aria-valuenow="usageTime"
                    :aria-valuemin="0" :aria-valuemax="usageAddTime" :style="{ width: timePercentage + '%' }">
                  </div>
                </div>
                <div v-if="isPaidPlan" class="subtitle fw-normal">利用可能時間は{{ periodDateTime }}に追加されます</div>
              </div>
            </div>
            <div class="setting-item">
              <div class="setting-name mb-3">
                <span class="fw-bold">ご請求の情報</span>
              </div>
              <div class="d-flex my-2">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">次回のご請求日</p>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <p class="form-control border-0 m-0">{{ periodDate }}</p>
                </div>
              </div>
              <div class="d-flex my-2">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">次回のご請求額(暫定)</p>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <p class="form-control border-0 m-0">{{ billing }}円</p>
                </div>
              </div>
              <div class="d-flex my-2">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">請求先クレジットカード</p>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <p class="form-control border-0 m-0">{{ cardNumber }}</p>
                </div>
              </div>
            </div>
            <div v-if="isPaidPlan && !isEnterprisePlan" class="setting-item border-bottom-0">
              <div class="setting-name mb-3">
                <span class="fw-bold">利用時間の追加</span>
              </div>
              <div class="collapse" id="collapseAddUsageTime">
                <div class="p-0">
                  <div class="d-flex mb-4">
                    <div class="col-5">
                      <p class="setting-label form-control border-0 px-0 m-0">時間単位での追加</p>
                      <span class="setting-description">1時間：300円</span>
                    </div>
                    <div class="col-lg-1 col-md-2 d-flex flex-column justify-content-between align-items-start">
                      <input type="number" min="0" class="form-control file-info-input" id="addTimeFormInput"
                        placeholder="0" v-model="addTime" />
                    </div>
                  </div>
                  <span v-if="addTimeError" class="editable-error col-12">
                    {{
                      addTimeError
                    }}
                  </span>
                  <div class="d-flex mb-3">
                    <div class="col-5"></div>
                    <div class="col-5">
                      <button class="add-usage-time-btn btn" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseAddUsageTime" aria-expanded="false"
                        aria-controls="collapseAddUsageTime" @click="closeAddUsageForm()">
                        キャンセル
                      </button>
                      <button class="add-usage-time-btn btn btn-primary" type="button" :disabled="addTimeError"
                        @click="showConfimModal()">
                        追加する
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex add-usage-time" :class="{ 'd-none': showAddUsageForm }">
                <div class="col-5">
                  <label class="setting-label form-control border-0 px-0">時間単位での追加</label>
                  <span class="setting-description">1時間：300円</span>
                </div>
                <div class="col-lg-2 col-md-3 d-flex  flex-column justify-content-between align-items-start">
                  <button class="add-usage-time-form-btn btn btn-outline-primary" type="button"
                    data-bs-toggle="collapse" data-bs-target="#collapseAddUsageTime" aria-expanded="false"
                    aria-controls="collapseAddUsageTime" @click="openAddUsageForm()">
                    時間を追加する
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed } from 'vue';
import axios from "@/axios";
import firebase from "firebase/app";
import "firebase/auth"
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
import { Modal } from 'bootstrap';
import { usePlanStore } from '@/stores/index';
import { usePlanInfo } from "@/components/composables/planInfo";
import { storeToRefs } from 'pinia';

// modules
import MyHeader from "@/components/modules/MyHeader.vue";
import MySidebar from "@/components/modules/MySidebar.vue";
import SettingSidebar from "@/components/modules/SettingSidebar.vue";
import SettingCollapseList from "@/components/modules/SettingCollapseList.vue";

// mixin
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import StripeFunc from "@/components/mixin/StripeFunc.js";
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import UsageTime from "@/components/mixin/usageTime.js";

export default {
  mixins: [BaseFunctions, ArrangeDate, StripeFunc, UsageTime],
  name: "ContractDetails",
  emits: ['componentChanged'],
  components: {
    MyHeader,
    MySidebar,
    SettingSidebar,
    SettingCollapseList,
  },
  data() {
    return {
      currentUser: firebase.auth().currentUser,
      // stripe
      cardNumber: "",
      expYear: "",
      expMonth: "",
      billing: 0,

      showAddUsageForm: false,
      usageTime: 0,
      displayUsageTime: '--',
      displayPlanUsageTime: '-分',
      timePercentage: 0,
      confirmModal: null,
      completeModal: null,
    };
  },
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    const schema = yup.object({
      addTime: yup.number().typeError("1以上の整数値で時間を入力してください。").min(1, "1以上の整数値で時間を入力してください。")
    });

    useForm({
      validationSchema: schema,
      initialValues: {
        addTime: 1,
      },
      validateOnMount: true,
    });

    const { value: addTime, errorMessage: addTimeError } = useField('addTime');

    const planStore = usePlanStore();
    const {
      isEnterprisePlan,
      planName,
      isPaidPlan,
      isCardRegistration,
      isNextFreePlan,
      usageAddTime,
      subscriptionPeriodEnd,
      subscriptionPeriodEndDate,
      subscriptionPeriodEndDateTime,
    } = storeToRefs(planStore);

    const periodDate = computed(() => {
      let periodDate = '請求予定はありません';

      if (subscriptionPeriodEnd.value) {
        if (!isNextFreePlan.value) {
          periodDate = subscriptionPeriodEndDate.value;
        }
      }

      return periodDate;
    });

    const periodDateTime = computed(() => {
      let periodDateTime = '';

      if (subscriptionPeriodEnd.value) {
        periodDateTime = subscriptionPeriodEndDateTime.value;
      }

      return periodDateTime;
    });

    return {
      addTime,
      addTimeError,
      showLoading,
      hideLoading,
      isEnterprisePlan,
      isPaidPlan,
      planName,
      isCardRegistration,
      usageAddTime,
      periodDate,
      periodDateTime
    }
  },
  mounted() {
    this.confirmModal = new Modal(this.$refs.confirmModal);
    this.completeModal = new Modal(this.$refs.completeModal);
  },
  async created() {
    this.showLoading();
    await usePlanInfo().getPlanInfo();
    // usage_time情報取得
    const usagetime = await this.getUsageTime();
    this.usageTime = usagetime.time;
    this.displayUsageTime = this.convertSecondsToMinuteForDisplay(usagetime.time);
    if (this.isCardRegistration) {
      // stripe情報取得
      await this.getStripeInfo().then((res) => {
        if (res.status) {
          this.cardNumber = res.cardNumber;
          this.expYear = res.expYear;
          this.expMonth = res.expMonth;
        } else {
          alert("カード情報の取得に失敗しました。");
        }
      });
      // 契約情報取得
      await this.getBilling().then((res) => {
        if (res.status) {
          this.billing = res.billing;
        } else {
          alert("契約情報の取得に失敗しました。");
        }});
      if (this.billing == 0) {
        this.periodDate = '請求予定はありません';
      }
    }
    if (this.isPaidPlan) {
      this.displayPlanUsageTime = this.convertSecondsToMinuteForDisplay(this.usageAddTime);
    }
    this.calculateUsageTime();
    this.hideLoading();
  },
  methods: {
    changeComponent(componentName) {
      this.$emit('componentChanged', componentName);
    },
    openAddUsageForm() {
      this.showAddUsageForm = true;
    },
    closeAddUsageForm() {
      this.addTime = 1;
      this.showAddUsageForm = false;
    },
    // 残り時間の割合を算出
    calculateUsageTime() {
      this.timePercentage = (this.usageTime / this.usageAddTime) * 100;
    },
    // 料金を算出
    calculatePrice() {
      const pricePerHour = 300;
      if (this.addTime > 0) {
        return this.addTime * pricePerHour;
      } else {
        return 0;
      }
    },
    showConfimModal() {
      if (this.addTime <= 0) {
        alert("1以上の整数値で時間を入力してください。");
        return;
      }
      this.confirmModal.show();
    },
    hideConfirmModal() {
      this.confirmModal.hide();
    },
    showCompleteModal() {
      this.completeModal.show();
    },
    hideCompleteModal() {
      this.closeAddUsageForm();
      this.completeModal.hide();
    },
    // サブスクリプションの作成
    async createSubscription() {
      if (this.addTime <= 0) {
        alert("1以上の整数値で時間を入力してください。");
        return;
      }
      this.showLoading();

      // 1.決済処理
      const params = {
        price: this.calculatePrice(),
      };
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/add_time", params)
        .then((response) => {
          if (response.data.status) {
          } else {
            alert("予期せぬエラーが発生しました。\n決済は中断されます。");
            this.hideLoading();
            return;
          }
        })
        .catch(() => {
          alert("予期せぬエラーが発生しました。\n決済は中断されます。");
          this.hideLoading();
          return;
        });

      // 2. Firestoreにサブスクリプションを追加
      const FS_result = await this.addFSSubscription(this.addTime);
      if (FS_result !== void 0) {
        alert("予期せぬエラーが発生しました。\n決済は中断されます。");
        this.hideLoading();
        return;
      }

      // 3. 成功のモーダルを表示
      this.hideLoading();
      this.hideConfirmModal();
      this.showCompleteModal();
      this.updateTimePercentage(this.addTime);
    },
    // firestore にサブスクリプション情報追加
    async addFSSubscription(addTime) {
      const secondsAddTime = this.convertHourToSeconds(addTime);
      const res = await this.addUsageTime(secondsAddTime);
      return res;
    },
    // 残り時間の割合の再計算
    updateTimePercentage(addTime) {
      const secondsAddTime = this.convertHourToSeconds(addTime);
      this.usageTime = parseInt(this.usageTime) + secondsAddTime;
      this.displayUsageTime = this.convertSecondsToMinuteForDisplay(this.usageTime)
      this.calculateUsageTime();
    },
    // 秒から時分秒への変換（表示用）
    convertSecondsToMinuteForDisplay(timeSeconds) {
      if (timeSeconds == 0) {
        return '--分--秒';
      }
      let hours = Math.floor(timeSeconds / 3600);
      let minutes = Math.floor((timeSeconds % 3600) / 60);
      let seconds = timeSeconds % 60;
      let displayTime = "";

      if (hours > 0) {
        displayTime += `${hours}時間`;
      }
      if (minutes > 0) {
        displayTime += `${minutes}分`;
      }
      if (seconds > 0) {
        displayTime += `${seconds}秒`;
      }
      
      return displayTime.trim();
    },
    // 時から秒への変換
    convertHourToSeconds(addTime) {
      const unitSeconds = 3600;
      return addTime * unitSeconds;
    },
  }
};
</script>

<style scoped>
.usaging-progress {
  height: 30px;
}

.bg-remaining {
  background-color: var(--primary);
}

.bg-usaging {
  background-color: var(--sub-primary);
}

.bg-personal-plan {
  background-color: var(--shared);
}

.setting-item .setting-name {
  font-size: 16px;
}

.setting-item .text-secondary {
  font-size: 0.8rem;
}

.setting-item {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--actived);
}

.setting-description {
  font-size: 0.73rem;
  color: var(--sub-text);
  display: inline-block;
  transform: translateY(-10px);
}

.plan-detail {
  background-color: var(--fade-background);
}

.editable-error {
  margin: 5px 0 0;
  color: #cc0000;
}
</style>
