import { defineStore } from 'pinia';
import io from 'socket.io-client';

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    memberMstId: null,
    corpMstId: null,
  }),
  actions: {
      setUserInfo(userInfo) {
          this.memberMstId = userInfo.member_mst_id;
          this.corpMstId = userInfo.corp_mst_id;
      },
  }
});

export const useSidebarStore = defineStore('sidebar', {
    state: () => ({
        isCollapsed: true,
    }),
    getters: {
        collapsed: (state) => { return state.isCollapsed },
    },
    actions: {
        toggleSidebar() {
            this.isCollapsed = !this.isCollapsed;
        },
        setSidebarState(payload) {
            this.isCollapsed = payload;
        },
    }
});

export const useTranscriptionStore = defineStore('transcription', {
  state: () => ({
    remainingTime: "",
    speechSituation: 0, // 0:before, 1:start, 2:finish
    speech: null,
    speechCount: 0,
    destroyed: false,
    timerToadd2: 0,
    mediaRecorder: null,
    blob: null,
    script: "",
    scriptTime: "",
    recordedCount: 0,
    recordedStart: [],
    recordedEnd: [],
    recordedTimer: [],
    recordedText: [],
    recordedChunks: [],
    recordedUser: [],
    whichAPI: 0,
    socket_ingestor: null,
    socket_reviewer: null,
    fileName: "",
    recordedTime: "",
    nameRecord: false,
    timeRecord: true,
    isRealtime: false,
  }),
  actions: {
    setSocketIngestor(IP) {
      this.socket_ingestor = io(IP, {
        transports: ["websocket"],
      });
    },
    setSocketReviewer(IP) {
      this.socket_reviewer = io(IP, {
        transports: ["websocket"],
      });
    },
    setScript(val) {
      this.script += val;
    },
    setScriptTime(val) {
      this.scriptTime += val;
    },
    setTimerToadd(val) {
      this.timerToadd2 += val;
    },
    setWhichAPI(val) {
      this.whichAPI = val ?? (this.whichAPI + 1) % 2;
    },
    setRemainingTime(val) {
      this.remainingTime = val;
    },
    resetSpeechSituation() {
      Object.assign(this, {
        remainingTime: "",
        speechSituation: 0,
        speech: null,
        speechCount: 0,
        destroyed: false,
        timerToadd2: 0,
        mediaRecorder: null,
        blob: null,
        script: "",
        scriptTime: "",
        recordedCount: 0,
        recordedStart: [],
        recordedEnd: [],
        recordedTimer: [],
        recordedText: [],
        recordedChunks: [],
        recordedUser: [],
        whichAPI: 0,
        socket_ingestor: null,
        socket_reviewer: null,
        fileName: "",
        recordedTime: "",
        nameRecord: false,
        timeRecord: true,
        isRealtime: false,
      });
    },
    startSpeechSituation() {
      this.speechSituation = 1;
    },
    finishSpeechSituation() {
      this.speechSituation = 2;
    },
    pushRecordedText(val) {
      this.recordedText.push(val);
    },
    pushRecordedTimer(val) {
      this.recordedTimer.push(val);
    },
    setRecordedTimer(val) {
      this.recordedTimer = val;
    },
    pushRecordedUser(val) {
      this.recordedUser.push(val);
    },
    pushRecordedStart(val) {
      this.recordedStart.push(val);
    },
    setRecordedStart(val) {
      this.recordedStart = val;
    },
    pushRecordedEnd(val) {
      this.recordedEnd.push(val);
    },
    setRecordedEnd(val) {
      this.recordedEnd = val;
    },
    addRecordedCount() {
      this.recordedCount += 1;
    },
    setRecordedCount(val) {
      this.recordedCount = val;
    },
    setBlob(val) {
      this.blob = new Blob(val);
    },
    setMediaRecorder(val1, val2) {
      this.mediaRecorder = new MediaRecorder(val1, val2);
    },
    dataaddStartMediaRecorder() {
      this.mediaRecorder.addEventListener("dataavailable", (e) => {
        if (e.data.size > 0) {
          this.recordedChunks.push(e.data);
        }
      });
    },
    startMediaRecorder() {
      this.mediaRecorder.start();
    },
    resumeMediaRecorder() {
      this.mediaRecorder.resume();
    },
    pauseMediaRecorder() {
      this.mediaRecorder.pause();
    },
    stopMediaRecorder() {
      this.mediaRecorder.stop();
    },
    setSpeech(language) {
      this.speech = new webkitSpeechRecognition();
      this.speech.lang = language;
      this.speech.interimResults = true;
    },
    startSpeech() {
      this.speech.start();
    },
    abortSpeech() {
      this.speech.abort();
    },
    addSpeechCount() {
      this.speechCount += 1;
    },
    finishSpeechCount() {
      this.speechCount = 10000;
    },
    setFileName(val) {
      this.fileName = val;
    },
    saveRecordedText(val) {
      this.recordedText = val;
    },
    setRecordedTime(val) {
      this.recordedTime = val;
    },
    setRecordedUser(val) {
      this.recordedUser = val;
    },
    setTimeRecord(val) {
      this.timeRecord = val;
    },
    setNameRecord(val) {
      this.nameRecord = val;
    },
    setIsRealtime(val) {
      this.isRealtime = val;
    },
    updateDestroyed() {
      this.isRealtime = true;
    },
  },
});

export const useFileSearchStore = defineStore('fileSearch', {
  state: () => ({
    meetingName: null,
    keyword: null,
    fileTypes: [],
    statuses: [],
    conversions: [],
    createdMemberMstIds: [],
    createdAtFrom: null,
    createdAtTo: null,
    updatedMemberMstIds: [],
    updatedAtFrom: null,
    updatedAtTo: null,
    clearAllChecked: false,
  }),
  getters: {
    getConditions: (state) => {
      return {
        keyword: state.keyword,
        fileTypes: state.fileTypes,
        statuses: state.statuses,
        conversions: state.conversions,
        createdMemberMstIds: state.createdMemberMstIds,
        createdAtFrom: state.createdAtFrom,
        createdAtTo: state.createdAtTo,
        updatedMemberMstIds: state.updatedMemberMstIds,
        updatedAtFrom: state.updatedAtFrom,
        updatedAtTo: state.updatedAtTo,
      };
    },
    getQuickSearch: (state) => {
      return {
        meetingName: state.meetingName,
      };
    },
    isConditions: (state) => {
      if (state.keyword || state.fileTypes.length > 0 || state.statuses.length > 0 ||
        state.conversions.length > 0 || state.createdMemberMstIds.length > 0 ||
        state.createdAtFrom || state.createdAtTo || state.updatedMemberMstIds.length > 0 ||
        state.updatedAtFrom || state.updatedAtTo
      ) {
        return true;
      }

      return false;
    },
  },
  actions: {
    setMeetingName(meetingName) {
      this.meetingName = meetingName;
    },
    setKeyword(keyword) {
      this.keyword = keyword;
    },
    setFileTypes(fileTypes) {
      this.fileTypes = fileTypes;
    },
    setStatuses(statuses) {
      this.statuses = statuses;
    },
    setConversions(conversions) {
      this.conversions = conversions;
    },
    setCreatedMemberMstIds(createdMemberMstIds) {
      this.createdMemberMstIds = createdMemberMstIds;
    },
    setCreatedAtFrom(createdAtFrom) {
      this.createdAtFrom = createdAtFrom;
    },
    setCreatedAtTo(createdAtTo) {
      this.createdAtTo = createdAtTo;
    },
    setUpdatedMemberMstIds(updatedMemberMstIds) {
      this.updatedMemberMstIds = updatedMemberMstIds;
    },
    setUpdatedAtFrom(updatedAtFrom) {
      this.updatedAtFrom = updatedAtFrom;
    },
    setUpdatedAtTo(updatedAtTo) {
      this.updatedAtTo = updatedAtTo;
    },
    clearFileSearch() {
      this.meetingName = null;
      this.keyword = null;
      this.fileTypes = [];
      this.statuses = [];
      this.conversions = [];
      this.createdMemberMstIds = [];
      this.createdAtFrom = null;
      this.createdAtTo = null;
      this.updatedMemberMstIds = [];
      this.updatedAtFrom = null;
      this.updatedAtTo = null;
    },
    setClearAllChecked() {
      this.clearAllChecked = !this.clearAllChecked;
    },
  }
});

export const useFileShareStore = defineStore('fileShare', {
  state: () => ({
    fileId: null,
    execFileListUpdate: false,
  }),
  actions: {
    setFileId(fileId) {
      this.fileId = fileId;
    },
    setExecFileListUpdate(execFileListUpdate) {
      this.execFileListUpdate = execFileListUpdate;
    },
  }
});

export const usePlanStore = defineStore('plan', {
  state: () => ({
    planId: null,
    planName: null,
    isPaidPlan: false,
    isEnterprisePlan: false,
    subscriptionPeriodEnd: null,
    maximumMembersCount: 0,
    isCardPayment: false,
    isCardRegistration: false,
    isNextFreePlan: false,
    stripeUnitAmount: 0,
    usageAddTime: 0,
    isAdmin: false,
    today: new Date(),
    errorMessage: null,
  }),
  getters: {
    isUnpaid: (state) => {
      if (state.isCardPayment && state.subscriptionPeriodEnd == null) {
        // 未決済
        return true;
      }

      let subscriptionPeriodEnd = new Date(state.subscriptionPeriodEnd);
      if (state.isCardPayment && subscriptionPeriodEnd < state.today) {
        // 契約終了
        return true;
      }

      return false;
    },
    subscriptionPeriodEndDate: (state) => {
      if (state.subscriptionPeriodEnd == null) {
        return '';
      }
      
      const periodEndDate = new Date(state.subscriptionPeriodEnd);
      const year = periodEndDate.getFullYear();
      const month = String(periodEndDate.getMonth() + 1).padStart(2, '0');
      const day = String(periodEndDate.getDate()).padStart(2, '0');
      const periodDate = year + '/' + month + '/' + day;
      return periodDate;
    },
    subscriptionPeriodEndDateTime: (state) => {
      if (state.subscriptionPeriodEnd == null) {
        return '';
      }
      
      const periodEndDate = new Date(state.subscriptionPeriodEnd);
      const year = periodEndDate.getFullYear();
      const month = String(periodEndDate.getMonth() + 1).padStart(2, '0');
      const day = String(periodEndDate.getDate()).padStart(2, '0');
      const hours = String(periodEndDate.getHours()).padStart(2, '0');
      const minutes = String(periodEndDate.getMinutes()).padStart(2, '0');
      const periodDate = year + '/' + month + '/' + day + ' ' + hours + ':' + minutes;
      return periodDate;
    },
  },
  actions: {
    setPlan(plan) {
      this.planId = plan.plan_id;
      this.planName = plan.plan_name;
      this.isPaidPlan = plan.is_paid_plan;
      this.isEnterprisePlan = plan.is_enterprise_plan;
      this.subscriptionPeriodEnd = plan.subscription_period_end;
      this.maximumMembersCount = plan.maximum_members_count;
      this.isCardPayment = plan.is_card_payment;
      this.isCardRegistration = plan.is_card_registration;
      this.isNextFreePlan = plan.is_next_free_plan;
      this.stripeUnitAmount = plan.stripe_unit_amount;
      this.usageAddTime = plan.usage_add_time;
      this.isAdmin = plan.is_admin;
      this.today = new Date();
    },
    updateToday() {
      this.today = new Date();
    },
    setErrorMessage(msg) {
      this.errorMessage = msg;
    },
  }
});

export const useUserNotificationInfoStore = defineStore('userNotificationInfo', {
  state: () => ({
    notificationsCount: null,
    notifications: null,
    notificationLastUpdated: null,
  }),
  actions: {
    setNotificationCount(notificationsCount) {
      this.notificationsCount = notificationsCount;
    },
    setNotificationInfo(notificationInfo) {
        this.notifications = notificationInfo;
    },
    setNotificationLastUpdated(notificationLastUpdated) {
      this.notificationLastUpdated = notificationLastUpdated;
    },
  }
});
