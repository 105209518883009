<template>
  <div id="transcribeSection" class="transcribe-section">
    <div class="transcribe-header d-flex justify-content-between" @click="showTranscribe()">
      <div>
        文字起こし<br />
        <span class="sub-text">収録言語：{{ recordedLanguage }}</span>
      </div>
      <div class="fw-normal top-timer">
        <span class="material-symbols-outlined text-primary me-2">mic</span>
        <span class="timer" id="transcribeTimerTop">{{ !isAudioLoading ? formatTime(currentTime) : formatTime(0) }}</span>
      </div>
    </div>
    <div
      class="
        tab-button-group
        my-0
        d-flex
        justify-content-between
        result-button-group
      "
      :class="{ 'invisible': isTranscribeLoading }"
    >
      <div class="right">
        <template v-if="!isTranscribeEdit">
          <button
            class="transcribe-edit btn"
            @click="transcribeEdit"
            :disabled="fileInfo.permission?.write_permission == false"
          >
            <span class="material-symbols-outlined">edit_square</span>
            <span class="btn-title">編集</span>
          </button>
          <button
            v-if="!isTranscribeEdit"
            class="transcribe-download btn"
            @click="downloadTranscribe"
          >
            <span class="material-symbols-outlined">download</span>
            <span class="btn-title">ダウンロード</span>
          </button>
        </template>
        <template v-else>
          <button class="transcribe-cancel btn" @click="cancelTranscribeEdit">
            <span class="material-symbols-outlined">undo</span>
            <span class="btn-title">戻る</span>
          </button>
          <button class="transcribe-save btn" @click="saveTranscribeEdit">
            <span class="material-symbols-outlined">save</span>
            <span class="btn-title">保存</span>
          </button>
        </template>
      </div>
      <div class="left">
        <button
          type="button"
          class="transcribe-result-menu btn"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span class="material-symbols-outlined">more_vert</span>
        </button>
        <ul class="details-dropdown dropdown-menu dropdown-menu-end">
          <li>
            <a type="button" class="dropdown-item py-2" @click="copyTranscribeToClipboard">
              <span class="material-symbols-outlined">content_copy</span>
              クリップボードへコピー
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="transcribe-content" id="transcribeContent">
      <div v-show="isTranscribeLoading" class="translation-loading mt-4 text-center">
        <vue-element-loading
          background-color="rgba(255, 255, 255, .7)"
          :active="isTranscribeLoading"
          :fullscreen="false"
          spinner="spinner"
          color="#7e858c"
        />
      </div>
      <div v-show="!isTranscribeLoading" v-for="(text_content, index) in textContents" :key="index">
        <div :id="'transcribeBlock' + index" class="transcribe-block">
          <div
            class="
              block-detail
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="d-flex mb-1">
              <span
                class="timestamp"
                :class="{
                  'd-none': !timestampDisplay,
                  'p-2 rounded': isTranscribeEdit,
                  'p-0': !isTranscribeEdit,
                }"
                @click.stop="seekAudioByBlock(text_content.time)"
              >
                {{ text_content.time }}
              </span>
              <input
                autocomplete="off"
                class="participant form-control"
                :class="{
                  'd-none': !participantDisplay,
                  'bg-white': isTranscribeEdit,
                  'border-0 p-0 bg-transparent': !isTranscribeEdit,
                }"
                @click.stop="seekAudioByBlock(text_content.time)"
                :readonly="!isTranscribeEdit"
                @input="draftTranscribeEdit(index, 'participant', $event)"
                :value="text_content.participant"
              />
            </div>
            <div>
              <button
                v-show="isTranscribeEdit"
                class="btn btn-sm text-primary playback-btn"
                :class="{
                  'disabled': isAudioLoading,
                }"
                @click.stop="playAudioByBlock(text_content.time, index)"
              >
                再生
              </button>
            </div>
          </div>
          <textarea
            class="block-content form-control"
            :class="{
              'bg-white': isTranscribeEdit,
              'border-0 p-0 bg-transparent disabled': !isTranscribeEdit,
            }"
            :readonly="!isTranscribeEdit"
            @click.stop="seekAudioByBlock(text_content.time)"
            @input="draftTranscribeEdit(index, 'dialogue', $event)"
            @keydown.enter.prevent
            :value="text_content.dialogue"
          ></textarea>
        </div>
      </div>
    </div>
    
    <div class="translation-loading mt-4 text-center">
      <vue-element-loading
        background-color="rgba(255, 255, 255, .7)"
        :active="isAudioLoading"
        :fullscreen="false"
        spinner="spinner"
        color="#7e858c"
      />
    </div>

    <div v-if="fileInfo.audio_path && !isAudioLoading" class="audio-progress">
      <input
        type="range"
        min="0"
        :max="audioDuration"
        :value="currentTime"
        @input="seekAudio($event.target.value)"
        :disabled="!fileInfo.audio_path"
      />
    </div>
    <audio
      v-if="fileInfo.audio_path"
      class="d-none"
      ref="player"
      :src="fileInfo.audio_path"
      @timeupdate="updateProgress"
      @loadedmetadata="updateAudioDuration"
    />
    <div
      class="
        transcribe-media
        d-flex
        justify-content-between
        align-items-center
        px-2
      "
    >
      <span v-if="fileInfo.audio_path" class="spacing"></span>
      <div
        v-if="!isAudioLoading"
        :class="{
          'w-100': !fileInfo.audio_path,
        }"
        class="media-buttons d-flex justify-content-evenly align-items-center"
      >
        <div class="settings-btn">
          <div
            class="btn-group dropup"
            :class="{ 'invisible': isTranscribeLoading && !fileInfo.audio_path }"
          >
            <a
              type="button"
              class="material-symbols-outlined"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
            >
              <span
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="表示設定"
              >
                playlist_add_check_circle
              </span>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" type="button">
                  <div
                    class="media-settings-switch mb-1 form-check form-switch"
                  >
                    <input
                      autocomplete="off"
                      class="form-check-input"
                      type="checkbox"
                      id="timestampSwitch"
                      v-model="currentTimestampDisplay"
                      @change="setTimedisplay"
                      :disabled="fileInfo.permission?.write_permission == false"
                    />
                    <label
                      class="form-check-label media-settings-switch-label"
                      for="timestampSwitch"
                      >時間表示</label
                    >
                  </div>
                </a>
              </li>
              <li>
                <a class="dropdown-item" type="button">
                  <div
                    class="media-settings-switch mb-1 form-check form-switch"
                  >
                    <input
                      autocomplete="off"
                      class="form-check-input"
                      type="checkbox"
                      id="participantSwitch"
                      v-model="currentParticipantDisplay"
                      @change="setParticipantDisplay"
                      :disabled="fileInfo.permission?.write_permission == false"
                    />
                    <label
                      class="form-check-label media-settings-switch-label"
                      for="participantSwitch"
                      >話者表示</label
                    >
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div v-if="fileInfo.audio_path" class="play-btn">
          <a
            type="button"
            class="material-symbols-outlined icon-fill"
            id="toggle-button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="再生/停止"
            @click="togglePlayPause"
          >
            {{ isPlaying ? "pause_circle" : "play_circle" }}
          </a>
        </div>
        <div
          v-if="fileInfo.audio_path"
          class="audio-download-btn"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="音声保存"
          @click="downloadAudio"
          :disabled="!fileInfo.audio_path"
        >
          <span type="button" class="material-symbols-outlined">
            download_for_offline
          </span>
        </div>
      </div>
      <div v-if="fileInfo.audio_path" class="recording-info d-flex">
        <span class="material-symbols-outlined">mic</span>
        <canvas ref="canvas" />
        <span class="timer" id="transcribeTimer">
          {{ !isAudioLoading ? formatTime(currentTime) : formatTime(0) }}/{{ !isAudioLoading ? formatTime(audioDuration) : formatTime(0) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { ref } from "vue";
import { useAVWaveform } from "vue-audio-visual";

export default {
  name: "TranscribeResult",
  props: [
    "fileInfo",
    "textContents",
    "isTranscribeLoading",
    "isAudioLoading",
    "isTranscribeEdit",
    "timestampDisplay",
    "participantDisplay",
    "isPlaying",
    "currentTime",
    "audioDuration",
  ],
  setup() {
    const player = ref(null);
    const canvas = ref(null);
    const audioSource = ref(null);

    return {
      player,
      canvas,
      audioSource,
    };
  },
  data() {
    return {
      currentTimestampDisplay: this.timestampDisplay,
      currentParticipantDisplay: this.participantDisplay,
      isMobileView: false,
    };
  },
  watch: {
    timestampDisplay(newVal) {
      if (newVal !== this.currentTimestampDisplay) {
        this.currentTimestampDisplay = newVal;
      }
    },
    participantDisplay(newVal) {
      if (newVal !== this.currentParticipantDisplay) {
        this.currentParticipantDisplay = newVal;
      }
    },
    isTranscribeLoading(oldVal, newVal) {
      if (newVal) {
        nextTick(() => {
          this.adjustTextareaHeight();
        });
      }
    },
  },
  methods: {
    setTimedisplay() {
      this.$emit("setTimedisplay", this.currentTimestampDisplay);
    },
    setParticipantDisplay() {
      this.$emit("setParticipantDisplay", this.currentParticipantDisplay);
    },
    togglePlayPause() {
      this.$emit("togglePlayPause");
    },
    downloadAudio() {
      this.$emit("downloadAudio");
    },
    updateProgress() {
      this.$emit("updateProgress");
    },
    updateAudioDuration() {
      this.$emit("updateAudioDuration");
    },
    seekAudio(time) {
      this.$emit("seekAudio", time);
    },
    transcribeEdit() {
      this.$emit("transcribeEdit");
      // 編集の時にテキストエリアの高さを調整
      this.adjustTextareaHeight(10);
    },
    cancelTranscribeEdit() {
      this.$emit("cancelTranscribeEdit");
      // 編集キャンセル時にテキストエリアの高さを調整
      this.adjustTextareaHeight();
    },
    saveTranscribeEdit() {
      this.$emit("saveTranscribeEdit");
    },
    downloadTranscribe() {
      this.$emit("downloadTranscribe");
    },
    copyTranscribeToClipboard() {
      this.$emit("copyTranscribeToClipboard");
    },
    seekAudioByBlock(time) {
      if(!this.isAudioLoading) {
        this.$emit("seekAudioByBlock", time);
      }
    },
    playAudioByBlock(time, index) {
      if(!this.isAudioLoading) {
        this.$emit("playAudioByBlock", time, index);
      }
    },
    draftTranscribeEdit(index, attr, $event) {
      this.$emit("draftTranscribeEdit", index, attr, $event);
      // 編集中の時にテキストエリアの高さを調整
      $event.target.style.height = 'auto';
      $event.target.style.height = `${$event.target.scrollHeight + 10}px`;
    },
    formatTime(seconds) {
      if (isNaN(seconds) || seconds === Infinity) {
        return "-";
      }
      const minutes = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
    },
    async updateAudioSource(audioPath) {
      this.audioSource = audioPath;
      useAVWaveform(this.player, this.canvas, {
        src: this.audioSource,
        canvHeight: 20,
        canvWidth: 50,
        noplayedLineColor: "#278bff",
        playedLineColor: "#85b4ff",
        playtimeSliderColor: "#5b7ba5",
        playtime: false,
        playtimeSlider: false,
        playtimeClickable: false,
      });
    },
    showTranscribe() {
      if (window.innerWidth <= 839) {
        const transcribeSection = document.getElementById("transcribeSection");
        if (this.isMobileView == false) {
          this.isMobileView = true;
          if (transcribeSection) {
            transcribeSection.classList.add("show");
          }
        } else {
          this.isMobileView = false;
          if (transcribeSection) {
            transcribeSection.classList.remove("show");
          }
        }
      }
    },
    adjustTextareaHeight(additionalHeight = 0) {
      const textareas = this.$el.querySelectorAll("textarea.block-content");
      // テキストエリアの高さが読み込み後で自動調整
      nextTick(() => {
        textareas.forEach((textarea) => {
          textarea.style.height = "auto";
          textarea.style.height = `${textarea.scrollHeight + additionalHeight}px`;
        });
      });
    },
  },
  computed: {
    recordedLanguage() {
      const languageMap = {
        "ja-JP": "日本語",
        "en-US": "英語",
        "zh-CN": "中国語",
        "zh-HK": "香港",
        "zh-TW": "台湾",
        "ko-KR": "韓国語",
        "hi-IN": "ヒンディー語",
        "es-ES": "スペイン語",
        "ru-RU": "ロシア語",
        "pt-BR": "ポルトガル語",
        "fr-FR": "フランス語",
        "de-DE": "ドイツ語",
      };

      return languageMap[this.fileInfo.recorded_language] || "--";
    },
  },
};
</script>

<style scoped>
.transcribe-header {
  padding: 5px 15px 15px 15px;
  border-bottom: solid 1px var(--actived);
}

.transcribe-header .sub-text {
  font-size: 14px;
  color: var(--sub-text);
}

.transcribe-media {
  height: 60px;
  align-items: center;
  padding-top: 10px;
}

.transcribe-media .material-symbols-outlined {
  color: var(--primary);
  font-size: 35px;
}

.transcribe-media .play-btn .material-symbols-outlined {
  font-size: 50px;
}

.transcribe-media .recording-info .material-symbols-outlined {
  font-size: 24px;
}

.transcribe-media .recording-info .timer {
  color: var(--sub-text);
  font-size: 12px;
  line-height: 25px;
}

.transcribe-media .media-buttons {
  width: 60%;
}

.transcribe-media .recording-info {
  border: solid 1px var(--actived);
  border-radius: 3px;
  padding: 5px;
}

.transcribe-media .spacing {
  width: 140px;
}

.audio-download-btn {
  width: 35px;
  height: 35px;
}

.wave {
  fill: none;
  stroke: var(--primary);
  stroke-width: 1;
  stroke-linecap: round;
  transition: d 0.2s ease-in-out;
}

.transcribe-content {
  height: calc(100 * var(--screen-height) - 360px);
  border-bottom: solid 1px var(--actived);
  overflow-y: auto;
}

.transcribe-block {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 3px;
  transition: background-color 0.5s ease;
}

.transcribe-block .block-detail {
  font-size: 12px;
}

.transcribe-block .block-detail .form-control.border-0:focus,
.transcribe-block .block-content.form-control.border-0:focus {
  box-shadow: none;
}

.transcribe-block .block-detail .timestamp {
  font-size: 14px;
  margin-right: 10px;
  width: 65px;
  color: var(--sub-text);
}

.transcribe-block .block-detail .timestamp.p-2 {
  background-color: var(--actived);
  border: solid 1px var(--white);
}

.transcribe-block.active .block-detail .timestamp.p-2 {
  background-color: transparent;
}

.transcribe-block .block-detail .participant {
  font-size: 14px;
  font-weight: 600;
}

.transcribe-block .block-content {
  font-size: 14px;
  width: 100%;
  resize: none;
}

.transcribe-block:hover {
  background-color: var(--actived);
}

.transcribe-block.active {
  background-color: var(--sub-primary);
}

.block-detail input:disabled {
  color: var(--default-text);
}

.block-btn {
  padding: 0;
  margin: 0;
}

.block-btn .material-symbols-outlined {
  font-size: 18px;
  vertical-align: middle;
}

.transcribe-block .editing {
  background-color: var(--editing-text-background);
  border-radius: 3px;
  border: solid 1px var(--sub-primary) !important;
}

.audio-progress {
  display: flex;
  align-items: center;
}

.audio-progress input[type="range"] {
  flex: 1;
  height: 1px;
}

.result-button-group {
background-color: var(--background);
border-radius: 3px;
}

.details-dropdown {
font-size: 0.9rem;
}

.details-dropdown .material-symbols-outlined {
vertical-align: middle;
font-size: 1.3rem;
}

.details-dropdown .details-remove {
color: var(--removed);
}

</style>
