<template>
  <div class="modal fade" id="shareModal" tabindex="-1" aria-labelledby="shareModalLabel" aria-hidden="true" ref="shareModalRef">
    <div class="modal-dialog modal-dialog-centered">
      <div v-if="memberLoading" class="modal-content loading">
        <div class="modal-header border-0 fs-4 pt-2 pb-0"></div>
        <div class="modal-body pt-3 pb-1" ref="shareLoadingContainerRef"></div>
        <div class="modal-footer py-1 border-0"></div>
        <vue-element-loading
          background-color="rgba(255, 255, 255, .7)"
          :active="memberLoading"
          :fullscreen="false"
          spinner="spinner"
          color="#278bff"
          :container="shareLoadingContainerRef"
        />
      </div>
      <div v-else class="modal-content">
        <div class="modal-header border-0 fs-4 pt-2 pb-0">
          <h5 class="modal-title" id="shareModalLabel">
            <span class="header-title filename-title text-truncate">{{ meetingName }}</span>を共有
          </h5>
          <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body pt-3 pb-1">
          <div class="input-group mb-3 sharing-search">
            <input type="text" class="form-control sharing-member-input" aria-describedby="button-addon2"
              placeholder="ユーザーで追加" v-model="keyword" @focus="keywordFocus" @blur="keywordBlur" />
            <button class="btn sharing-member-finder" type="button" id="button-addon2">
              <span class="material-symbols-outlined"> search </span>
            </button>
          </div>
          <div v-show="isShowSearchResult" class="sharing-member-search-result shadow">
            <div class="result d-flex align-items-center" v-for="notSharedMember in searchedMembers" :key="notSharedMember.member_mst_id" @click="addMember(notSharedMember)">
              <div class="d-flex">
                <ProfileIcon :profileIconPath="notSharedMember.profile_icon_path" :iconName="notSharedMember.icon_name" :userName="notSharedMember.member_name" />
                <div class="user-info">
                  <div class="user-name">{{notSharedMember.member_name}}<span v-if="userInfoStore.memberMstId == notSharedMember.member_mst_id">（自分）</span></div>
                  <div class="user-email">{{notSharedMember.email}}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="file-shared-list">
            <div v-for="(member) in sharedMembers" :key="member.member_mst_id" class="shared-member mb-2 d-flex justify-content-between">
              <div class="d-flex">
                <ProfileIcon :profileIconPath="member.profile_icon_path" :iconName="member.icon_name" :userName="member.member_name" />
                <div class="user-info">
                  <div class="user-name">{{member.member_name}}<span v-if="userInfoStore.memberMstId == member.member_mst_id">（自分）</span></div>
                  <div class="user-email">{{member.email}}</div>
                </div>
              </div>
              <div class="shared-permission">
                <select v-model="member.file_permission_mst_id" class="form-select" aria-label="Default select example" :disabled="member.is_created_user">
                  <option v-for="(filePermissionMst) in filePermissionMsts" :key="filePermissionMst.file_permission_mst_id" :value="filePermissionMst.file_permission_mst_id">{{ filePermissionMst.file_permission_name }}</option>
                  <option value="">アクセス権を削除</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer py-1 border-0">
          <button type="button" class="btn" data-bs-dismiss="modal">
            キャンセル
          </button>
          <button type="button" class="btn btn-primary" @click="sharedFile">完了</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, inject } from 'vue';
import { Modal } from 'bootstrap';
import { useFileShareStore } from '@/stores/index';
import { useUserInfoStore } from '@/stores/index';
import axios from "@/axios";
import ProfileIcon from "../modules/ProfileIcon.vue";

export default {
  name: "CommonFileShare",
  components: {ProfileIcon},
  setup() {
    const shareModalRef = ref(null);
    const shareLoadingContainerRef = ref(null);
    let shareModal = null;
    const meetingName = ref('');
    const keyword = ref('');
    const sharedMembers = ref([]);
    const beforeSharedMembers = ref([]);
    const filePermissionMsts = ref([]);
    const members = ref([]);
    const searchedMembers = ref([]);
    const memberLoading = ref(true);
    const isShowSearchResult = ref(false);

    const fileShareStore = useFileShareStore();
    const userInfoStore = useUserInfoStore();

    // ローディング
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    onMounted(async () => {
      shareModal = new Modal(shareModalRef.value);

      if (shareModal) {
        shareModalRef.value.addEventListener('shown.bs.modal', handleModalShown);
        shareModalRef.value.addEventListener('hidden.bs.modal', handleModalHidden);
      }
    });

    const handleModalShown = async () => {
      // 初期化
      meetingName.value = '';
      keyword.value = '';
      sharedMembers.value = [];
      filePermissionMsts.value = [];
      members.value = [];
      searchedMembers.value = [];
      fileShareStore.setExecFileListUpdate(false);

      const params = {
        file_id: fileShareStore.fileId,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/file_share_init",
          params
        )
        .then((response) => {
          if (response.data.status) {
            meetingName.value = response.data.meeting_name;
            filePermissionMsts.value = response.data.file_permission_msts;
            sharedMembers.value = response.data.shared_members;
            beforeSharedMembers.value = JSON.parse(JSON.stringify(response.data.shared_members));
            members.value = response.data.not_shared_members;
            searchedMembers.value = response.data.not_shared_members;
          } else {
            alert("ファイル共有情報の取得に失敗しました");
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          memberLoading.value = false;
        });
    };

    const handleModalHidden = () => {
      memberLoading.value = true;
    };

    watch(keyword, (newCondition) => {
      isShowSearchResult.value = true;
      if (newCondition == '') {
        searchedMembers.value = members.value;
      } else {
        searchedMembers.value = members.value.filter(member => {
          return member.member_name.includes(newCondition) || member.email.includes(newCondition);
        });
      }
    });

    watch(searchedMembers, (newSearchedMembers) => {
      if (newSearchedMembers.length == 0) {
        isShowSearchResult.value = false;
      }
    });

    const keywordBlur = (event) => {
      setTimeout(() => {
        isShowSearchResult.value = false;
      }, 200);
    };

    const keywordFocus = (event) => {
      if (searchedMembers.value.length > 0) {
        isShowSearchResult.value = true;
      }
    };

    const addMember = (member) => {
      sharedMembers.value.push(member);
      members.value = members.value.filter((tmpMember) => tmpMember.member_mst_id !== member.member_mst_id);
      searchedMembers.value = searchedMembers.value.filter((searchedMember) => searchedMember.member_mst_id !== member.member_mst_id);
    };

    const sharedFile = async () => {
      showLoading();

      const addList = [];
      const updateList = [];
      const deleteList = [];

      sharedMembers.value.forEach((sharedMember) => {
        if (sharedMember.is_created_user) {
          // 作成者の権限は、編集不可
          return;
        }

        const tmpMember = beforeSharedMembers.value.find((beforeSharedMember) => beforeSharedMember.member_mst_id === sharedMember.member_mst_id);

        if (!tmpMember) {
          if (sharedMember.file_permission_mst_id !== null) {
            // 権限を指定してないメンバーは無視
            addList.push(sharedMember);
          }
        } else {
          if (sharedMember.file_permission_mst_id === '') {
            // 権限削除しているメンバー
            deleteList.push(sharedMember);
          } else if (sharedMember.file_permission_mst_id !== tmpMember.file_permission_mst_id) {
            // ファイル権限を変更しているメンバー
            updateList.push(sharedMember);
          }
        }
      });

      // 更新対象なし
      if (addList.length == 0 && updateList.length == 0 && deleteList.length == 0) {
        hideLoading();
        shareModal.hide();
        return;
      }

      const params = {
        file_id: fileShareStore.fileId,
        add_list: addList,
        update_list: updateList,
        delete_list: deleteList,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/shared_file",
          params
        )
        .then((response) => {
          if (response.data.status) {
            // ファイル一覧を更新
            fileShareStore.setExecFileListUpdate(true);
          } else {
            alert(response.data.error_msg);
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          hideLoading();
          shareModal.hide();
        });
    };

    return {
      shareModalRef,
      shareLoadingContainerRef,
      meetingName,
      keyword,
      sharedMembers,
      filePermissionMsts,
      members,
      searchedMembers,
      fileShareStore,
      userInfoStore,
      memberLoading,
      isShowSearchResult,
      keywordBlur,
      keywordFocus,
      addMember,
      sharedFile,
    };
  },
}
</script>

<style scoped>
.sharing-search {
  border-bottom: solid 1px var(--actived);
}
.sharing-member-input {
  border: none;
}
.sharing-member-input:focus {
  box-shadow: none;
}
.filename-title {
  max-width: 36ch;
}
.modal-title {
  display: inherit;
}

.sharing-member-search-result {
  width: calc(100% - 2rem);
  position: absolute;
  top: 57px;
  background-color: var(--fade-background);
  z-index: 1000;
  border-radius: 8px;
  max-height: 260px;
  overflow-y: auto;
}
.sharing-member-search-result .result {
  margin: 3px;
  padding: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.sharing-member-search-result .result:hover {
  background-color: var(--hoved);
}
.sharing-member-search-result .result-content {
  margin-left: 8px;
}
.sharing-member-search-result .result-content .result-title {
  width: 40ch;
  font-size: 0.9rem;
  transform: translateY(3px);
}
.sharing-member-search-result .result-content .result-info {
  color: var(--sub-text);
  font-size: 0.65rem;
  transform: translateY(-5px);
}
.sharing-member-search-result .result-icon .record-icon {
  width: 32px;
  height: 32px;
}
.sharing-member-search-result .result-icon .record-icon .material-symbols-outlined {
  font-size: 26px;
}
.modal-content.loading {
  height: 200px;
  padding: 10px;
}

.file-shared-list {
  max-height: 260px;
  overflow-y: auto;
}
</style>