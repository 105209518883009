<template>
  <div class="settings-content">
    <div class="modal fade" id="memberRegisterModal" tabindex="-1" aria-labelledby="memberRegisterModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header fs-4 py-2">
            <h5 class="modal-title">
              <span class="fw-bold">メンバーを追加</span>
              <div class="subtitle">登録後は追加したメンバーへパスワードをご連絡ください</div>
            </h5>
            <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-3 pb-1">
            <div class="input-section">
              <label for="inputEmail" class="form-label input-label mb-0">ユーザー名</label>
              <input type="text" class="form-control form-input mt-0" id="inputName" autocomplete="off"
                v-model="registUserName">
            </div>
            <span v-if="registUserNameError" class="editable-error col-12">
              {{
                registUserNameError
              }}
            </span>
            <div class="input-section">
              <label for="inputEmail" class="form-label input-label mb-0">メールアドレス</label>
              <input type="email" class="form-control form-input mt-0" id="inputEmail" aria-describedby="emailHelp"
                autocomplete="off" placeholder="name@company.com" v-model="registUserMailAddress">
            </div>
            <span v-if="registUserMailAddressError" class="editable-error col-12">
              {{
                registUserMailAddressError
              }}
            </span>
            <div class="input-section">
              <label for="inputEmail" class="form-label input-label mb-0">権限</label>
              <select class="form-select" aria-label="Default select example" v-model="registUserAuthorityId">
                <option v-for="memberAuthority in memberAuthorities" :value="memberAuthority.member_authority_mst_id"
                  :key="memberAuthority.member_authority_mst_id">
                  {{ memberAuthority.member_authority_name }}
                </option>
              </select>
            </div>
            <form @submit.prevent>
              <div class="input-section">
                <label for="inputPassword" class="form-label input-label mb-0">パスワード</label>
                <div class="input-group mt-0">
                  <input :type="inputPasswordType" class="password-input form-control form-input border-end-0"
                    id="inputPassword" autocomplete="off" v-model="registNewUserPassword">
                  <button class="btn form-input border-start-0 border" type="button">
                    <span class="material-symbols-outlined" @click="changePasswordVisible()">
                      {{ visibilityPasswordIcon }}
                    </span>
                  </button>
                </div>
              </div>
              <span v-if="registNewUserPasswordError" class="editable-error col-12">
                {{
                  registNewUserPasswordError
                }}
              </span>
              <div class="input-section">
                <label for="inputPasswordVerify" class="form-label input-label mb-0">パスワード（確認）</label>
                <div class="input-group mt-0">
                  <input :type="inputConfirmPasswordType" class="password-input form-control form-input border-end-0"
                    id="inputPasswordVerify" autocomplete="off" v-model="confirmNewUserPassword">
                  <button class="btn form-input border-start-0 border" type="button">
                    <span class="material-symbols-outlined" @click="changeConfirmPasswordVisible()">
                      {{ visibilityConfirmPasswordIcon }}
                    </span>
                  </button>
                </div>
              </div>
              <span v-if="confirmNewUserPasswordError" class="editable-error col-12">
                {{
                  confirmNewUserPasswordError
                }}
              </span>
            </form>
          </div>
          <div class="modal-footer py-3 border-0">
            <button type="button" class="btn" data-bs-dismiss="modal">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" @click="registMember($event)" data-bs-dismiss="modal"
              :disabled="isRegistFormDisabled()">登録</button>
            <div class="text-section lh-sm mt-2 text-right w-100">
              アカウントを追加できませんか？<a class="link" :href="contactUrl" target="_blank">お問い合わせする</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="authorityChangeModal" tabindex="-1" aria-labelledby="authorityChangeModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header fs-4 py-2">
            <h5 class="modal-title" v-if="selectTargetMemberName !== ''">
              <span id="memberName" class="fw-bold">{{ selectTargetMemberName }}</span>さんの権限を変更
            </h5>
            <h5 class="modal-title" v-else>
              <span id="memberName" class="fw-bold"></span>メンバーの権限を変更
            </h5>
            <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-3 pb-1">
            <select id="memberAuthoritySelect" class="form-select" aria-label="Default select example"
              v-model="updateUserAuthorityId">
              <option v-for="memberAuthority in memberAuthorities" :value="memberAuthority.member_authority_mst_id"
                :key="memberAuthority.member_authority_mst_id">
                {{ memberAuthority.member_authority_name }}
              </option>
            </select>
          </div>
          <div class="modal-footer py-1 border-0">
            <button type="button" class="btn" data-bs-dismiss="modal">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="updateMembersAuthority()">変更</button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-x-setting" role="tabpanel" aria-labelledby="nav-x-setting-tab">
          <div class="setting-item border-bottom-0">
            <div class="tab-pane" id="personal-payment-plan-setting" role="tabpanel"
              aria-labelledby="personal-payment-plan-setting-tab">
              <div class="page-title ps-0 mb-2">
                <h3>メンバー</h3>
              </div>
              <div class="setting-titles">
                <a class="settings-selector btn w-100 d-flex justify-content-between align-items-center"
                  data-bs-toggle="collapse" href="#settingsCollapse" role="button" aria-expanded="false"
                  aria-controls="settingsCollapse">
                  <h5 class="mb-0">設定: メンバー</h5>
                  <span class="material-symbols-outlined fs-1">
                    keyboard_arrow_down
                  </span>
                </a>
                <SettingCollapseList @componentChanged="changeComponent" currentComponent="MemberManagement" />
                <hr class="mt-2">
              </div>
              <p class="pt-2 pb-0 mb-1 fs-7">登録枠: {{ currentMembersCount }} / {{ planStore.maximumMembersCount }}</p>
              <div class="files-filter d-flex">
                <div class="filter d-flex" :class="{ 'd-none': !showMenu }">
                  <span id="resetOpt" class="reset-opt material-symbols-outlined" @click="clearAuthorityFilter()"
                    :class="{ 'd-none': isSelectAuthorityFilter }">close<span class="select-title">権限:</span></span>
                  <select id="optionsSelect" class="options-filter form-select bg-transparent border-0 outline-0"
                    aria-label="Default select example" v-model="filters.selected_authority" @change="changeMemberList()">
                    <option value="none" disabled selected hidden>権限</option>
                    <option v-for="memberAuthority in memberAuthorities"
                      :value="memberAuthority.member_authority_mst_id" :key="memberAuthority.member_authority_mst_id">
                      {{ memberAuthority.member_authority_name }}
                    </option>
                  </select>
                </div>
                <div class="filter d-flex" :class="{ 'd-none': !showMenu }">
                  <span id="resetUpdated" class="reset-opt material-symbols-outlined" @click="clearLastLoginAtFilter()"
                    :class="{ 'd-none': isSelectLastLoginAtFilter }">close<span
                      class="select-title">最終ログイン日:</span></span>
                  <select id="updatedSelect" class="options-filter form-select bg-transparent border-0 outline-0"
                    aria-label="Default select example" v-model="filters.selected_last_login_at"
                    @change="changeMemberList()">
                    <option value="0" disabled selected hidden>最終ログイン日</option>
                    <option value="1">今日</option>
                    <option value="2">過去7日間</option>
                    <option value="3">過去30日間</option>
                    <option value="4">今年（{{thisYear}}）</option>
                    <option value="5">昨年（{{previousYear}}）</option>
                  </select>
                </div>
                <div class="filter d-flex" :class="{ 'd-none': !showMenu }">
                  <span id="resetCreated" class="reset-opt material-symbols-outlined" @click="clearCreatedAtFilter()"
                    :class="{ 'd-none': isSelectCreatedAtFilter }">close<span class="select-title">作成日時:</span></span>
                  <select id="createdSelect" class="options-filter form-select bg-transparent border-0 outline-0"
                    aria-label="Default select example" v-model="filters.selected_created_at" @change="changeMemberList()">
                    <option value="0" disabled selected hidden>作成日時</option>
                    <option value="1">今日</option>
                    <option value="2">過去7日間</option>
                    <option value="3">過去30日間</option>
                    <option value="4">今年（{{thisYear}}）</option>
                    <option value="5">昨年（{{previousYear}}）</option>
                  </select>
                </div>
                <div class="filter d-flex" :class="{ 'd-none': !showMenu }">
                  <span id="resetCreated" class="reset-opt material-symbols-outlined" @click="clearUpdatedAtFilter()"
                    :class="{ 'd-none': isSelectUpdatedAtFilter }">close<span class="select-title">更新日時:</span></span>
                  <select id="createdSelect" class="options-filter form-select bg-transparent border-0 outline-0"
                    aria-label="Default select example" v-model="filters.selected_updated_at" @change="changeMemberList()">
                    <option value="0" disabled selected hidden>更新日時</option>
                    <option value="1">今日</option>
                    <option value="2">過去7日間</option>
                    <option value="3">過去30日間</option>
                    <option value="4">今年（{{thisYear}}）</option>
                    <option value="5">昨年（{{previousYear}}）</option>
                  </select>
                </div>
                <button type="button" class="btn btn-primary ms-auto add-member-btn text-truncate" data-bs-toggle="modal"
                  data-bs-target="#memberRegisterModal" :class="{ 'd-none': !showMenu }"
                  :disabled="isMembersCountExceed">メンバー追加</button>
                <div id="checkedCount" class="filter d-flex" :class="{ 'd-none': showMenu }">
                  <span id="resetCheck" role="button" class="reset-opt material-symbols-outlined"
                    @click="clearAll()">close</span>{{ checkedMembers.length }}<span class="ps-1"> 選択中</span>
                  <a type="button" class="btn checking-btn pe-0" data-bs-toggle="modal"
                    data-bs-target="#authorityChangeModal" @click="selectMultipleTargetMembers()">
                    <span class="material-symbols-outlined">manage_accounts</span>権限変更
                  </a>
                  <a type="button" class="btn checking-btn pe-3 text-danger"
                    @click="selectMultipleDeleteTargetMembers()">
                    <span class="material-symbols-outlined">person_remove</span>アカウント削除
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table id="memberDataTable" class="table data-table">
                  <thead>
                    <tr>
                      <th class="table-check">
                        <input id="allCheck" type="checkbox" v-model="allChecked" />
                      </th>
                      <th class="table-membername" @click="changeSort('member_name')">
                        <div class="d-flex justify-content-between align-items-center">
                          ユーザー名<span>{{ sortIcon('member_name') }}</span>
                        </div>
                      </th>
                      <th class="table-authority text-truncate" @click="changeSort('member_authority_mst_id')">
                        <div class="d-flex justify-content-between align-items-center">
                          権限<span>{{ sortIcon('member_authority_mst_id') }}</span>
                        </div>
                      </th>
                      <th class="table-created-at text-truncate" @click="changeSort('last_login_at')">
                        <div class="d-flex justify-content-between align-items-center">
                          最終ログイン日時<span>{{ sortIcon('last_login_at') }}</span>
                       </div>
                      </th>
                      <th class="table-created-at" @click="changeSort('created_at')">
                        <div class="d-flex justify-content-between align-items-center">
                          作成日時<span>{{ sortIcon('created_at') }}</span>
                        </div>
                      </th>
                      <th class="table-created-at" @click="changeSort('updated_at')">
                        <div class="d-flex justify-content-between align-items-center">
                          更新日時<span>{{ sortIcon('updated_at') }}</span>
                        </div>
                      </th>
                      <th class="table-action"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isItems">
                    <tr v-for="(member, index) in currentCompanyMembers" :key="member.member_mst_id">
                      <td class="member-info member-check">
                        <input :disabled="isMyColumn(member.firebase_authentication_uid)" type="checkbox"
                          class="item-checkbox" :id="member.member_mst_id" :value="member.member_mst_id"
                          v-model="checkedMembers">
                      </td>
                      <td class="member-info member-name ps-0">
                        <div class="d-flex user-select-option md">
                          <ProfileIcon :profileIconPath="member.profile_icon_path" :iconName="member.icon_name"
                            :userName="member.member_name" />
                          <div class="user-info">
                            <div class="user-name">{{ member.member_name }}</div>
                            <div class="user-email">{{ member.mail_address }}</div>
                          </div>
                        </div>
                      </td>
                      <td class="member-info member-authority text-truncate">
                        {{ member.member_authority_name }}
                      </td>
                      <td class="member-info member-created-at">{{ dateFormat(member.login_timestamp) }}
                      </td>
                      <td class="member-info member-created-at">{{ dateFormat(member.created_at) }}
                      </td>
                      <td class="member-info member-updated-at">{{ dateFormat(member.updated_at) }}
                      </td>
                      <td class="member-info member-actions">
                        <div class="btn-group dropstart">
                          <button type="button" class="btn" data-bs-toggle="dropdown" aria-expanded="false"
                            :disabled="isMyColumn(member.firebase_authentication_uid)">
                            <span class="material-symbols-outlined">more_vert</span>
                          </button>
                          <ul class="dropdown-menu">
                            <li><a class="dropdown-item my-1" type="button" data-bs-toggle="modal"
                                data-bs-target="#authorityChangeModal"
                                @click="selectTargetMember(member.member_mst_id)"><span
                                  class="material-symbols-outlined">manage_accounts</span>権限変更</a></li>
                            <li><a class="dropdown-item my-1 text-danger" type="button"
                                @click="selectDeleteTargetMember(member.member_mst_id)"><span
                                  class="material-symbols-outlined">delete</span>アカウントを削除する</a>
                            </li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="7" class="dt-empty">
                        <span class="no-data-text">データがありません</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div id="" class="bottom d-flex justify-content-between align-items-center">
                  <div class="dt-info" aria-live="polite" id="allDataTable_info" role="status"> {{
                    pagination.total_count }} 件中 {{ pagination.start_index }} から {{ pagination.end_index }} まで表示</div>
                  <div class="dt-length">
                    <select name="allDataTable_length" aria-controls="allDataTable" class="dt-input" id="dt-length-0"
                      v-model.number="pages.per_page" @change="changePerPage()">
                      <option v-for="perPage in perPages" :key="perPage" :value="perPage">{{ perPage }}</option>
                    </select>
                    <label for="dt-length-0"> 件表示</label>
                  </div>
                  <div class="dt-paging">
                    <nav aria-label="pagination">
                      <button class="dt-paging-button first" :disabled="!pagination.has_prev" @click="goToPage(1)"
                        role="link" type="button" aria-controls="allDataTable" aria-disabled="true" aria-label="First"
                        data-dt-idx="first" tabindex="-1">«</button>
                      <button class="dt-paging-button previous" :disabled="!pagination.has_prev"
                        @click="goToPage(pagination.prev_num)" role="link" type="button" aria-controls="allDataTable"
                        aria-disabled="true" aria-label="Previous" data-dt-idx="previous" tabindex="-1">‹</button>
                      <button v-for="page_num in pagination.page_numbers" :key="page_num" class="dt-paging-button"
                        :class="{ current: page_num === pagination.page }" :disabled="page_num === '...'"
                        @click="goToPage(page_num)" role="link" type="button" aria-controls="allDataTable"
                        aria-current="page" data-dt-idx="0">{{ page_num }}</button>
                      <button class="dt-paging-button next" :disabled="!pagination.has_next"
                        @click="goToPage(pagination.next_num)" role="link" type="button" aria-controls="allDataTable"
                        aria-label="Next" data-dt-idx="next">›</button>
                      <button class="dt-paging-button last" :disabled="!pagination.has_next"
                        @click="goToPage(pagination.total_pages)" role="link" type="button" aria-controls="allDataTable"
                        aria-label="Last" data-dt-idx="last">»</button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth"
import { useField, useForm } from 'vee-validate';
import * as yup from 'yup';
// modules
import SettingCollapseList from "@/components/modules/SettingCollapseList.vue";
import ProfileIcon from "../modules/ProfileIcon.vue";

// mixin
import authentication from "../mixin/authentication.js";
import Authority from "@/components/mixin/authority.js";

import { inject } from 'vue';
import axios from "@/axios";
import { usePlanStore } from '@/stores/index';

export default {
  mixins: [authentication, Authority],
  name: "MemberManagement",
  emits: ['componentChanged'],
  components: {
    ProfileIcon,
    SettingCollapseList,
  },
  data() {
    return {
      memberAuthorities: [],
      checkedMembers: [],
      selectMembersId: [],
      currentCompanyMembers: [],
      isPasswordVisible: false,
      isConfirmPasswordVisible: false,
      registUserAuthorityId: 0,
      updateUserAuthorityId: 0,
      currentMembersCount: 0,
      selectTargetMemberName: '',
      allChecked: false,
      currentUser: firebase.auth().currentUser,
      now: new Date(),
      filters: {
        selected_authority: 'none',
        selected_last_login_at: '0',
        selected_created_at: '0',
        selected_updated_at: '0',
      },
      orders: {
        sort_by: 'created_at',
        sort_order: 'desc',
      },
      perPages: [10, 25, 50, 100],
      pages: {
        page: 1,
        per_page: 10,
      },
      pagination: {
        has_prev: false,
        has_next: false,
        prev_num: 0,
        next_num: 0,
        page: 0,
        total_pages: 0,
        page_numbers: 0,
        total_count: 0,
        start_index: 0,
        end_index: 0,
      },
      contactUrl: process.env.VUE_APP_CONTACT_URL,
    };
  },
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    // パスワード用カスタムバリデーションルール
    yup.addMethod(yup.string, "password", function () {
      return this.test('password', 'パスワードは、英大文字・英小文字・数字・記号のうち3種以上を含めてください。', function (value) {
        if (!value) return true;
        const ret = authentication.methods.validatePassword(value);
        return ret.status;
      });
    });

    const schema = yup.object({
      registUserName: yup.string().max(50, 'ユーザー名は50文字以内にしてください。').required('ユーザー名は必須項目です。'),
      registUserMailAddress: yup.string().email('メールアドレスは有効なメールアドレスではありません。').max(50, 'メールアドレスは50文字以内にしてください。').required('メールアドレスは必須項目です。'),
      registNewUserPassword: yup.string().password().required('パスワードは必須項目です。'),
      confirmNewUserPassword: yup.string().oneOf([yup.ref('registNewUserPassword'), null], 'パスワードが一致しません。').required('パスワード（確認）は必須項目です。'),
    });

    const { errors: registFormErrors, resetForm } = useForm({
      validationSchema: schema,
      initialValues: {
        registUserName: '',
        registUserMailAddress: '',
        registNewUserPassword: '',
        confirmNewUserPassword: '',
      }
    });

    const { value: registUserName, errorMessage: registUserNameError } = useField('registUserName');
    const { value: registUserMailAddress, errorMessage: registUserMailAddressError } = useField('registUserMailAddress');
    const { value: registNewUserPassword, errorMessage: registNewUserPasswordError } = useField('registNewUserPassword');
    const { value: confirmNewUserPassword, errorMessage: confirmNewUserPasswordError } = useField('confirmNewUserPassword');

    const planStore = usePlanStore();

    return {
      registUserName,
      registUserNameError,
      registUserMailAddress,
      registUserMailAddressError,
      registNewUserPassword,
      registNewUserPasswordError,
      confirmNewUserPassword,
      confirmNewUserPasswordError,
      registFormErrors,
      resetForm,
      showLoading,
      hideLoading,
      planStore,
    }
  },
  async created() {
    this.showLoading();
    // ユーザ操作権限の有無チェック
    const userOperationAuthority = await this.getUserOperationAuthority();
    const hasUserOperationAuthority = Boolean(userOperationAuthority);
    // 企業プランかチェック
    const currentCompanyPlan = await this.getCompanyPlan();
    const isCompamyPlan = currentCompanyPlan != 999;
    if ((userOperationAuthority && !hasUserOperationAuthority) || (currentCompanyPlan && !isCompamyPlan)) {
      alert("権限がありません。");
      this.$router.push("/");
    } else {
      await this.getMemberManagementList();
      this.hideLoading();
    }
  },
  watch: {
    allChecked(newValue) {
      if (newValue) {
        this.checkedMembers = this.currentCompanyMembers.filter(member => member.firebase_authentication_uid != this.currentUser.uid).map(member => member.member_mst_id);
      } else {
        this.checkedMembers = [];
      }
    },
  },
  computed: {
    isSelectAuthorityFilter() {
      return this.filters.selected_authority == 'none' ? true : false;
    },
    isSelectLastLoginAtFilter() {
      return this.filters.selected_last_login_at == '0' ? true : false;
    },
    isSelectCreatedAtFilter() {
      return this.filters.selected_created_at == '0' ? true : false;
    },
    isSelectUpdatedAtFilter() {
      return this.filters.selected_updated_at == '0' ? true : false;
    },
    inputPasswordType() {
      return this.isPasswordVisible ? "text" : "password";
    },
    inputConfirmPasswordType() {
      return this.isConfirmPasswordVisible ? "text" : "password";
    },
    visibilityPasswordIcon() {
      return this.isPasswordVisible ? "visibility_off" : "visibility";
    },
    visibilityConfirmPasswordIcon() {
      return this.isConfirmPasswordVisible ? "visibility_off" : "visibility";
    },
    isMembersCountExceed() {
      const result = parseInt(this.planStore.maximumMembersCount) <= parseInt(this.currentMembersCount);
      return result;
    },
    showMenu() {
      return !this.checkedMembers.length > 0;
    },
    isItems() {
      return this.currentCompanyMembers.length > 0;
    },
    // 今年
    thisYear() {
      return this.now.getFullYear();
    },
    // 昨年
    previousYear() {
      return this.now.getFullYear() - 1;
    },
  },
  methods: {
    changeComponent(componentName) {
      this.$emit('componentChanged', componentName);
    },
    isMyColumn(firebaseAuthUid) {
      return firebaseAuthUid == this.currentUser.uid;
    },
    changePasswordVisible() {
      this.isPasswordVisible = !this.isPasswordVisible;
    },
    changeConfirmPasswordVisible() {
      this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
    },
    dateFormat(dateValue) {
      if (dateValue) {
        let date = (new Date(dateValue))
        let y = date.getUTCFullYear();
        let m = ("00" + (date.getUTCMonth() + 1)).slice(-2);
        let d = ("00" + date.getUTCDate()).slice(-2);
        let h = ("00" + date.getUTCHours()).slice(-2);
        let min = ("00" + date.getUTCMinutes()).slice(-2);
        let sec = ("00" + date.getUTCSeconds()).slice(-2);
        return y + "/" + m + "/" + d + " " + h + ":" + min + ":" + sec;
      }else {
        return '';
      }
    },
    clearAll() {
      this.allChecked = false;
      this.checkedMembers = [];
    },
    changePerPage() {
      this.pages.page = 1;
      this.changeMemberList();
    },
    goToPage(toPage) {
      this.pages.page = toPage;
      this.changeMemberList();
    },
    clearAuthorityFilter() {
      this.filters.selected_authority = 'none';
      this.changeMemberList();
    },
    clearLastLoginAtFilter() {
      this.filters.selected_last_login_at = '0';
      this.changeMemberList();
    },
    clearCreatedAtFilter() {
      this.filters.selected_created_at = '0';
      this.changeMemberList();
    },
    clearUpdatedAtFilter() {
      this.filters.selected_updated_at = '0';
      this.changeMemberList();
    },
    isRegistFormDisabled() {
      if (this.registUserName && this.registUserMailAddress && this.registNewUserPassword && this.confirmNewUserPassword) {
        return Object.keys(this.registFormErrors).length;
      } else {
        return true;
      }
    },
    // メンバー情報取得
    async getMemberManagementList() {
      const params = {
        filters: this.filters,
        orders: this.orders,
        pages: this.pages,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
          "/texta-basic-api/get_member_management_list",
          params,
        )
        .then((response) => {
          if (response.data.status) {
            this.currentCompanyMembers = response.data.members;
            this.pagination = response.data.pagination;
            this.currentMembersCount = response.data.current_members_count;
            this.memberAuthorities = response.data.authorities;
          }
        })
        .catch((error) => {
        });
    },
    // プラン情報取得
    async getCompanyPlan() {
      const response = await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_company_plan",
        );
      if (response.data) {
        return response.data.plan;
      } else {
        return null;
      }
    },
    selectTargetMember(targetMemberMstId) {
      // 三点リーダーから選択の場合
      this.selectMembersId = [targetMemberMstId];
      this.selectTargetMemberName = this.currentCompanyMembers.find(
          (member) => member.member_mst_id === targetMemberMstId).member_name;
      this.updateUserAuthorityId = this.currentCompanyMembers.find(
          (member) => member.member_mst_id === targetMemberMstId).member_authority_mst_id
      // チェックボックス選択解除
      this.clearAll();
    },
    selectDeleteTargetMember(targetMemberMstId) {
      // 三点リーダーから選択の場合
      this.selectMembersId = [targetMemberMstId];
      // チェックボックス選択解除
      this.clearAll();
      this.confirmDeleteAccount();
    },
    selectMultipleTargetMembers() {
      // チェックボックスから選択の場合
      this.selectMembersId = this.checkedMembers;
      if(this.checkedMembers.length == 1) {
        // 個別選択
        this.selectTargetMemberName = this.currentCompanyMembers.find(
          (member) => member.member_mst_id === this.selectMembersId[0]).member_name;
        this.updateUserAuthorityId = this.currentCompanyMembers.find(
          (member) => member.member_mst_id === this.selectMembersId[0]).member_authority_mst_id
      }else {
        // 複数選択
        this.selectTargetMemberName = '';
        this.updateUserAuthorityId = 0;
      }
    },
    selectMultipleDeleteTargetMembers() {
      // チェックボックスから選択の場合
      this.selectMembersId = this.checkedMembers;
      this.selectTargetMemberName = '';
      this.confirmDeleteAccount();
    },
    sortIcon(column) {
      if (column === this.orders.sort_by) {
        if ('asc' === this.orders.sort_order) {
          return '▲';
        } else {
          return '▼'
        }
      }
      return '';
    },
    // 状態変更時
    async changeMemberList() {
      this.showLoading();
      await this.getMemberManagementList();
      this.clearAll();
      this.hideLoading();
    },
    // カラムソート順変更時
    async changeSort(sort_by) {
      this.orders.sort_by = sort_by;
      if (this.orders.sort_by === sort_by) {
        this.orders.sort_order = this.orders.sort_order === 'asc' ? 'desc' : 'asc';
      } else {
        this.orders.sort_order = 'asc';
      }
      this.showLoading();
      await this.getMemberManagementList();
      this.hideLoading();
    },
    // メンバー追加処理
    async registMember(event) {
      event.preventDefault();
      this.showLoading();
      const params = {
        email: this.registUserMailAddress,
        displayName: this.registUserName,
        password: this.registNewUserPassword,
        authority: this.registUserAuthorityId
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/regist_member",
          params,
        )
        .then(async (response) => {
          if (response.data.status) {
            alert("メンバーを登録しました。");
            await this.changeMemberList();
            this.clearMemberForm();
          } else if (response.data.error_code == 'USER_NOT_HAVE_AUTHORITY') {
            alert("権限がないため、メンバー登録できません。");
          } else if (response.data.error_code == 'MEMBERS_LIMIT_EXCEEDED') {
            alert("メンバー登録枠の上限に達したため、メンバー登録できません。");
          } else if (response.data.error_code == 'ALREADY_EXISTS') {
            alert("このメールアドレスは既に別のアカウントで使用されています。");
          } else {
            alert("メンバー登録に失敗しました。");
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    clearMemberForm() {
      this.resetForm({
        values: {
          registUserName: '',
          registUserMailAddress: '',
          registNewUserPassword: '',
          confirmNewUserPassword: '',
        }
      });
      this.registUserAuthorityId = 0;
    },
    confirmDeleteAccount() {
      if (confirm('アカウントを削除しますか？')) {
        this.deleteMember();
      }
    },
    // メンバー削除処理
    async deleteMember() {
      this.showLoading();
      const params = {
        target_members_id: this.selectMembersId,
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/delete_member",
          params,
        )
        .then(async (response) => {
          if (response.data.status) {
            alert("メンバーのアカウントを削除しました。");
            await this.changeMemberList();
          } else if (response.data.error_code == 'TARGET_INCLUDES_LOGIN_USER') {
            alert("現在ログインしているユーザー自身はアカウント削除できません。");
          } else if (response.data.error_code == 'USER_NOT_HAVE_AUTHORITY') {
            alert("権限がないため、メンバーのアカウント削除できません。");
          } else {
            alert("メンバーのアカウント削除に失敗しました。");
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    // 権限変更処理
    async updateMembersAuthority() {
      this.showLoading();
      const params = {
        target_member_authority_mst_id: this.updateUserAuthorityId,
        target_members_id: this.selectMembersId,
      };
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_members_authority",
          params,
        )
        .then(async (response) => {
          if (response.data.status) {
            alert("メンバーの権限を更新しました。");
            await this.changeMemberList(); 
          } else if (response.data.error_code == 'TARGET_INCLUDES_LOGIN_USER') {
            alert("現在ログインしているユーザー自身は権限更新できません。");
          } else if (response.data.error_code == 'USER_NOT_HAVE_AUTHORITY') {
            alert("権限がないため、メンバーの権限更新できません。");
          } else {
            alert("メンバーの権限更新に失敗しました。");
          }
        })
        .catch((error) => {
          alert("メンバーの権限更新に失敗しました。");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style scoped>
.setting-item .setting-name {
  font-size: 16px;
}

.setting-item .text-secondary {
  font-size: 0.8rem;
}

.setting-item {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--actived);
}

.select-title {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  vertical-align: 5px;
}

.data-table tr:first-child th {
  background-color: transparent;
}

.data-table thead {
  background-color: var(--disabled);
}

tbody,
td,
tfoot,
th,
thead,
td {
  border-color: var(--actived);
}

.table-action {
  width: 2%;
}

.table-check {
  width: 2%;
}

.table-membername {
  width: 25%;
}

.table-updated-at,
.table-updated-by,
.table-created-by,
.last-login-at,
.table-created-at {
  width: 15%;
  max-width: 175px;
}

.data-table thead tr th:hover {
  outline: 2px solid var(--sub-border) !important;
  outline-offset: -2px;
}

.member-info.member-check,
.member-info.member-authority {
  line-height: 2.5rem;
}

.member-info.member-authority,
.member-info.member-files-count,
.member-info.member-updated-at,
.member-info.member-created-at {
  color: var(--sub-text);
  font-size: 0.7rem;
}

.member-info.member-actions .dropdown-menu {
  font-size: 14px;
  color: var(--secondary);
}

.member-info.member-actions .dropdown-menu .material-symbols-outlined {
  font-size: 21px;
  vertical-align: -4px;
}

.reset-opt {
  font-size: 22px;
  margin-left: 10px;
}

.files-filter {
  padding: 5px 10px 10px 0px;
}

.files-filter .filter {
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 19px;
  background-color: var(--fade-background);
  align-items: center;
  margin-right: 10px;
}

.options-filter {
  margin-left: 5px;
}

.options-filter:focus {
  box-shadow: none;
}

.editable-error {
  margin: 5px 0 0;
  color: #cc0000;
}
.member-actions .btn-group .btn:active {
  border: none;
}

.member-actions .btn-group .btn.show .material-symbols-outlined {
  color: var(--primary);
  font-variation-settings:
  'FILL' 0,
  'wght' 700,
  'GRAD' 200,
  'opsz' 24
}
</style>
