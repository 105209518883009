<template>
  <div class="data-tabs">
    <div v-if="isDisplayTab" class="nav nav-tabs">
      <a
        v-if="dateFilterValue"
        type="button"
        class="btn text-default fw-bold"
        @click="clearDateFilter"
      >
        &#x2715; (<span>{{ dateFilterValue }}</span
        >)：
      </a>
      <div
        class="nav-link"
        :class="{ active: fileTarget == 'all' }"
        @click="changeTabAllFiles('all')"
      >
        すべて
      </div>
      <div
        class="nav-link"
        :class="{ active: fileTarget == 'my_files' }"
        @click="changeTabMyFiles('myfiles')"
      >
        マイファイル
      </div>
      <div
        class="nav-link"
        :class="{ active: fileTarget == 'share' }"
        @click="changeTabSharedFiles('sharedfiles')"
      >
        共有ファイル
      </div>
    </div>
    <div class="tab-content" id="nav-tabContent">
      <div
        class="tab-pane fade show active"
        id="nav-all-files"
        role="tabpanel"
        aria-labelledby="nav-all-files-tab"
      >
        <div class="table-responsive">
          <table id="allDataTable" class="table data-table">
            <thead>
              <tr>
                <th v-if="isDisplayCheckBox" class="table-check">
                  <input id="allCheck" type="checkbox" v-model="allChecked" />
                </th>
                <th class="table-title" @click="sort('title')">
                  <div class="d-flex justify-content-between align-items-center">タイトル<span>{{sortIcon('title')}}</span></div>
                </th>
                <th class="table-status" @click="sort('status')">
                  <div class="d-flex justify-content-between align-items-center">状態<span>{{sortIcon('status')}}</span></div>
                </th>
                <th class="table-options" @click="sort('options')">
                  <div class="d-flex justify-content-between align-items-center">変換<span>{{sortIcon('options')}}</span></div>
                </th>
                <th class="table-duration" @click="sort('duration')">
                  <div class="d-flex justify-content-end align-items-center">長さ<span>{{sortIcon('duration')}}</span></div>
                </th>
                <th class="table-created-by" @click="sort('created_by')">
                  <div class="d-flex justify-content-between align-items-center">作成者<span>{{sortIcon('created_by')}}</span></div>
                </th>
                <th class="table-created-at" @click="sort('created_at')">
                  <div class="d-flex justify-content-end align-items-center">作成日時<span>{{sortIcon('created_at')}}</span></div>
                </th>
                <th class="table-action"></th>
              </tr>
            </thead>
            <tbody v-if="isItems">
              <tr v-for="item in items" :key="item.file_id" @click="navigateToDetail(item, $event)">
                <td v-if="isDisplayCheckBox" class="record-info record-check">
                  <input type="checkbox" class="item-checkbox" :value="item.file_id" v-model="checkedItemsProxy" :disabled="isDisabledRowCheck(item)" />
                </td>
                <td class="record-info record-title">
                  <div class="d-flex align-items-center">
                    <div v-if="item.file_type === 0" class="record-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="ファイル">
                      <span class="material-symbols-outlined icon-fill">draft</span>
                    </div>
                    <div v-if="item.file_type === 1" class="record-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="収録したファイル">
                      <span class="material-symbols-outlined icon-fill">mic</span>
                    </div>
                    <div v-if="item.file_type === 2" class="record-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="インポートしたファイル">
                      <span class="material-symbols-outlined icon-fill">upload_file</span>
                    </div>
                    <div>
                    <span class="meeting-title text-truncate" data-bs-toggle="tooltip" data-bs-placement="bottom" :title="item.meeting_name">
                      {{item.meeting_name}}
                    </span>
                    <div class="record-tags">
                      <span v-if="item.is_share" class="material-symbols-outlined icon-fill status-icon shared">
                        communities
                      </span>
                      <span v-else-if="isTranscriptionCompleted(item.file_type, item.file_transcription_status)" class="material-symbols-outlined icon-fill status-icon completed">
                        check_circle
                      </span>
                      <span v-else-if="isTranscriptionProcessing(item.file_type, item.file_transcription_status)" class="material-symbols-outlined icon-fill status-icon pending">
                        schedule
                      </span>
                      <span v-else-if="isTranscriptionError(item.file_type, item.file_transcription_status)" class="material-symbols-outlined icon-fill status-icon failed">
                        error
                      </span>
                      <div v-if="item.is_meeting_minutes" class="options-icon">
                        <span class="minutes icon"></span>
                      </div>
                      <div v-if="item.is_translation" class="options-icon">
                        <span class="material-symbols-outlined icon-fill">
                          g_translate
                        </span>
                      </div>
                    </div>
                    </div>
                  </div>
                </td>
                <td class="record-info record-status">
                  <span v-if="item.is_share" class="material-symbols-outlined icon-fill status-icon shared" data-bs-toggle="tooltip" data-bs-placement="bottom" title="共有済み">
                    communities
                  </span>
                  <span v-else-if="isTranscriptionCompleted(item.file_type, item.file_transcription_status)" class="material-symbols-outlined icon-fill status-icon completed" data-bs-toggle="tooltip" data-bs-placement="bottom" title="文字起こし済み">
                    check_circle
                  </span>
                  <span v-else-if="isTranscriptionProcessing(item.file_type, item.file_transcription_status)" class="material-symbols-outlined icon-fill status-icon pending" data-bs-toggle="tooltip" data-bs-placement="bottom" title="処理中">
                    schedule
                  </span>
                  <span v-else-if="isTranscriptionError(item.file_type, item.file_transcription_status)" class="material-symbols-outlined icon-fill status-icon failed" data-bs-toggle="tooltip" data-bs-placement="bottom" title="エラー発生">
                    error
                  </span>
                </td>
                <td class="record-info record-options">
                  <div class="d-flex">
                    <div v-if="item.is_meeting_minutes" class="options-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="議事録作成済み">
                      <span class="minutes icon"></span>
                    </div>
                    <div v-if="item.is_translation" class="options-icon me-1">
                      <span class="material-symbols-outlined icon-fill" data-bs-toggle="tooltip" data-bs-placement="bottom" title="翻訳済み">
                        g_translate
                      </span>
                    </div>
                  </div>
                </td>
                <td class="record-info record-duration">
                  {{item.duration}}
                </td>
                <td class="record-info record-created-by">
                  {{item.created_member_name}}
                </td>
                <td class="record-info record-created-at">
                  {{item.created_at}}
                </td>
                <td class="record-info record-actions">
                  <div v-if="isDisplayRowMenu" class="btn-group dropstart">
                    <button type="button" class="btn" data-bs-toggle="dropdown" aria-expanded="false" :disabled="isDisabledRowMenu(item)">
                      <span class="material-symbols-outlined">more_vert</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li v-if="item.write_permission">
                        <a class="dropdown-item my-1" @click="renameModalShow(item.file_id, item.meeting_name, item.updated_at)" type="button">
                          <span class="rename icon pe-1 mb-1"></span>タイトルを変更する
                        </a>
                      </li>
                      <li v-if="item.share_permission && isTranscriptionCompleted(item.file_type, item.file_transcription_status)">
                        <a class="dropdown-item my-1" @click="showFileShareModal(item.file_id)" data-bs-toggle="modal" data-bs-target="#shareModal" type="button">
                          <span class="material-symbols-outlined">share</span>ファイルを共有する
                        </a>
                      </li>
                      <li v-if="isTranscriptionError(item.file_type, item.file_transcription_status)">
                        <a class="dropdown-item my-1" @click="retryTranscript(item.file_id)" type="button">
                          <span class="material-symbols-outlined me-1">cloud_sync</span>文字起こしをやり直す
                        </a>
                      </li>
                      <li v-if="item.delete_permission">
                        <a class="dropdown-item my-1 text-danger" @click="deleteFile(item.file_id, item.meeting_name)" type="button">
                          <span class="material-symbols-outlined">delete</span>削除する
                        </a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="!isLoading">
              <tr>
                <td colspan="7" class="dt-empty">
                  <img class="no-data-image" src="../../assets/img/no-data.svg" alt="データがありません">
                  <br>
                  <span class="no-data-text">データがありません</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div id="" class="bottom d-flex justify-content-between align-items-center">
            <div class="dt-info" aria-live="polite" id="allDataTable_info" role="status"> {{ pagination.total_count }} 件中 {{ pagination.start_index }} から {{ pagination.end_index }} まで表示</div>
            <div class="dt-length">
              <select name="allDataTable_length" aria-controls="allDataTable" class="dt-input" id="dt-length-0" v-model.number="perPage" @change="changePerPage">
                <option v-for="perPage in perPages" :key="perPage" :value="perPage">{{perPage}}</option>
              </select>
              <label for="dt-length-0"> 件表示</label>
            </div>
            <div class="dt-paging">
              <nav aria-label="pagination">
                <button class="dt-paging-button first" :disabled="!pagination.has_prev" @click="goToPage(1)" role="link" type="button" aria-controls="allDataTable" aria-disabled="true" aria-label="First" data-dt-idx="first" tabindex="-1">«</button>
                <button class="dt-paging-button previous" :disabled="!pagination.has_prev" @click="goToPage(pagination.prev_num)" role="link" type="button" aria-controls="allDataTable" aria-disabled="true" aria-label="Previous" data-dt-idx="previous" tabindex="-1">‹</button>
                <button v-for="page_num in pagination.page_numbers" :key="page_num" class="dt-paging-button" :class="{current: page_num === pagination.page}" :disabled="page_num === '...'" @click="goToPage(page_num)" role="link" type="button" aria-controls="allDataTable" aria-current="page" data-dt-idx="0">{{ page_num }}</button>
                <button class="dt-paging-button next" :disabled="!pagination.has_next" @click="goToPage(pagination.next_num)" role="link" type="button" aria-controls="allDataTable" aria-label="Next" data-dt-idx="next">›</button>
                <button class="dt-paging-button last" :disabled="!pagination.has_next" @click="goToPage(pagination.total_pages)" role="link" type="button" aria-controls="allDataTable" aria-label="Last" data-dt-idx="last">»</button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal-lg fade" ref="renameModal" tabindex="-1" aria-labelledby="renameModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header fs-4 py-2">
            <h5 class="modal-title" id="renameModalLabel">
              ファイルのタイトルを変更
            </h5>
            <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-3 pb-1">
            <div class="file-rename">
              <input type="text" class="form-control filename" v-model="inputMeetingName" />
              <span v-if="errors.inputMeetingName" class="text-danger">{{ errors.inputMeetingName }}</span>
            </div>
          </div>
          <div class="modal-footer py-1 border-0">
            <button type="button" class="btn" data-bs-dismiss="modal">
              キャンセル
            </button>
            <button type="button" class="btn btn-primary" :disabled="errors.inputMeetingName" @click="updateMeetingName">変更</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, computed, ref, watch } from 'vue';
import { Modal } from 'bootstrap';
import * as yup from 'yup';
import { useForm, useField } from 'vee-validate';
import axios from "@/axios";
import { useFileStatus } from "../composables/fileStatus";
import { useFileShare } from "../composables/fileShare";
import { useFileSearchStore } from '../../stores';

export default {
  name: "CommonFileList",
  components: {},
  data() {
    return {
      checkedItems: [],
      perPages: [10, 25, 50, 100],
      perPage: 10,
      renameModal: null,
      fileId: "",
      inputMeetingName: "",
      updatedAt: "",
    };
  },
  setup() {
    const loadingState = inject('loading');

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    const isLoading = computed(() => loadingState.loading.value);

    const {
      isTranscriptionCompleted,
      isTranscriptionProcessing,
      isTranscriptionError,
    } = useFileStatus();

    const {showFileShareModal} = useFileShare();

    const schema = yup.object({
      inputMeetingName: yup.string().required('タイトルを設定してください。').max(50, '50文字以内で設定してください。'),
    });

    const { validate, errors } = useForm({
      validationSchema: schema
    });

    const isDisabledRowCheck = (item) => {
      // ファイル共有
      if (item.share_permission && isTranscriptionCompleted(item.file_type, item.file_transcription_status)) {
        return false;
      }

      // 文字起こしをやり直す
      if (isTranscriptionError(item.file_type, item.file_transcription_status)) {
        return false;
      }

      // ファイル削除
      if (item.delete_permission) {
        return false;
      }

      return true;
    };

    const isDisabledRowMenu = (item) => {
      // タイトル変更
      if (item.write_permission) {
        return false;
      }

      // ファイル共有
      if (item.share_permission && isTranscriptionCompleted(item.file_type, item.file_transcription_status)) {
        return false;
      }

      // 文字起こしをやり直す
      if (isTranscriptionError(item.file_type, item.file_transcription_status)) {
        return false;
      }

      // ファイル削除
      if (item.delete_permission) {
        return false;
      }

      return true;
    };

    const allChecked = ref(false);
    const fileSearchStore = useFileSearchStore();

    watch(() => fileSearchStore.clearAllChecked, () => {
      allChecked.value = false;
    });

    return {
      validate,
      errors,
      isLoading,
      showLoading,
      hideLoading,
      isTranscriptionCompleted,
      isTranscriptionProcessing,
      isTranscriptionError,
      isDisabledRowCheck,
      isDisabledRowMenu,
      showFileShareModal,
      allChecked,
    };
  },
  props: {
    items: {
      type: Array,
      defalult: () => [],
    },
    displayFormat: {
      type: Number,
      default: 0, // 0:デフォルト、1：ゴミ箱
    },
    isDisplayCheckBox: {
      type: Boolean,
      default: true,
    },
    isDisplayTab: {
      type: Boolean,
      default: false,
    },
    isDisplayRowMenu: {
      type: Boolean,
      default: true,
    },
    pagination: null,
    sortBy: {
      type: String,
      default: 'created_at',
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
    parentCheckedItems: {
      type: Array,
      defalult: () => [],
    },
    dateFilterValue: {
      type: String,
      default: '',
    },
    fileTarget: {
      type: String,
      default: 'all',
    },
  },
  created: function() {},
  mounted() {
    this.renameModal = new Modal(this.$refs.renameModal);

    const { value } = useField('inputMeetingName', undefined, { initialValue: this.inputMeetingName });
    this.inputMeetingName = value;
  },
  computed: {
    checkedItemsProxy: {
      get() {
        return this.checkedItems;
      },
      set(value) {
        this.checkedItems = value;
        this.$emit('update:checked-items', value);
      },
    },
    isItems() {
      return this.items.length > 0;
    },
  },
  methods: {
    goToPage(toPage) {
      this.$emit('go-to-page', toPage);
    },
    changePerPage() {
      this.$emit('change-per-page', this.perPage);
    },
    sort(itemName) {
      this.$emit('sort', itemName);
    },
    sortIcon(column) {
      if (column === this.sortBy) {
        if ('asc' === this.sortOrder) {
          return '▲';
        } else {
          return '▼'
        }
      }

      return '';
    },
    renameModalShow(fileId, meetingName, updatedAt) {
      this.fileId = fileId;
      this.inputMeetingName = meetingName;
      this.updatedAt = updatedAt;
      this.renameModal.show();
    },
    async updateMeetingName() {
      this.showLoading();

      const result = await this.validate();

      if (result.valid) {
        const params = {
          file_id: this.fileId,
          meeting_name: this.inputMeetingName,
          updated_at: this.updatedAt,
        };
        await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_file_meeting_name",
            params
          )
          .then((response) => {
            if (response.data.status) {
              // 更新内容を一覧に反映
              this.$emit('search-files');
              this.renameModal.hide();
            } else {
              alert(response.data.message);
            }
          })
          .catch((error) => {
          })
          .finally(() =>{
            this.hideLoading();
          });
      } else {
        this.hideLoading();
      }
    },
    deleteFile(fileId, meetingName) {
      this.$emit('delete-file', {fileId: fileId, meetingName: meetingName})
    },
    async retryTranscript(fileId) {
      this.showLoading();

      const params = {
        file_id: fileId,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/file-transcription/retry_transcript",
          params
        )
        .then((response) => {
          if (!response.data.status) {
            alert(response.data.message);
          }
        })
        .catch((error) => {
        })
        .finally(() =>{
          // 更新内容を一覧に反映
          this.$emit('search-files');
        });
    },
    changeTabAllFiles() {
      this.$emit('search-all-files');
    },
    changeTabMyFiles() {
      this.$emit('search-my-files');
    },
    changeTabSharedFiles() {
      this.$emit('search-shared-files');
    },
    clearDateFilter() {
      this.$emit('clear-date-filter');
    },
    navigateToDetail(item, event) {
      if (
        event.target.closest('.record-check') ||
        event.target.closest('.record-actions')
      ) {
        return;
      }
      // ステータスが完了のみ詳細画面に遷移する
      if (this.isTranscriptionCompleted(item.file_type, item.file_transcription_status)) {
        this.$router.push({ path: '/detail', query: { fileId: item.file_id } });
      }
    },
  },
  watch: {
    allChecked(newValue) {
      if (newValue) {
        this.checkedItems = this.items.filter(item => item.delete_permission || item.share_permission).map(item => item.file_id);
        this.$emit('update:checked-items', this.checkedItems);
      } else {
        this.checkedItems = [];
        this.$emit('update:checked-items', []);
      }
    },
    items() {
      this.checkedItems = [];
      this.$emit('update:checked-items', []);
    },
    parentCheckedItems(newValue) {
      this.checkedItems = [...newValue];
    },
  },
};
</script>

<style scoped>
/* FILES */
.data-table tr:first-child th {
  background-color: transparent;
}
.data-table thead {
  background-color: var(--disabled);
}
.data-table thead tr th,
.data-table tbody tr td,
.dt-info {
  font-size: 0.9rem;
  line-height: 1rem;
}
tbody,
td,
tfoot,
th,
thead,
td {
  border-color: var(--actived);
}
.data-table thead tr th:hover {
  outline: 2px solid var(--sub-border)!important;
  outline-offset: -2px;
}
.data-table tbody tr:hover {
  background-color: var(--actived);
}
.record-info {
  background-color: transparent!important;
}
.record-icon {
  width: 24px;
  height: 24px;
  background-color: var(--icon-target);
  border-radius: 3px;
  color: var(--white);
  padding: 2px;
}
.record-icon .material-symbols-outlined {
  font-size: 20px;
}
.status-icon.completed {
  color: var(--success);
}
.status-icon.pending {
  color: var(--sub-warning);
}
.status-icon.failed {
  color: var(--sub-alert);
}
.status-icon.shared {
  color: var(--shared);
}
.status-icon.material-symbols-outlined {
  font-size: 16px;
}

.options-icon {
  width: 24px;
  height: 24px;
  background-color: var(--actived);
  border-radius: 3px;
  color: var(--icon-target);
  padding: 2px;
}
.options-icon .material-symbols-outlined {
  font-size: 20px;
}

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}
.icon.minutes {
  background-image: url("../../assets/font-symbol/minustes.svg");
}
.icon.rename {
  background-image: url("../../assets/font-symbol/rename.svg");
}
.record-info.record-actions {
  text-align: right;
}
.record-info.record-actions .dropdown-menu {
  font-size: 14px;
  color: var(--secondary);
}
.record-info.record-actions .dropdown-menu .material-symbols-outlined {
  font-size: 21px;
  vertical-align: -4px;
}
.record-info.record-actions button {
  width: min-content;
  padding: 0;
}
.record-info.record-actions button:focus {
  outline: 0;
  border: 0;
}
.table-status{
  width: 7%;
}
.table-options,
.table-duration {
  width: 10%;
}
.table-created-by {
  width: 10%;
}
.table-created-at {
  width: 15%;
}
.table-action {
  width: 2%;
}
table.data-table>tbody>tr {
  height: calc(5 *  var(--screen-height));
  vertical-align: middle;
  transition: background-color 0.3s ease-in-out;
}
.record-duration,
.record-created-at {
  color: var(--sub-text) !important;
  font-size:  0.7rem !important;
  text-align: right;
}
.record-created-by {
  color: var(--sub-text) !important;
  font-size:  0.7rem !important;
}
.record-info .meeting-title {
  line-height: 1.5em;
  width: 27vw;
  display: inline-block;
}
.files-type-filter #nav-tab .btn.active {
  background-color: var(--primary);
  border: var(--primary);
}
.files-type-filter #nav-tab .btn:hover {
  background-color: var(--primary);
  border: var(--primary);
}
.files-filter {
  padding: 5px 20px 10px 20px;
}
.files-filter .filter {
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 19px;
  background-color: var(--fade-background);
  align-items: center;
  margin-right: 10px;
}
.options-filter{
  margin-left: 5px;
}
.options-filter:focus {
  box-shadow: none;
}
.reset-opt {
  font-size: 22px;
  margin-left: 10px;
}
.select-title {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  vertical-align: 5px;
}
.table-check {
  width: 2%;
}
.checking-btn{
  font-size: 14px;
}
.checking-btn .material-symbols-outlined {
  font-size: 21px;
}
.filename-title {
  max-width: 36ch;
}
.modal-title {
  display: inherit;
}
.sharing-search {
  border-bottom: solid 1px var(--actived);
}
.sharing-member-input {
  border: none;
}
.sharing-member-input:focus {
  box-shadow: none;
}
.record-tags {
  display: none;
}
.record-tags .options-icon {
  width: 16px;
  height: 16px;
}
.record-tags .options-icon .material-symbols-outlined {
  font-size: 12px;
}
.record-tags .options-icon .icon {
  width: 12px;
  height: 12px;
  vertical-align: baseline;
}
</style>
