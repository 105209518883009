import { ref, reactive, inject } from 'vue';
import axios from "@/axios";
import { useFileSearchStore } from '../../stores';

export function useFileSearch() {
  const items = ref([]);
  const page = ref(1);
  const per_page = ref(10);
  const sort = reactive({ sort_by: "created_at", sort_order: "desc" });
  const pagination = ref({
    has_prev: false,
    has_next: false,
    prev_num: 0,
    next_num: 0,
    page: 0,
    total_pages: 0,
    page_numbers: 0,
    total_count: 0,
    start_index: 0,
    end_index: 0,
  });
  const checkedItems = ref([]);
  // 検索条件
  const fileTarget = ref('all');
  const searchType = ref(0);
  const conditions = reactive({
    meeting_name: "",
    keyword: "",
    file_type: ref([]),
    status: ref([]),
    conversion: ref([]),
    created_member_mst_id: ref([]),
    created_at_from: null,
    created_at_to: null,
    updated_member_mst_id: ref([]),
    updated_at_from: null,
    updated_at_to: null,
    filter_conversion: null,
    filter_created_member_mst_id: null,
    filter_created_at_from: null,
    filter_created_at_to: null,
  });
  // ローディング
  const loadingState = inject('loading');

  const showLoading = () => {
    loadingState.updateLoading(true);
  };

  const hideLoading = () => {
    loadingState.updateLoading(false);
  };

  // ファイル検索処理
  const searchFiles = async (loading = true) => {
    if (loading) {
      showLoading();
    }
    const params = {
      page: page.value,
      per_page: per_page.value,
      sort: sort,
      file_target: fileTarget.value,
      search_type: searchType.value,
      conditions: {
        meeting_name: conditions.meeting_name,
        keyword: conditions.keyword,
        file_type: conditions.file_type.value,
        status: conditions.status.value,
        conversion: conditions.conversion.value,
        created_member_mst_id: conditions.created_member_mst_id.value,
        created_at_from: conditions.created_at_from,
        created_at_to: conditions.created_at_to,
        updated_member_mst_id: conditions.updated_member_mst_id.value,
        updated_at_from: conditions.updated_at_from,
        updated_at_to: conditions.updated_at_to,
        filter_conversion: conditions.filter_conversion,
        filter_created_member_mst_id: conditions.filter_created_member_mst_id,
        filter_created_at_from: conditions.filter_created_at_from,
        filter_created_at_to: conditions.filter_created_at_to,
      },
    };
    await axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/file_search",
        params
      )
      .then((response) => {
        if (response.data.status) {
          items.value = response.data.items;
          pagination.value = response.data.pagination;
        } else {
          alert("検索失敗しました。");
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        hideLoading();
      });
  };

  // ページ移動
  const goToPage = (toPage) => {
    page.value = toPage;
    searchFiles();
  };

  // 表示件数変更
  const changePerPage = (toPerPage) => {
    page.value = 1;
    per_page.value = toPerPage;
    searchFiles();
  };

  // 並び順変更
  const sortPage = (itemName) => {
    page.value = 1;
    if (sort.sort_by === itemName) {
      sort.sort_order = sort.sort_order === 'asc' ? 'desc' : 'asc';
    } else {
      sort.sort_order = 'asc';
    }
    sort.sort_by = itemName;
    searchFiles();
  };

  // すべてのファイル検索
  const searchAllFiles = () => {
    fileTarget.value = 'all';
    page.value = 1;
    searchFiles();
  };

  // マイファイル検索
  const searchMyFiles = () => {
    fileTarget.value = 'my_files';
    page.value = 1;
    searchFiles();
  };

  // 共有されたファイル検索
  const searchSharedFiles = () => {
    fileTarget.value = 'share';
    page.value = 1;
    searchFiles();
  };

  // クイック検索
  const quickSearch = (meetingName) => {
    if (meetingName !== '') {
      // 検索条件を設定
      fileTarget.value = 'all';
      searchType.value = 0;
      conditions.meeting_name = meetingName;

      // 検索結果の取得数設定
      page.value = 1;
      per_page.value = 10;
      sort.sort_by = "created_at";
      sort.sort_order = "desc";

      searchFiles(false);

    } else {
      items.value = [];
    }
  };

  // 新しい条件で検索
  const searchWithNewConditions = (newConditions) => {
    conditions.meeting_name = newConditions.meetingName || "";
    conditions.keyword = newConditions.keyword || "";
    conditions.file_type.value = newConditions.fileTypes || [];
    conditions.status.value = newConditions.statuses || [];
    conditions.conversion.value = newConditions.conversions || [];
    conditions.created_member_mst_id.value = newConditions.createdMemberMstIds || [];
    conditions.created_at_from = newConditions.createdAtFrom || "";
    conditions.created_at_to = newConditions.createdAtTo || "";
    conditions.updated_member_mst_id.value = newConditions.updatedMemberMstIds || [];
    conditions.updated_at_from = newConditions.updatedAtFrom || "";
    conditions.updated_at_to = newConditions.updatedAtTo || "";
    // フィルター条件をクリア
    conditions.filter_conversion = null;
    conditions.filter_created_member_mst_id = null;
    conditions.filter_created_at_from = null;
    conditions.filter_created_at_to = null;

    searchAllFiles();
  };

  // ファイル削除
  const deleteFile = async (deleteFile) => {
    if (window.confirm(deleteFile.meetingName + "を削除しますか？")) {
      showLoading();
      const params = {
        file_id: deleteFile.fileId,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/delete_file",
          params
        )
        .then((response) => {
          if (response.data.status) {
            // ページネーションを調整
            paginationAdjustment(1);
            // 更新内容を一覧に反映
            searchFiles(false);
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
        })
        .finally(() =>{
          hideLoading();
        });
    }
  };

  // 選択中のファイル削除
  const deleteMultipleFiles = async () => {
    if (window.confirm("選択中のファイルを削除しますか？")) {
      showLoading();
      let deletedFilesNum = checkedItems.value.length;
      const params = {
        file_ids: checkedItems.value,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/delete_multiple_files",
          params
        )
        .then((response) => {
          if (!response.data.status) {
            // 削除失敗
            let errMessage = "";
            response.data.messages.forEach((message) => {
              let errFile = items.value.find((item) => item.file_id == message.file_id);
              errMessage += `${errFile.meeting_name}：${message.message}\n`;
            });
            alert(errMessage);
            deletedFilesNum = checkedItems.value.length - response.data.messages.length;
          } else {
            useFileSearchStore().setClearAllChecked();
          }
        })
        .catch((error) => {
        })
        .finally(() =>{
          paginationAdjustment(deletedFilesNum);
          searchFiles(false);
          hideLoading();
        });
    }
  };

  // ページネーションの調整
  const paginationAdjustment = (deletedFilesNum) => {
    if (page.value < pagination.value.total_pages) {
      return;
    }

    // ファイルを削除した結果ページ内にデータがなくなった場合、前のページを表示
    let reducePage = pagination.value.end_index - pagination.value.start_index - deletedFilesNum;
    if (reducePage <= 0 && page.value > 1) {
      page.value--;
    }
  };

  // 選択中の文字起こし再実行
  const retryMultipleTranscript = async () => {
    showLoading();
    const params = {
      file_ids: checkedItems.value,
    };
    await axios
      .post(
        process.env.VUE_APP_API_ENDPOINT + "/file-transcription/retry_multiple_transcript",
        params
      )
      .then((response) => {
        if (!response.data.status) {
          // 文字起こし失敗を含む場合
          let errMessage = "";
          response.data.messages.forEach((message) => {
            errMessage += `${message.message}\n`;
          });
          alert(errMessage);
        }
      })
      .catch((error) => {
      })
      .finally(() =>{
        searchFiles(false);
        hideLoading();
      });
};

  return {
    items,
    page,
    per_page,
    sort,
    pagination,
    checkedItems,
    fileTarget,
    searchType,
    conditions,
    searchFiles,
    goToPage,
    changePerPage,
    sortPage,
    searchAllFiles,
    searchMyFiles,
    searchSharedFiles,
    searchWithNewConditions,
    quickSearch,
    deleteFile,
    deleteMultipleFiles,
    retryMultipleTranscript,
  };
}