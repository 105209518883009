<template>
  <div class="header">
    <FileKeywordSearch />
    <div v-if="userInfo" class="user-box d-flex">
      <div class="network-status me-3 d-flex justify-content-center">
        <span class="material-symbols-outlined network-status-icon"
        :class="`network-status-icon-${connectionIcon}`"
          @mouseover="showConnectStatus"
          @mouseleave="hideConnectStatus">
          {{ connectionIcon }}
        </span>
      </div>
      <p v-if="isShowConnectStatus" class="network-status-message">通信速度: {{ connectionStatus }}</p>
      <div class="user-menu me-3 d-flex">
        <div class="user-container">
          <span v-if="memberNotificationsCount > 0" class="badge">{{ memberNotificationsCount }}</span>
          <ProfileIcon :profileIconPath="userInfo['profile_icon_path']" :iconName="userInfo['icon_name']" :userName="userInfo['member_name']" />
        </div>
        <button
          id="userMenu"
          type="button"
          class="border-0 bg-transparent p-0"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          @click="getNotificationInfo()"
          >
            <span class="material-symbols-outlined desktop-dropdown-icon">
              keyboard_arrow_down
            </span>
            <span class="material-symbols-outlined mobile-dropdown-icon">
              menu
            </span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end user-dropdown">
          <li class="d-flex user-detail">
            <ProfileIcon :profileIconPath="userInfo['profile_icon_path']" :iconName="userInfo['icon_name']" :userName="userInfo['member_name']" />
            <div class="user-info">
              <div class="user-name">{{ userInfo['member_name'] }} </div>
              <div class="user-email">{{ userInfo['mail_address'] }}</div>
            </div>
          </li>
          <li>
            <div class="accordion">
              <span class="dropdown-item user-dropdown-item dropdown-span"
                @click.stop="toggleAccordion" 
                :aria-expanded="isOpen.toString()"
                data-target="#collapseOne"
                data-controll="#collapseOne">
                  <span class="material-symbols-outlined">
                    notifications
                  </span>
                  通知（{{ memberNotificationsCount }}件）
                  <span class="material-symbols-outlined notification-down-icon" :class="{open: isOpen}">
                    keyboard_arrow_down
                  </span>
              </span>
              <div class="scroll-content" id="scroll-content">
                <div id="collapseOne" class="collapse" :class="{show: isOpen}"
                    area-labelledby="headingOne" data-parent="#singleAccordion"
                    v-for="(memberNotification, index) in memberNotifications" :key="index">
                  <a
                    class="dropdown-item user-dropdown-item sub-dropdown-item"
                    @click="navigateToDetail(memberNotification['file_id'])"
                    >{{ memberNotification['notification_message'] }}
                  </a>
                </div>
                <div v-if="loading" class="text-center">loading</div>
              </div>
            </div>
          </li>
          <li class="mobile-navigator">
            <router-link class="dropdown-item user-dropdown-item" to="/">
              <span class="material-symbols-outlined dropdown-span"> home </span>
                ホーム
            </router-link>
          </li>
          <li class="mobile-navigator">
            <router-link class="dropdown-item user-dropdown-item" to="/file-management">
              <span class="material-symbols-outlined dropdown-span"> folder_open </span>
                ファイル管理
            </router-link>
          </li>
          <li class="mobile-navigator">
            <a class="dropdown-item user-dropdown-item" :href="contact_url"  target="_blank">
              <span class="material-symbols-outlined dropdown-span"> help </span>
                お問い合わせ
            </a>
          </li>
          <hr class="mobile-navigator">
          <li>
            <a class="dropdown-item user-dropdown-item" @click="signOut">
              <span class="material-symbols-outlined dropdown-span"> logout </span>
                ログアウト
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import toggleFunctions from "../mixin/toggleFunctions.js";
import authentication from "../mixin/authentication.js";
import "../../assets/stylesheet/reset.css";
import "../../assets/stylesheet/stylesheet.css";
import ProfileIcon from "../modules/ProfileIcon.vue";
import FileKeywordSearch from "../modules/FileKeywordSearch.vue";
import { useUserNotificationInfo } from "../composables/notificationInfo";
import { useUserNotificationInfoStore } from '@/stores/index';

export default {
  name: "MyHeader",
  mixins: [toggleFunctions, authentication],
  components: {ProfileIcon, FileKeywordSearch},
  data() {
    return {
      isOpen: false,
      userInfo: null,
      memberNotifications: null,
      memberNotificationsCount: 0,
      loading: false,
      page: 1,
      itemsPerPage: 10,
      connectionIcon: "close_small",
      connectionStatus: "利用不可",
      isShowConnectStatus: false,
      notificaionIntervalId: null,
      contact_url: `${process.env.VUE_APP_LP_URL}/contact/`
    };
  },
  async mounted() {
    if('connection' in navigator) {
      const connection = navigator.connection;
      this.updateConnectionStatus();
      connection.addEventListener('change', this.updateConnectionStatus);
    }else {
      this.connectionStatus = "利用不可";
    }
  },
  async created() {
    await this.getUserInfo();
    await this.getNotificationInfo();
    // 30秒ごとに通知チェック
    this.notificaionIntervalId = setInterval(this.getNotificationInfo, 30000);
  },
  beforeUnmount() {
    const dropdownMenu = document.getElementById("scroll-content");
    if(dropdownMenu) {
      dropdownMenu.removeEventListener('scroll', this.handleScroll);
    }
    clearInterval(this.notificaionIntervalId);
  },
  methods: {
    async getNotificationInfo() {
      await useUserNotificationInfo().getNotifications(useUserNotificationInfoStore().notificationLastUpdated);
      this.memberNotificationsCount = useUserNotificationInfoStore().notificationsCount;
      this.memberNotifications = useUserNotificationInfoStore().notifications;
    },
    showConnectStatus() {
      this.isShowConnectStatus = true;
    },
    hideConnectStatus() {
      this.isShowConnectStatus = false;
    },
    updateConnectionStatus() {
      const connection = navigator.connection;
      if(connection.downlink > 8) {
        this.connectionStatus = "安定";
        this.connectionIcon = "signal_cellular_alt";
      }else if(3 < connection.downlink <= 8) {
        this.connectionStatus = "やや安定";
        this.connectionIcon = "signal_cellular_alt_2_bar";
      }else if(0 < connection.downlink <= 3) {
        this.connectionStatus = "不安定";
        this.connectionIcon = "signal_cellular_alt_1_bar";
      }else {
        this.connectionStatus = "利用不可";
        this.connectionIcon = "close_small";
      }
    },
    setUserIconClass() {
      if(this.userInfo['icon_name'] != null) {
        return 'user-icon-icon_name';
      }else {
        return 'user-icon';
      }
    },
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      if(this.isOpen) {
        this.$nextTick(() => {
          const dropdownMenu = document.getElementById("scroll-content");
          if(dropdownMenu) {
            dropdownMenu.addEventListener('scroll', this.handleScroll);
          }
        });
      }
    },
    handleScroll(event) {
      const dropdownMenu = event.target;
      // スクロール最下部到達判定
      if(dropdownMenu.scrollTop + dropdownMenu.clientHeight >= dropdownMenu.scrollHeight) {
        // 通知取得数判定
        if(this.memberNotificationsCount > (this.page * this.itemsPerPage)) {
          // 通知差分取得
          this.getMemberNotificationsDiff();
        }
      }
    },
    // メンバーマスタからユーザ情報取得
    async getUserInfo() {
      await axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_header_user_info",
        )
        .then((response) => {
          if(response.data.status) {
            this.userInfo = response.data.user_info;
          }
        })
        .catch((error) => {
        });
    },
    // メンバー通知テーブルからメンバー通知差分取得
    getMemberNotificationsDiff() {
      this.loading = true;
      this.page += 1;
      const params = {
        page: this.page,
        per_page: this.itemsPerPage
      }
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_member_notifications_diff",
          params,
        )
        .then((response) => {
          if(response.data.status) {
            this.memberNotifications = [...this.memberNotifications, ...response.data.notifications];
          }
        })
        .catch((error) => {
        })
        .finally(() => {
          this.loading = false;
        });
    },
    navigateToDetail(file_id) {
      const query = {};
      query.fileId = file_id;
      this.$router.push({ path: '/detail', query });
    },
  },
};
</script>

<style scoped>
.user-icon-icon_name {
  background-color: var(--primary);
  height: 40px;
  width: 40px;
  font-size: 28px;
  border-radius: 50%;
  margin: 4px 0 0 4px;
  color: var(--white);
  font-weight: bolder;
  padding: 0 10px;
  font-size: x-small;
  padding-top: 15px;
}
.network-status-icon {
  cursor: pointer;
}
.network-status-icon-signal_cellular_alt {
  color: var(--primary);
}
.network-status-icon-signal_cellular_alt_2_bar {
  color: var(--primary);
}
.network-status-icon-signal_cellular_alt_1_bar {
  color: orange;
}
.network-status-icon-close_small {
  color: black;
  display: contents;
}
.network-status-message {
  background-color: black;
  color: white;
  border: 1px solid;
  padding: 5px;
  margin-top: 5px;
  position: absolute;
  z-index: 100;
  top: 75px;
  right: 40px;
}
.notification-down-icon.open {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}
.user-container {
  position: relative;
  display: inline-block;
}
.user-icon-img-label {
  height: 40px;
  width: 40px;
  font-size: 28px;
  border-radius: 50%;
  margin: 4px 0 0 4px;
  color: var(--white);
  font-weight: bolder;
}
.user-icon-img {
  width: inherit;
  height: inherit;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  object-fit: cover;
  border-radius: 50%;
}
.badge {
  position: absolute;
  right: 0px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: inline-grid;
  justify-content: center;
  padding: 5px;
}
.scroll-content {
  max-height: 130px;
  overflow-y: auto;
}
.dropdown-span {
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.scroll-content::-webkit-scrollbar {
  margin: 0 auto;
  width: 1px;
  background-color: white;
  scrollbar-width: thin;
}
.scroll-content::-webkit-scrollbar-thumb {
  background-color: var(--primary);
  border-radius: 1px;
}
.search-input:focus {
  box-shadow: none;
}
</style>