<template>
  <div :class="{'col-5': iconSize == 'large'}">
    <div v-if="isLoading" class="user-icon load" :class="iconSize">
    </div>
    <div v-else-if="imageUrl">
      <img class="user-icon-img" :class="iconSize" :src="imageUrl">
    </div>
    <div v-else class="user-icon" :class="iconSize">
      {{ userIconName }}
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted } from 'vue';
import firebase from "firebase/app";
import 'firebase/storage';

export default {
  name: "ProfileIcon",
  props: {
    profileIconPath: {
      type: String,
      default: null,
    },
    iconName: {
      type: String,
      default: null,
    },
    userName: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: 'small',
    },
  },
  setup(props) {
    const imageUrl = ref(null);
    const userIconName = ref('');
    const storage = firebase.app().storage(process.env.VUE_APP_STORAGE_PATH);
    const isLoading = ref(true);

    onMounted(async () => {
      setIcon(props.profileIconPath, props.iconName, props.userName);
    });

    watch(() => props.profileIconPath, (newIconPath) => {
      setIcon(newIconPath, props.iconName, props.userName);
    });

    watch(() => props.profileIconPath, (newIconName) => {
      setIcon(props.profileIconPath, newIconName, props.userName);
    });

    watch(() => props.userName, (newUserName) => {
      setIcon(props.profileIconPath, props.iconName, newUserName);
    });

    const setIcon = (iconPath, iconName, userName) => {
      if (!iconPath && !iconName && !userName) {
        return;
      }

      if (iconPath) {
        const imageRef = storage.ref(iconPath);
        imageRef.getDownloadURL()
          .then((url) => {
            imageUrl.value = url;
          })
          .catch((error) => {
            imageUrl.value = null;
          })
          .finally(() => {
            isLoading.value = false;
          });
      } else {
        if (iconName != null) {
          userIconName.value = iconName;
        } else {
          if(userName) {
            userIconName.value = userName.slice(0, 1);
          }
        }
        isLoading.value = false;
      }
    };

    return { imageUrl, userIconName, isLoading };
  },
}
</script>

<style scoped>
.user-icon-img.large {
  display: flex;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  color: var(--white);
  font-weight: bolder;
  margin: 4px 0 0 4px;
}

.user-icon-img.small {
  display: flex;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  color: var(--white);
  font-weight: bolder;
  margin: 4px 0 0 4px;
}

.user-icon {
  text-align: center;
  background-color: var(--primary);
  height: 40px;
  width: 40px;
  font-size: 28px;
  border-radius: 50%;
  color: var(--white);
  font-weight: bolder;
  margin: 4px 0 0 4px;
}

.user-icon.load {
  background-color: var(--background);
}

.user-icon.large {
  height: 75px;
  width: 75px;
  font-size: 32px;
  line-height: 75px;
}

.user-icon.small {
  height: 40px;
  width: 40px;
  font-size: 16px;
  line-height: 40px;
}
</style>