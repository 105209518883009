<template>
  <div class="main-container">
    <div class="page-title">
      <h3 v-if="isConditions">ファイル検索結果<a type="button" @click="clearConditions">×</a></h3>
      <h3 v-else>ファイル管理</h3>
    </div>
    <div class="page-main d-flex">
      <div class="left-section overflow-auto">
        <div class="files-filter d-flex">
          <div v-if="checkedItems.length == 0" class="filter d-flex">
            <span v-show="filterSelectedConversion !== '0'" id="resetOpt" class="reset-opt material-symbols-outlined" @click="clearFilterConversion">close<span
                class="select-title">変換:</span></span>
            <select id="optionsSelect" class="options-filter form-select bg-transparent border-0 outline-0"
              aria-label="Default select example" v-model="filterSelectedConversion" @change="changeFilterConversion">
              <option value="0" disabled selected hidden>変換</option>
              <option value="1">議事録</option>
              <option value="2">翻訳</option>
            </select>
          </div>
          <div v-if="checkedItems.length == 0" class="filter d-flex">
            <span v-show="filterSelectedCreatedMember !== '0'" id="resetCreater" class="reset-opt material-symbols-outlined" @click="clearFilterCreatedMember">close<span
                class="select-title">作成者:</span></span>
            <select id="createrSelect" class="options-filter form-select bg-transparent border-0 outline-0"
              aria-label="Default select example" v-model="filterSelectedCreatedMember" @change="changeFilterCreatedMember">
              <option value="0" disabled selected hidden>作成者</option>
              <option v-for="member in filterCreatedMembers" :key="member.member_mst_id" :value="member.member_mst_id">{{member.member_name}}</option>
            </select>
          </div>
          <div v-if="checkedItems.length == 0" class="filter d-flex">
            <span v-show="filterSelectedCreatedAt !== '0'" id="resetCreated" class="reset-opt material-symbols-outlined" @click="clearFilterCreatedAt">close<span
                class="select-title">作成日時:</span></span>
            <select id="createdSelect" class="options-filter form-select bg-transparent border-0 outline-0"
              aria-label="Default select example" v-model="filterSelectedCreatedAt" @change="changeFilterCreatedAt">
              <option value="0" disabled selected hidden>作成日時</option>
              <option value="1">今日</option>
              <option value="2">過去7日間</option>
              <option value="3">過去30日間</option>
              <option value="4">今年（{{thisYear}}）</option>
              <option value="5">昨年（{{previousYear}}）</option>
            </select>
          </div>
          <div v-if="checkedItems.length != 0" id="checkedCount" class="filter d-flex">
            <span id="resetCheck" role="button" class="reset-opt material-symbols-outlined" @click="clearCheckedItems">close</span>
            <span class="ps-1">{{checkedItems.length}} 選択中</span>
            <!-- 複数ファイルのファイル共有は保留 -->
            <!-- <a v-if="isDisplayShareMultipleFiles" type="button" class="btn checking-btn pe-0" onclick="">
              <span class="material-symbols-outlined">share</span>ファイルを共有する
            </a> -->
            <a v-if="isDisplayRedoTranscription" type="button" class="btn checking-btn pe-0" @click="retryMultipleTranscript">
              <span class="material-symbols-outlined me-1">cloud_sync</span>文字起こしをやり直す
            </a>
            <a v-if="isDisplayDeleteMultipleFiles" type="button" class="btn checking-btn pe-3 text-danger" @click="deleteMultipleFiles">
              <span class="material-symbols-outlined">delete</span>削除する
            </a>
          </div>
        </div>
        <CommonFileList
          :items="items"
          :pagination="pagination"
          :sortBy="sort.sort_by"
          :sortOrder="sort.sort_order"
          :parentCheckedItems="checkedItems"
          @search-files="searchFiles"
          @go-to-page="goToPage"
          @change-per-page="changePerPage"
          @sort="sortPage"
          @update:checked-items="checkedItems = $event"
          @delete-file="deleteFile"
        />
      </div>
      <div class="right-section justify-content-start">
        <div class="files-type-filter">
          <div id="nav-tab" role="tablist">
            <router-link class="btn btn-outline-secondary mb-1 w-100" :class="{'active': fileTarget == 'all'}" id="nav-all-files-tab"
              :to="{path: '/file-management', query: {fileTarget: 'all'}}">
              すべて
            </router-link>
            <router-link class="btn btn-outline-secondary mb-1 w-100" :class="{'active': fileTarget == 'my_files'}" id="nav-my-files-tab"
              :to="{path: '/file-management', query: {fileTarget: 'my_files'}}">
              マイファイル
            </router-link>
            <router-link class="btn btn-outline-secondary mb-1 w-100" :class="{'active': fileTarget == 'share'}" id="nav-shared-files-tab"
              :to="{path: '/file-management', query: {fileTarget: 'share'}}">
              共有ファイル
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import CommonFileList from "../modules/CommonFileList.vue";

import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useFileSearchStore } from '@/stores/index';
import { useFileShareStore } from '@/stores/index';
import { useFileSearch } from "../composables/fileSearch";
import { useFileStatus } from "../composables/fileStatus";

export default {
  name: "FileManagement",
  components: {
    CommonFileList,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const fileSearchStore = useFileSearchStore();
    const fileShareStore = useFileShareStore();
    const isConditions = ref(false);

    const {
      items,
      conditions,
      sort,
      page,
      pagination,
      checkedItems,
      fileTarget,
      searchFiles,
      goToPage,
      changePerPage,
      sortPage,
      searchAllFiles,
      searchMyFiles,
      searchSharedFiles,
      searchWithNewConditions,
      deleteFile,
      deleteMultipleFiles,
      retryMultipleTranscript,
    } = useFileSearch();

    const {
      isTranscriptionCompleted,
      isTranscriptionError,
    } = useFileStatus();

    const filterCreatedMembers = ref([]);

    const filterSelectedConversion = ref('0');
    const filterSelectedCreatedMember = ref('0');
    const filterSelectedCreatedAt = ref('0');

    const now = ref(new Date());

    onMounted(async () => {
      getFilterCreatedMembers();
    });

    // クエリパラメータの変更で再検索を実行
    watch(() => route.query, (newQuery) => {
      if (newQuery.fileTarget == 'all') {
        // 「すべて」を初期表示
        searchAllFiles();
      } else if (newQuery.fileTarget == 'my_files') {
        // 「マイファイル」を初期表示
        searchMyFiles();
      } else if (newQuery.fileTarget == 'share') {
        // 「共有ファイル」を初期表示
        searchSharedFiles();
      } else if (newQuery.quickSearch) {
        // 会議名で検索
        searchWithNewConditions(fileSearchStore.getQuickSearch);
        isConditions.value = true;
        filterSelectedConversion.value = '0';
        filterSelectedCreatedMember.value = '0';
        filterSelectedCreatedAt.value = '0';
      } else {
        // 詳細条件で検索
        const conditions = fileSearchStore.getConditions;
        searchWithNewConditions(conditions);
        if (fileSearchStore.isConditions) {
          isConditions.value = true;
          filterSelectedConversion.value = '0';
          filterSelectedCreatedMember.value = '0';
          filterSelectedCreatedAt.value = '0';
        } else {
          isConditions.value = false;
          if (!fileSearchStore.isConditions) {
            // 検索条件なし時はフィルタークリア
            filterSelectedConversion.value = '0';
            filterSelectedCreatedMember.value = '0';
            filterSelectedCreatedAt.value = '0';
          }
        }
      }
    }, {
      immediate: true
    });

    // 検索条件をクリア
    const clearConditions = () => {
      // 検索条件クリア
      fileSearchStore.clearFileSearch();

      // ファイル管理画面再表示
      router.push({
        name: 'FileManagement',
        query: {
          // クエリパラメータの変更で検索実行する
          searchDate: Date.now(),
        }
      });
    };

    // フィルター作成者一覧を取得
    const getFilterCreatedMembers = async () => {
      await axios
        .get(
          process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_filer_members",
        )
        .then((response) => {
          filterCreatedMembers.value = response.data.members;
        })
        .catch((error) => {
          filterCreatedMembers.value = [];
        });
    };

    // 変換フィルター変更時
    const changeFilterConversion = () => {
      if (filterSelectedConversion.value == '1') {
        // 議事録
        conditions.filter_conversion = '0';
      } else if (filterSelectedConversion.value == '2') {
        // 翻訳
        conditions.filter_conversion = '1';
      } else {
        // 存在しない選択肢は、条件クリア
        conditions.filter_conversion = null;
      }
      page.value = 1;
      searchFiles();
    };

    // 変換フィルター解除時
    const clearFilterConversion = () => {
      filterSelectedConversion.value = '0';
      conditions.filter_conversion = [];
      page.value = 1;
      searchFiles();
    };

    // 作成者フィルター選択時
    const changeFilterCreatedMember = () => {
      conditions.filter_created_member_mst_id = filterSelectedCreatedMember.value;
      page.value = 1;
      searchFiles();
    };

    // 作成者フィルター解除時
    const clearFilterCreatedMember = () => {
      filterSelectedCreatedMember.value = '0';
      conditions.filter_created_member_mst_id = [];
      page.value = 1;
      searchFiles();
    };

    // 作成日時フィルター選択時
    const changeFilterCreatedAt = () => {
      let nowDate = new Date();

      if (filterSelectedCreatedAt.value == '1') {
        // 今日
        let year = nowDate.getFullYear();
        let month = String(nowDate.getMonth() + 1).padStart(2, '0');
        let day = String(nowDate.getDate()).padStart(2, '0');

        conditions.filter_created_at_from = `${year}-${month}-${day}`;
        conditions.filter_created_at_to = `${year}-${month}-${day}`;
      } else if (filterSelectedCreatedAt.value == '2') {
        // 過去7日間
        let tmpDate = new Date();
        tmpDate.setDate(nowDate.getDate() - 6);
        let yearFrom = tmpDate.getFullYear();
        let monthFrom = String(tmpDate.getMonth() + 1).padStart(2, '0');
        let dayFrom = String(tmpDate.getDate()).padStart(2, '0');
        let yearTo = nowDate.getFullYear();
        let monthTo = String(nowDate.getMonth() + 1).padStart(2, '0');
        let dayTo = String(nowDate.getDate()).padStart(2, '0');

        conditions.filter_created_at_from = `${yearFrom}-${monthFrom}-${dayFrom}`;
        conditions.filter_created_at_to = `${yearTo}-${monthTo}-${dayTo}`;
      } else if (filterSelectedCreatedAt.value == '3') {
        // 過去30日間
        let tmpDate = new Date();
        tmpDate.setDate(nowDate.getDate() - 29);
        let yearFrom = tmpDate.getFullYear();
        let monthFrom = String(tmpDate.getMonth() + 1).padStart(2, '0');
        let dayFrom = String(tmpDate.getDate()).padStart(2, '0');
        let yearTo = nowDate.getFullYear();
        let monthTo = String(nowDate.getMonth() + 1).padStart(2, '0');
        let dayTo = String(nowDate.getDate()).padStart(2, '0');

        conditions.filter_created_at_from = `${yearFrom}-${monthFrom}-${dayFrom}`;
        conditions.filter_created_at_to = `${yearTo}-${monthTo}-${dayTo}`;
      } else if (filterSelectedCreatedAt.value == '4') {
        // 今年
        let yearFrom = nowDate.getFullYear();
        let monthFrom = '01';
        let dayFrom = '01';
        let yearTo = nowDate.getFullYear();
        let monthTo = '12';
        let dayTo = '31';

        conditions.filter_created_at_from = `${yearFrom}-${monthFrom}-${dayFrom}`;
        conditions.filter_created_at_to = `${yearTo}-${monthTo}-${dayTo}`;
      } else if (filterSelectedCreatedAt.value == '5') {
        // 昨年
        let yearFrom = nowDate.getFullYear() - 1;
        let monthFrom = '01';
        let dayFrom = '01';
        let yearTo = nowDate.getFullYear() - 1;
        let monthTo = '12';
        let dayTo = '31';

        conditions.filter_created_at_from = `${yearFrom}-${monthFrom}-${dayFrom}`;
        conditions.filter_created_at_to = `${yearTo}-${monthTo}-${dayTo}`;
      } else {
        conditions.filter_created_at_from = null;
        conditions.filter_created_at_to = null;
      }

      page.value = 1;
      searchFiles();
    };

    // 作成日時フィルター解除時
    const clearFilterCreatedAt = () => {
      filterSelectedCreatedAt.value = '0';
      conditions.filter_created_at_from = null;
      conditions.filter_created_at_to = null;
      page.value = 1;
      searchFiles();
    };

    // 今年
    const thisYear = computed(() => {
      return now.value.getFullYear();
    });

    // 昨年
    const previousYear = computed(() => {
      return now.value.getFullYear() - 1;
    });

    // 選択解除
    const clearCheckedItems = () => {
      checkedItems.value = [];
      fileSearchStore.setClearAllChecked();
    };

    // ファイルを共有する表示条件
    const isDisplayShareMultipleFiles = ref(true);
    // 文字起こしをやり直す表示条件
    const isDisplayRedoTranscription = ref(true);
    // 削除する表示条件
    const isDisplayDeleteMultipleFiles = ref(true);
    watch(checkedItems, (newVal) => {
      // チェックしているファイル
      const checkItems = items.value.filter(item => newVal.includes(item.file_id));

      // 「ファイルを共有する」が可能なリスト
      const shareItems = checkItems.filter(item => {
        if (isTranscriptionCompleted(item.file_type, item.file_transcription_status) && item.share_permission) {
          return true;
        }
        return false;
      });
      isDisplayShareMultipleFiles.value = checkItems.length == shareItems.length;

      // 「文字起こしをやり直す」が可能なリスト
      const redoTranscriptionItems = checkItems.filter(item => isTranscriptionError(item.file_type, item.file_transcription_status));
      isDisplayRedoTranscription.value = checkItems.length == redoTranscriptionItems.length;

      // 「削除する」が可能なリスト
      const deleteItems = checkItems.filter(item => item.delete_permission);
      isDisplayDeleteMultipleFiles.value = checkItems.length == deleteItems.length;
    },
    {
      deep: true
    });

    watch(() => fileShareStore.execFileListUpdate, (newValue) => {
      // ファイル共有後の一覧の更新
      if (newValue) {
        searchFiles();
      }
    });

    return {
      isConditions,
      items,
      sort,
      pagination,
      checkedItems,
      fileTarget,
      searchFiles,
      goToPage,
      changePerPage,
      sortPage,
      searchAllFiles,
      searchMyFiles,
      searchSharedFiles,
      filterCreatedMembers,
      filterSelectedConversion,
      clearFilterConversion,
      changeFilterConversion,
      filterSelectedCreatedMember,
      clearFilterCreatedMember,
      changeFilterCreatedMember,
      filterSelectedCreatedAt,
      clearFilterCreatedAt,
      changeFilterCreatedAt,
      thisYear,
      previousYear,
      clearCheckedItems,
      deleteMultipleFiles,
      retryMultipleTranscript,
      isDisplayDeleteMultipleFiles,
      isDisplayShareMultipleFiles,
      isDisplayRedoTranscription,
      deleteFile,
      clearConditions,
    };
  },
};
</script>

<style scoped>
/* FILES */
.data-table tr:first-child th {
  background-color: transparent;
}
.data-table thead {
  background-color: var(--disabled);
}
.data-table thead tr th {
  display: flex;
  justify-content: space-between;
}
.data-table thead tr th,
.data-table tbody tr td,
.dt-info {
  font-size: 0.9rem;
  line-height: 1rem;
}
tbody,
td,
tfoot,
th,
thead,
td {
  border-color: var(--actived);
}
.data-table thead tr th:hover {
  outline: 2px solid var(--sub-border)!important;
  outline-offset: -2px;
}
.data-table tbody tr:hover {
  background-color: var(--actived);
}
.record-info {
  background-color: transparent!important;
}
.record-icon {
  width: 24px;
  height: 24px;
  background-color: var(--icon-target);
  border-radius: 3px;
  color: var(--white);
  padding: 2px;
}
.record-icon .material-symbols-outlined {
  font-size: 20px;
}
.status-icon.completed {
  color: var(--success);
}
.status-icon.pending {
  color: var(--sub-warning);
}
.status-icon.failed {
  color: var(--sub-alert);
}
.status-icon.shared {
  color: var(--shared);
}
.status-icon.material-symbols-outlined {
  font-size: 16px;
}

.options-icon {
  width: 24px;
  height: 24px;
  background-color: var(--actived);
  border-radius: 3px;
  color: var(--icon-target);
  padding: 2px;
}
.options-icon .material-symbols-outlined {
  font-size: 20px;
}

.icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  vertical-align: middle;
}
.icon.minutes {
  background-image: url("../../assets/font-symbol/minustes.svg");
}
.icon.rename {
  background-image: url("../../assets/font-symbol/rename.svg");
}
.record-info.record-actions {
  text-align: right;
}
.record-info.record-actions .dropdown-menu {
  font-size: 14px;
  color: var(--secondary);
}
.record-info.record-actions .dropdown-menu .material-symbols-outlined {
  font-size: 21px;
  vertical-align: -4px;
}
.record-info.record-actions button {
  width: min-content;
  padding: 0;
}
.record-info.record-actions button:focus {
  outline: 0;
  border: 0;
}
.table-status,
.table-options {
  width: 10%;
}
.table-duration,
.table-created-by {
  width: 15%;
}
.table-created-at {
  width: 15%;
}
.table-action {
  width: 2%;
}
table.data-table>tbody>tr {
  height: calc(5 * var(--screen-height));
  vertical-align: middle;
  transition: background-color 0.3s ease-in-out;
}
.record-duration,
.record-created-by,
.record-created-at {
  color: var(--sub-text) !important;
  font-size:  0.7rem !important;
}
.record-info .meeting-title {
  line-height: 1.5em;
  width: 27vw;
  display: inline-block;
}
.files-type-filter #nav-tab .btn.active {
  background-color: var(--primary);
  border: var(--primary);
}
.files-type-filter #nav-tab .btn:hover {
  background-color: var(--primary);
  border: var(--primary);
}
.files-filter {
  padding: 5px 20px 10px 20px;
}
.files-filter .filter {
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 19px;
  background-color: var(--fade-background);
  align-items: center;
  margin-right: 10px;
}
.options-filter{
  margin-left: 5px;
}
.options-filter:focus {
  box-shadow: none;
}
.reset-opt {
  font-size: 22px;
  margin-left: 10px;
}
.select-title {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  vertical-align: 5px;
}
.table-check {
  width: 2%;
}
.checking-btn{
  font-size: 14px;
}
.checking-btn .material-symbols-outlined {
  font-size: 21px;
}
.filename-title {
  max-width: 36ch;
}
.modal-title {
  display: inherit;
}
.sharing-search {
  border-bottom: solid 1px var(--actived);
}
.sharing-member-input {
  border: none;
}
.sharing-member-input:focus {
  box-shadow: none;
}
.record-tags {
  display: none;
}
.record-tags .options-icon {
  width: 16px;
  height: 16px;
}
.record-tags .options-icon .material-symbols-outlined {
  font-size: 12px;
}
.record-tags .options-icon .icon {
  width: 12px;
  height: 12px;
  vertical-align: baseline;
}
</style>
