<template>
  <div class="side" :class="{ 'sm': !isCollapsed }">
    <a type="button" class="logo ms-4" @click="navigateRoute()">
      <img class="main-logo" src="../../assets/img/texta-logo-main.svg" alt="Logo" :class="{ 'sm': !isCollapsed }"/>
      <img class="main-logo-sm" src="../../assets/img/texta-logo-sm.svg" alt="Logo" :class="{ 'sm': !isCollapsed }"/>
    </a>
    <button id="buttonCollapse" class="side-collapse-button" v-if="isCollapsed" @click="toggleSide">
      <span class="material-symbols-outlined"> arrow_back_ios </span>
    </button>
    <div class="navigation-bar" :class="{ 'sm': !isCollapsed }">
      <div class="navigation-top">
        <div class="navigation-item" data-bs-toggle="tooltip" data-bs-placement="right" :title="!isCollapsed ? 'ホーム' : ''"  :class="{'active-page': $route.path == '/', 'sm': !isCollapsed }">
          <router-link class="navigation-link d-block" to="/">
            <span class="material-symbols-outlined navigation-icon align-midlle" :class="{ 'sm': !isCollapsed }">home</span>
            <span class="navigation-text" :class="{ 'sm': !isCollapsed }">ホーム</span>
          </router-link>
        </div>
        <div class="navigation-item" :class="{'active-page': $route.path == '/file-management', 'sm': !isCollapsed}">
          <div class="dropend">
            <router-link class="navigation-link" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown"
              aria-expanded="false" to="/file-management">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="material-symbols-outlined navigation-icon" :class="{ 'sm': !isCollapsed }">folder_open</span>
                  <span class="navigation-text" :class="{ 'sm': !isCollapsed }">ファイル管理</span>
                </div>
                <span class="material-symbols-outlined navigation-text pe-2" :class="{ 'sm': !isCollapsed }">
                  arrow_forward_ios
                </span>
              </div>
            </router-link>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <ul class="side-sub-menu">
                <li :class="{'active-sub-menu': $route.path == '/file-management' && $route.query.fileTarget != 'my_files' && $route.query.fileTarget != 'share'}">
                  <router-link class="dropdown-item py-2 px-4" :to="{path: '/file-management', query: {fileTarget: 'all'}}">すべて</router-link>
                </li>
                <li :class="{'active-sub-menu': $route.path == '/file-management' && $route.query.fileTarget == 'my_files'}">
                  <router-link class="dropdown-item py-2 px-4" :to="{path: '/file-management', query: {fileTarget: 'my_files'}}">マイファイル</router-link>
                </li>
                <li :class="{'active-sub-menu': $route.path == '/file-management' && $route.query.fileTarget == 'share'}">
                  <router-link class="dropdown-item py-2 px-4"  :to="{path: '/file-management', query: {fileTarget: 'share'}}">共有ファイル</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="navigation-btm">
        <hr />
        <div class="navigation-item" :class="{ 'sm': !isCollapsed }">
          <div class="dropend">
            <a class="navigation-link" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown"
              aria-expanded="false" href="#">
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span class="material-symbols-outlined navigation-icon" :class="{ 'sm': !isCollapsed }">help</span>
                  <span class="navigation-text" :class="{ 'sm': !isCollapsed }">ヘルプとサポート</span>
                </div>
                <span class="material-symbols-outlined navigation-text pe-2" :class="{ 'sm': !isCollapsed }">
                  arrow_forward_ios
                </span>
              </div>
            </a>
            <div class="dropdown-menu down-menu" aria-labelledby="dropdownMenuButton">
              <ul class="side-sub-menu">
                <li>
                  <a class="dropdown-item py-2 px-4" :href="qa_url" target="_blank">FAQ</a>
                </li>
                <li>
                  <a class="dropdown-item py-2 px-4" :href="contact_url" target="_blank">お問い合わせ</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="navigation-item" data-bs-toggle="tooltip" data-bs-placement="right" :title="!isCollapsed ? '設定' : ''" :class="{'active-page': $route.path == '/setting', 'sm': !isCollapsed }">
          <router-link class="navigation-link d-block" to="/setting">
            <span class="material-symbols-outlined navigation-icon" :class="{ 'sm': !isCollapsed }">settings</span>
            <span class="navigation-text" :class="{ 'sm': !isCollapsed }">設定</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// modules
import { useSidebarStore } from "@/stores"
import { storeToRefs } from 'pinia';
import { Tooltip } from 'bootstrap'

export default {
  name: "Sidebar",
  data() {
    return {
      qa_url: `${process.env.VUE_APP_LP_URL}/qa/`,
      contact_url: `${process.env.VUE_APP_LP_URL}/contact/`
    };
  },
  setup() {
    const store = useSidebarStore(); // Storeにアクセス
    const toggleSidebar = () => {
      store.toggleSidebar(); // アクションを実行
    };
    const { collapsed } = storeToRefs(store)
    const isCollapsed = collapsed
    return { toggleSidebar, isCollapsed};
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })
  },
  methods: {
    toggleSide() {
      this.toggleSidebar();
    },
    navigateRoute() {
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.active-sub-menu .dropdown-item {
  background-color: var(--primary);
  color: var(--white);
  border-radius: 8px;
}
</style>