<template>
  <div class="main-container">
    <div class="page-title">
      <h3>新規収録</h3>
    </div>
    <div class="page-main">
      <div class="realtime-transcribe d-flex">
        <div class="options-section">
          <div class="options-form">
            <div class="mb-3">
              <label for="optionsFormInput1" class="form-label"
                >会議タイトル</label
              >
              <input
                type="text"
                class="form-control options-input"
                id="optionsFormInput1"
                placeholder="例）経営会議"
                v-model="fileInfo.meeting_name"
              />
              <span v-if="errors.inputMeetingName" class="text-danger">{{
                errors.inputMeetingName
              }}</span>
            </div>
            <div class="mb-3">
              <label for="optionsFormInput2" class="form-label">開催日時</label>
              <div class="row">
                <div class="col col-12 col-md-5 mb-1">
                  <input
                    type="date"
                    class="form-control options-input"
                    placeholder="yyyy/mm/dd"
                    id="optionsFormInput2"
                    aria-label="開催日"
                    v-model="fileInfo.meeting_date"
                  />
                </div>
                <div class="col col-md-3">
                  <input
                    type="time"
                    class="form-control options-input"
                    placeholder="--:--"
                    aria-label="開始"
                    v-model="fileInfo.meeting_start_time"
                  />
                </div>
                <div class="col col-1 text-center time-divider">〜</div>
                <div class="col col-md-3">
                  <input
                    type="time"
                    class="form-control options-input"
                    placeholder="--:--"
                    aria-label="終了"
                    v-model="fileInfo.meeting_end_time"
                  />
                </div>
              </div>
            </div>
            <div class="mb-3">
              <label for="optionsFormInput3" class="form-label">参加者</label>
              <input
                type="text"
                class="form-control options-input"
                id="optionsFormInput3"
                placeholder="例）テキスタA、テキスタB"
                v-model="fileInfo.meeting_participants"
              />
              <span v-if="errors.inputParticipants" class="text-danger">{{
                errors.inputParticipants
              }}</span>
            </div>
            <div class="mb-3">
              <label for="optionsFormInput4" class="form-label">場所</label>
              <input
                type="text"
                class="form-control options-input"
                id="optionsFormInput4"
                placeholder="例）会議室A"
                v-model="fileInfo.meeting_location"
              />
              <span v-if="errors.inputLocation" class="text-danger">{{
                errors.inputLocation
              }}</span>
            </div>
            <div class="mb-3">
              <label for="exampleFormControlTextarea1" class="form-label"
                >目的</label
              >
              <textarea
                class="form-control options-input"
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder="例）売り上げと目標の確認"
                v-model="fileInfo.meeting_purpose"
              ></textarea>
              <span v-if="errors.inputPurpose" class="text-danger">{{
                errors.inputPurpose
              }}</span>
            </div>
            <button
              :disabled="
                errors.inputMeetingName ||
                errors.inputParticipants ||
                errors.inputLocation ||
                errors.inputPurpose
              "
              class="btn btn-block w-100 btn-primary"
              @click="createNewTranscribeFile"
            >
              文字起こし結果を保存
            </button>
            <a v-if="onMobileDevice" type="button" class="text-secondary w-100 mt-2 text-center text-decoration-underline" @click="toggleSection()">文字起こし結果を編集</a>
          </div>
        </div>
        <div id="transcribeSection" class="transcribe-section">
          <div class="transcribe-result">
            <div class="transcribe-header d-flex justify-content-between">
              <div>
                文字起こし<span class="d-md-none">({{ min }}:{{ sec }})</span><br />
                <span class="sub-text">収録言語：{{ recordingLanguage }}</span>
              </div>
            </div>
            <div class="transcribe-content" id="transcribeContent">
              <div
                class="transcribe-block"
                v-for="(block, index) in scriptWithTime"
                :key="index"
              >
                <div
                  class="
                    block-detail
                    d-flex
                    align-items-center
                    justify-content-between
                  "
                >
                  <div class="d-flex mb-1">
                    <span class="timestamp p-2">{{ block[0] }}</span>
                    <input
                      class="form-control participant border-0"
                      v-model="block[1]"
                    />
                  </div>
                </div>
                <textarea
                  class="block-content form-control border-0"
                  v-model="block[2]"
                  style="height: auto"
                ></textarea>
              </div>
            </div>
            <div
              class="
                transcribe-media
                d-flex
                justify-content-between
                align-items-center
                px-2
              "
            >
              <div class="spacing realtime">
                <a 
                v-if="onMobileDevice" 
                type="button" 
                class="btn btn-sm btn-outline-danger rounded-circle stop-btn" 
                @click="toggleSection()">
                  <span class="material-symbols-outlined icon-fill stop-icon">stop</span>
                </a>
              </div>
              <div
                class="
                  media-buttons
                  d-flex
                  justify-content-evenly
                  align-items-center
                  realtime
                "
              >
                <div v-if="isRecording" class="play-btn">
                  <a
                    type="button"
                    class="material-symbols-outlined icon-fill"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="一時停止"
                    @click="toggleRecording"
                    :class="{ disabled: !isAudioDeviceConnectable }"
                    :disabled="!isAudioDeviceConnectable"
                  >
                    pause_circle
                  </a>
                </div>
                <div v-else class="resume-btn">
                  <a
                    type="button"
                    class="btn btn-sm btn-primary rounded-circle resume-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="再開"
                    @click="toggleRecording"
                    :class="{ disabled: !isAudioDeviceConnectable }"
                    :disabled="!isAudioDeviceConnectable"
                  >
                    <span class="material-symbols-outlined icon-fill">mic</span>
                  </a>
                </div>
              </div>
              <div class="recording-info d-flex">
                <span class="material-symbols-outlined"> mic </span>
                <div class="audio-wave mx-1">
                  <AVMedia
                    :media="audioStream"
                    :canv-height="20"
                    :canv-width="50"
                    :line-color="'#278bff'"
                    :line-width="2"
                    :frequ-lnum="10"
                    type="frequ"
                  ></AVMedia>
                </div>
                <span class="timer" id="transcribeTimer"
                  >{{ min }}:{{ sec }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, inject } from "vue";
import axios from "@/axios";
import firebase from "firebase/app";
import toggleFunctions from "../mixin/toggleFunctions";
import Authority from "@/components/mixin/authority.js";
import UsageTime from "../mixin/usageTime";
import * as yup from "yup";
import { useForm, useField } from "vee-validate";
import io from "socket.io-client";

export default {
  name: "Record",
  mixins: [toggleFunctions, Authority, UsageTime],
  components: {},
  setup() {
    const loadingState = inject("loading");

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    const schema = yup.object({
      inputMeetingName: yup
        .string()
        .required("会議タイトルは必須です")
        .max(50, "50文字以内で設定してください。"),
      inputParticipants: yup
        .string()
        .max(100, "100文字以内で設定してください。")
        .nullable(),
      inputLocation: yup
        .string()
        .max(50, "50文字以内で設定してください。")
        .nullable(),
      inputPurpose: yup
        .string()
        .max(100, "100文字以内で設定してください。")
        .nullable(),
    });

    const { validate, errors } = useForm({
      validationSchema: schema,
    });

    const { value: inputMeetingName } = useField("inputMeetingName");
    const { value: inputParticipants } = useField("inputParticipants");
    const { value: inputLocation } = useField("inputLocation");
    const { value: inputPurpose } = useField("inputPurpose");

    return {
      showLoading,
      hideLoading,
      validate,
      errors,
      inputMeetingName,
      inputParticipants,
      inputLocation,
      inputPurpose,
    };
  },
  data() {
    return {
      language: "ja-JP",
      fileInfo: {},
      isRecording: false,
      speech: null,
      speechCount: 0,
      timerId: null,
      GCPtimerId: null,
      startTime: Date.now(),
      elapsedTime: 0,
      scriptWithTime: [],
      recognizingText: "",
      recordedChunks: [],
      mediaRecorder: null,
      min: "00",
      sec: "00",
      recordMode: "",
      isGCPRecord: false,
      whichAPI: 0,
      speechFinished: false,
      destroyed: false,
      socket_ingestor: null,
      socket_reviewer: null,
      fileName: "",
      audioStream: null,
      audioBlob: null,
      audioContext: null,
      ingestorIP: process.env.VUE_APP_API_ENDPOINT,
      reviewerIP: process.env.VUE_APP_API_ENDPOINT,
      userIdToken: "",
      displayStream: null,
      userStream: null,
      streamDestination: null,
      isAudioDeviceConnectable: true,
      remainingTime: 0,
      onMobileDevice: false,
      pausedTime: 0,

      // ユーザー情報
      user_id: firebase.auth().currentUser.uid,
    };
  },
  async created() {
    this.userIdToken = await firebase.auth().currentUser.getIdToken();
    this.recordMode = this.$route.query.recordMode;
    this.isGCPRecord = this.$route.query.isGCPRecord;
    this.language = this.$route.query.language;
    this.showLoading();
    if (!navigator.mediaDevices) {
      this.isAudioDeviceConnectable = false;
      alert("お使いのデバイスは録音に対応していません");
    }
    this.updateRemaingUsageTime();
    this.startTranscription();
    this.hideLoading();
  },
  computed: {
    recordingLanguage() {
      const languageMap = {
        "ja-JP": "日本語",
        "en-US": "英語",
        "zh-CN": "中国語",
        "zh-HK": "香港",
        "zh-TW": "台湾",
        "ko-KR": "韓国語",
        "hi-IN": "ヒンディー語",
        "es-ES": "スペイン語",
        "ru-RU": "ロシア語",
        "pt-BR": "ポルトガル語",
        "fr-FR": "フランス語",
        "de-DE": "ドイツ語",
      };

      return languageMap[this.language] || "--";
    },
  },
  methods: {
    checkScreenWidth() {
      this.onMobileDevice = window.innerWidth < 839;
    },
    // ユーザートークンの取得
    async renewUserIdToken() {
      this.userIdToken = await firebase.auth().currentUser.getIdToken();
    },
    // 残り利用時間のチェック
    updateRemaingUsageTime() {
      if (this.recordMode == "screen" || this.isGCPRecord == 1) {
        this.GCPtimerId = setInterval(() => {
          // 残り利用時間を確認
          this.getUsageTime()
            .then((response) => {
              if (response && response.time) {
                this.remainingTime = response.time;
              } else {
                // 利用可能時間が0になったら、getUsageTime()のレスポンスがnullになるため、0に設定
                this.remainingTime = 0;
              }
            })
            .finally(() => {
              // 利用可能時間が0になった場合、録音を停止
              if (this.isRecording && this.remainingTime <= 0) {
                this.pauseRecording();
                // 時間計算停止するため、不要ソケットの接続を終了
                if (this.socket_ingestor) {
                  this.socket_ingestor.removeAllListeners();
                  this.socket_ingestor.disconnect();
                }
                if (this.socket_reviewer) {
                  this.socket_reviewer.removeAllListeners();
                  this.socket_reviewer.disconnect();
                }
                alert(
                  "利用可能時間が0になりました。高精度音声認識時間を追加したい場合は、お問い合わせください。"
                );
              }
            });
        }, 1000);
      }
    },
    startTranscription() {
      this.isRecording = true;
      if (this.recordMode == "micro") {
        if (this.isGCPRecord == 1) {
          // GCPの音声認識APIを利用する場合
          this.gcpRecord();
        } else {
          // Web Speech APIを利用する場合
          this.webSpeechRecord();
        }
      } else {
        this.dualRecord();
      }
      // 開始日時の取得
      const currentTime = new Date();
      this.fileInfo.meeting_date =
        currentTime.getFullYear() +
        "-" +
        (currentTime.getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        currentTime.getDate().toString().padStart(2, "0");
      this.fileInfo.meeting_start_time =
        currentTime.getHours().toString().padStart(2, "0") +
        ":" +
        currentTime.getMinutes().toString().padStart(2, "0");
    },
    startMicrophones() {
      this.audioStream = this.mediaRecorder.stream;
    },
    pauseMicrophones() {
      if (this.audioStream) {
        this.audioStream = null;
      }
    },
    async stopRecorder() {
      this.isAudioDeviceConnectable = false;
      // 各音声認識がある場合は終了する
      if (this.displayStream) {
        this.displayStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (this.userStream) {
        this.userStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
      if (this.speech) {
        this.speech.stop();
        this.speech = null;
      }
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.mediaRecorder.stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    },
    async dualRecord() {
      // 音声データを、GCP内のreviewer.pyから受け取る。
      this.socket_reviewer = io(this.reviewerIP, {
        transports: ["websocket"],
        path: "/reviewer/socket.io/",
      });
      this.socket_reviewer.on("connect", () => {
        this.socket_reviewer.emit("create_instance", this.userIdToken);
      });

      // 新ストリーム作成
      try {
        const dualContext = new AudioContext();
        this.displayStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true,
        });
        this.userStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });

        // 画像データーを取得しない様にする
        this.displayStream.getVideoTracks()[0].stop();
        this.displayStream.removeTrack(this.displayStream.getVideoTracks()[0]);

        // チャンネルを結語
        let outputChannel = dualContext.createMediaStreamSource(
          this.displayStream
        );
        let inputChannel = dualContext.createMediaStreamSource(this.userStream);
        this.streamDestination = dualContext.createMediaStreamDestination();
        outputChannel.connect(this.streamDestination);
        inputChannel.connect(this.streamDestination);
        let stream = this.streamDestination.stream;

        // 録音開始
        this.mediaRecorder = new MediaRecorder(stream, {
          mimeType: "video/webm;codecs=vp9",
        });
        this.mediaRecorder.ondataavailable = (e) => {
          if (e.data.size > 0) {
            this.recordedChunks.push(e.data);
          }
        };
        this.mediaRecorder.onstop = () => {
          const blob = new Blob(this.recordedChunks);
          this.audioBlob = blob;
        };
        this.mediaRecorder.start();
        this.audioStream = stream;

        // タイマー開始
        this.startTime = Date.now();
        this.countUp();
        this.speechCount += 1;
        this.outputTranscription();
        this.inputTranscription();
      } catch {
        alert("録音機能が利用できません。オーディオデバイスを確認してください");
        this.isAudioDeviceConnectable = false;
        this.$router.push({ path: "/" });
      }
    },
    async outputTranscription() {
      // 出力端末の音声を文字起こしをする
      this.audioContext = new AudioContext();
      let displayAudioTrack = this.displayStream.getAudioTracks()[0];
      let input = this.audioContext.createMediaStreamSource(this.displayStream);
      let processor = this.audioContext.createScriptProcessor(1024, 1, 1);
      input.connect(processor);
      processor.connect(this.audioContext.destination);
      this.socket_ingestor = io(this.ingestorIP, {
        transports: ["websocket"],
        path: "/ingestor/socket.io/",
      });
      this.socket_ingestor.on("connect", () => {
        this.socket_ingestor.emit(
          "create_instance",
          this.userIdToken,
          this.language
        );
      });
      processor.onaudioprocess = (e) => {
        if (this.speechCount % 2 == 1) {
          let voice = e.inputBuffer.getChannelData(0);
          this.socket_ingestor.emit("data", {
            data: voice.buffer,
            user_id: this.user_id,
          });
        }
      };

      // 文字起こし実行中ならば
      if (this.speechCount % 2 == 1) {
        this.recognizingText = "";
        let re = new RegExp("^\\[.+\\]");
        this.socket_reviewer.on("transcript" + this.user_id, (msg, cb) => {
          this.socket_reviewer.send("data_recieve");
          if (msg.length >= 7 && msg.substr(0, 7) == "[Final]") {
            let transcript = msg.substr(7, msg.length);
            var participant = "会議参加者";
            if (this.isRecording) {
              this.scriptWithTime.push([
                this.min + ":" + this.sec,
                participant,
                transcript,
              ]);
            }
            this.recognizingText = "";
            this.scrollToBottom();
          } else {
            this.recognizingText = msg;
            this.scrollToBottom();
          }
        });
      }

      // タブ共有が停止すれば
      displayAudioTrack.onended = () => {
        this.pauseRecording();
        this.stopRecorder();
      };
    },
    inputTranscription() {
      // 自己の音声を文字起こしをする
      this.speech = new webkitSpeechRecognition();
      this.speech.lang = this.language;
      this.speech.interimResults = true;
      this.speech.start();
      let participant = "自分";
      if (
        firebase.auth().currentUser.displayName &&
        firebase.auth().currentUser.displayName !== ""
      ) {
        participant = firebase.auth().currentUser.displayName;
      }
      this.speech.onresult = (e) => {
        // 文字起こし実行中ならば
        if (this.speechCount % 2) {
          var results = e.results;
          this.recognizingText = "";
          for (var i = e.resultIndex; i < results.length; i++) {
            // 息継ぎ
            if (results[i].isFinal) {
              if (this.isRecording) {
                this.scriptWithTime.push([
                  this.min + ":" + this.sec,
                  participant,
                  results[i][0].transcript,
                ]);
              }
              this.recognizingText = "";
              this.scrollToBottom();
              // 発声中
            } else {
              this.recognizingText += results[i][0].transcript;
            }
          }
        }
      };
      // ちょっと止まるとonendが発火して文字起こしが止まるので再開させる
      this.speech.onend = () => {
        // 終了していたら再開させない
        if (this.speechCount % 2 && this.speech != null) {
          this.speech.start();
        }
      };
    },
    gcpRecord() {
      // 音声データを、GCP内のreviewer.pyから受け取る。
      this.socket_reviewer = io(this.reviewerIP, {
        transports: ["websocket"],
        path: "/reviewer/socket.io/",
      });
      this.socket_reviewer.on("connect", () => {
        this.socket_reviewer.emit("create_instance", this.userIdToken);
      });

      // 録音開始
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          // 音声データを録音
          this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: "video/webm;codecs=vp9",
          });
          this.mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              this.recordedChunks.push(e.data);
            }
          };
          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks);
            this.audioBlob = blob;
          };
          this.mediaRecorder.start();
          this.audioStream = stream;

          // AudioContextで音声を取得
          this.audioContext = new AudioContext();
          let input = this.audioContext.createMediaStreamSource(stream);
          let processor = this.audioContext.createScriptProcessor(1024, 1, 1);
          input.connect(processor);
          processor.connect(this.audioContext.destination);
          // 音声データをingestor.pyへ送る。
          this.socket_ingestor = io(this.ingestorIP, {
            transports: ["websocket"],
            path: "/ingestor/socket.io/",
          });
          this.socket_ingestor.on("connect", () => {
            this.socket_ingestor.emit(
              "create_instance",
              this.userIdToken,
              this.language
            );
          });
          processor.onaudioprocess = (e) => {
            // 議事録中だった場合、音声データを送る
            if (this.speechCount % 2 == 1) {
              let voice = e.inputBuffer.getChannelData(0);
              this.socket_ingestor.emit("data", {
                data: voice.buffer,
                user_id: this.user_id,
              });
            }
          };
        })
        .catch(() => {
          alert("録音機能が利用できません。文字起こしは行われます。");
          this.isAudioDeviceConnectable = false;
          this.$router.push({ path: "/" });
        });
      // タイマー開始
      this.startTime = Date.now();
      this.countUp();
      this.speechCount += 1;
      // 文字起こし実行中ならば
      if (this.speechCount % 2 == 1) {
        this.recognizingText = "";
        let re = new RegExp("^\\[.+\\]");

        this.socket_reviewer.on("transcript" + this.user_id, (msg, cb) => {
          this.socket_reviewer.send("data_recieve");
          if (msg.length >= 7 && msg.substr(0, 7) == "[Final]") {
            let transcript = msg.substr(7, msg.length);
            var participant = "会議参加者";
            if (this.isRecording) {
              this.scriptWithTime.push([
                this.min + ":" + this.sec,
                participant,
                transcript,
              ]);
            }
            this.recognizingText = "";
            this.scrollToBottom();
          } else {
            this.recognizingText = msg;
            this.scrollToBottom();
          }
        });
      }
    },
    webSpeechRecord() {
      // マイクの録音を開始
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream, {
            mimeType: "audio/webm",
          });
          this.mediaRecorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
              this.recordedChunks.push(e.data);
            }
          };
          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.recordedChunks);
            this.audioBlob = blob;
          };
          this.mediaRecorder.start();
          this.audioStream = stream;
        })
        .catch(() => {
          alert("録音機能が利用できません。文字起こしは行われます。");
          this.isAudioDeviceConnectable = false;
          this.$router.push({ path: "/" });
        });

      // 録音時間のカウントアップ
      this.startTime = Date.now();
      this.countUp();
      this.speechCount += 1;

      // 音声認識の開始
      this.speech = new webkitSpeechRecognition();
      this.speech.lang = this.language;
      this.speech.interimResults = true;
      this.speech.start();

      // 音声認識の結果を取得
      this.speech.onresult = (e) => {
        if (this.speechCount % 2) {
          var results = e.results;
          this.recognizingText = "";
          for (var i = e.resultIndex; i < results.length; i++) {
            if (results[i].isFinal) {
              var participant = "会議参加者";
              if (this.isRecording) {
                this.scriptWithTime.push([
                  this.min + ":" + this.sec,
                  participant,
                  results[i][0].transcript,
                ]);
              }
              this.recognizingText = "";
              this.scrollToBottom();
            } else {
              this.recognizingText += results[i][0].transcript;
            }
          }
        }
      };

      // 音声認識のエラー処理
      this.speech.onend = () => {
        if (this.speechCount % 2 && this.speech != null) {
          this.speech.start();
        }
      };
    },
    countUp() {
      this.timerId = setInterval(() => {
        if (this.isRecording) {
          this.elapsedTime = Date.now() - this.startTime + this.pausedTime;
          this.min = ("0" + Math.floor(this.elapsedTime / 60000)).slice(-2);
          this.sec = ("0" + Math.floor((this.elapsedTime % 60000) / 1000)).slice(-2);
        }
      }, 1000);
    },
    scrollToBottom() {
      // 入力を選択中の以外場合はスクロールを一番下にする
      this.$nextTick(() => {
        const container = this.$el.querySelector("#transcribeContent");
        const activeElement = document.activeElement;
        if (
          container &&
          activeElement.tagName !== "INPUT" &&
          activeElement.tagName !== "TEXTAREA"
        ) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: "smooth",
          });
        }
      });
    },
    toggleRecording() {
      if (this.isRecording) {
        this.pauseRecording();
      } else {
        this.resumeRecording();
      }
      this.clearToolTips();
    },
    pauseRecording() {
      this.mediaRecorder.pause();
      this.pauseMicrophones();
      this.isRecording = false;
      this.pausedTime += Date.now() - this.startTime;
      const currentTime = new Date();
      this.fileInfo.meeting_end_time =
        currentTime.getHours().toString().padStart(2, "0") +
        ":" +
        currentTime.getMinutes().toString().padStart(2, "0");
    },
    resumeRecording() {
      this.mediaRecorder.resume();
      this.startMicrophones();
      this.isRecording = true;
      this.startTime = Date.now();
    },
    clearToolTips() {
      // ページ遷移の時、表示されているツールチップをクリア
      const tooltips = document.querySelectorAll(".tooltip");
      const popovers = document.querySelectorAll(".popover");

      tooltips.forEach((tooltip) => tooltip.remove());
      popovers.forEach((popover) => popover.remove());
    },
    clearTimerInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
      }
      if (this.GCPtimerId) {
        clearInterval(this.GCPtimerId);
      }
    },
    async createNewTranscribeFile() {
      // チェックの為、録音を停止
      if (this.isRecording) {
        this.pauseRecording();
      }
      // ソケットの接続を終了
      if (this.socket_ingestor !== null) {
        this.socket_ingestor.disconnect();
      }
      if (this.socket_reviewer !== null) {
        this.socket_reviewer.disconnect();
      }
      this.clearTimerInterval();
      // 必須チェック
      if (this.fileInfo.meeting_name === "" || !this.fileInfo.meeting_name) {
        this.$toasted.show("会議タイトルを入力ください。", {
          className: "bg-primary rounded",
          icon: "fa-exclamation-circle",
        });
        return;
      }
      this.showLoading();

      // 音声認識を終了
      this.stopRecorder();

      // テキストデータを作成
      const times = ["00:00", ...this.scriptWithTime.map(item => item[0])];
      const speakers = this.scriptWithTime.map(item => item[1]);
      const contents = this.scriptWithTime.map(item => item[2]);
      
      const content = contents.map((contentText, index) => {
        return `[${times[index]}] ${speakers[index]}: ${contentText}\n`;
      }).join("");

      // ファイル情報を保存
      const params = {
        file_info: {
          meeting_name: this.fileInfo.meeting_name,
          meeting_date: this.fileInfo.meeting_date,
          meeting_start_time: this.fileInfo.meeting_start_time,
          meeting_end_time: this.fileInfo.meeting_end_time,
          meeting_participants: this.fileInfo.meeting_participants,
          meeting_location: this.fileInfo.meeting_location,
          meeting_purpose: this.fileInfo.meeting_purpose,
          recorded_language: this.language,
          duration: parseInt(this.sec, 10) + parseInt(this.min, 10) * 60,
        },
        text_content: content,
      };
      await axios
        .post(
          process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/create_new_transcribe_file",
          params
        )
        .then((response) => {
          // 音声ファイルをcloud storageに保存
          if (response.data.status) {
            let storage = firebase
              .app()
              .storage(process.env.VUE_APP_STORAGE_PATH);
            let dataRef = storage.ref(response.data.file.audio_path);
            let uploadTask = dataRef.put(this.audioBlob);
            uploadTask.on(
              "state_changed",
              (snapshot) => {},
              (error) => {
                alert("音声ファイルのアップロードが失敗しました。");
                this.hideLoading();
              },
              () => {
                this.$router.push({
                  path: "/detail",
                  query: { fileId: response.data.file.file_id },
                });
              }
            );
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    checkScreenWidth() {
      const optionsSection = document.querySelector(".options-section");
      const transcribeSection = document.querySelector(".transcribe-section");
      if(window.innerWidth < 839) {
        this.onMobileDevice = true;
      } else {
        this.onMobileDevice = false;
        if(optionsSection.classList.contains("show")) {
          optionsSection.classList.remove("show");
          transcribeSection.classList.remove("d-none");
          optionsSection.style.width = "40%";
        }
      };
    },
    toggleSection() {
      const optionsSection = document.querySelector(".options-section");
      const transcribeSection = document.querySelector(".transcribe-section");
      if (this.onMobileDevice && !optionsSection.classList.contains("show")) {
        if(this.isRecording) {
          this.pauseRecording();
        }
        optionsSection.classList.add("show");
        transcribeSection.classList.add("d-none");
        optionsSection.style.width = "100%";
      } else {
        optionsSection.classList.remove("show");
        transcribeSection.classList.remove("d-none");
        optionsSection.style.width = "40%";
      }
    }
  },
  watch: {
    "fileInfo.meeting_name": function (newVal) {
      this.inputMeetingName = newVal;
    },
    "fileInfo.meeting_participants": function (newVal) {
      this.inputParticipants = newVal;
    },
    "fileInfo.meeting_location": function (newVal) {
      this.inputLocation = newVal;
    },
    "fileInfo.meeting_purpose": function (newVal) {
      this.inputPurpose = newVal;
    },
    // ページにアクセス後、録音中のトークンを取得
    async min(newVal) {
      // 高精度音声認識利用する場合のみ、トークンを取得
      if (newVal && (this.recordMode == "screen" || this.isGCPRecord == 1)) {
        await this.renewUserIdToken();
      }
    },
  },
  mounted() {
    this.checkScreenWidth();
    window.addEventListener('resize', this.checkScreenWidth);
  },
  beforeRouteLeave(to, from, next) {
    // 録音中の時にページ遷移する場合、確認コンファームを表示する
    if (this.isRecording && this.isAudioDeviceConnectable) {
      const answer = window.confirm("収録を保存せず、ページを離れますか？");
      if (answer) {
        this.stopRecorder().then(() => {
          next();
        });
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkScreenWidth);
    // ファイル作成後、ローディングを非表示する（作成成功の場合）
    this.clearTimerInterval();
    this.hideLoading();

    // 音声認識を終了
    this.stopRecorder();

    // ソケットの接続を終了
    if (this.socket_ingestor) {
      this.socket_ingestor.removeAllListeners();
      this.socket_ingestor.disconnect();
    }
    if (this.socket_reviewer) {
      this.socket_reviewer.removeAllListeners();
      this.socket_reviewer.disconnect();
    }
  },
};
</script>

<style scoped>
.page-main,
.result-transcribe {
  padding: 0 20px;
}

.options-section {
  width: 40%;
  padding-top: 15px;
  padding-right: 20px;
  padding-left: 20px;
  border: solid 1px var(--actived);
  border-radius: 8px 0 0 0;
  border-bottom: none;
}

.transcribe-section {
  width: 60%;
  padding-top: 15px;
  border: solid 1px var(--actived);
  border-radius: 0 8px 0 0;
  border-bottom: none;
  border-left: none;
  height: calc(100 * var(--screen-height) - 160px);
}

.dropdown-item .material-symbols-outlined {
  vertical-align: -5px;
  font-size: 1.5rem;
}

.options-input::placeholder {
  font-size: 14px;
  color: var(--sub-text);
}

.time-divider {
  line-height: 38px;
}

.transcribe-header {
  padding: 5px 15px 15px 15px;
  border-bottom: solid 1px var(--actived);
}

.transcribe-header .sub-text {
  font-size: 14px;
  color: var(--sub-text);
}

.transcribe-media {
  height: 60px;
  align-items: center;
  padding-top: 10px;
}

.transcribe-media .material-symbols-outlined:not(.stop-icon) {
  color: var(--primary);
  font-size: 35px;
}

.transcribe-media .play-btn .material-symbols-outlined {
  font-size: 50px;
}

.transcribe-media .recording-info .material-symbols-outlined {
  font-size: 24px;
}

.transcribe-media .recording-info .timer {
  color: var(--sub-text);
  font-size: 12px;
  line-height: 25px;
}

.transcribe-media .media-buttons {
  width: 60%;
}

.transcribe-media .recording-info {
  border: solid 1px var(--actived);
  border-radius: 3px;
  padding: 5px;
}

.transcribe-media .spacing {
  width: 100px;
}

.audio-download-btn {
  width: 35px;
  height: 35px;
}

.wave {
  fill: none;
  stroke: var(--primary);
  stroke-width: 1;
  stroke-linecap: round;
  transition: d 0.2s ease-in-out;
}

.transcribe-content {
  height: calc(100 * var(--screen-height) - 320px);
  border-bottom: solid 1px var(--actived);
  overflow-y: auto;
}

.transcribe-block {
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  transition: background-color 0.5s ease;
}

.transcribe-block .block-detail {
  font-size: 12px;
}

.transcribe-block .block-detail .timestamp {
  margin-top: 3px;
  font-size: 14px;
  margin-right: 10px;
  color: var(--sub-text);
}

.transcribe-block .block-detail .participant {
  font-weight: 600;
}

.transcribe-block .block-content {
  font-size: 14px;
  width: 100%;
  resize: none;
}

.edit-btn {
  opacity: 0;
  visibility: hidden;
}

.transcribe-block:hover {
  background-color: var(--actived);
}

.transcribe-block:hover .edit-btn {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease 0.2s, visibility 0.3s ease 0.2s;
}

.transcribe-block.active {
  background-color: var(--sub-primary);
}

.block-detail input:disabled {
  color: var(--default-text);
}

.block-btn {
  padding: 0;
  margin: 0;
}

.block-btn .material-symbols-outlined {
  font-size: 18px;
  vertical-align: middle;
}

.transcribe-block .editing {
  background-color: var(--editing-text-background);
  border-radius: 3px;
  border: solid 1px var(--sub-primary) !important;
}

.file-info {
  height: calc(100 * var(--screen-height) - 295px);
  overflow-y: auto;
}

.file-info .form-control:disabled {
  background-color: var(--white);
  border: 0;
}

.file-info .border.form-control:disabled {
  border: 0 !important;
}

.detail-info-table {
  font-size: 14px;
}

.detail-info-table th,
.detail-info-table td {
  color: var(--secondary);
}

textarea.file-info-input,
textarea.options-input {
  resize: none;
}
.audio-progress {
  display: flex;
  align-items: center;
}
.audio-progress input[type="range"] {
  flex: 1;
  height: 1px;
}

.audio-wave {
  padding-top: 3px;
}

.resume-btn {
  display: flex;
  justify-content: center;
  width: 50px;
  height: 56px;
}

.resume-btn .resume-icon {
  margin-top: 4px;
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resume-btn .resume-icon .material-symbols-outlined {
  font-size: 28px !important;
  color: var(--white);
}
.stop-btn {
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
}
</style>
