<template>
  <div class="main d-flex">
    <MySidebar ref="sidebar"/>
    <div class="container-fluid pe-0">
      <MyHeader/>
      <button id="buttonExpand" class="side-expand-button" :class="{ 'sm': !isCollapsed }" @click="toggleSidebar">
        <span class="material-symbols-outlined"> arrow_forward_ios </span>
      </button>
      <router-view />
    </div>
    <CommonFileSearch />
    <CommonFileShare />
  </div>
</template>

<script>
import { useSidebarStore } from "@/stores"
import { storeToRefs } from 'pinia';
import MyHeader from "../modules/MyHeader.vue";
import MySidebar from "../modules/MySidebar.vue";
import CommonFileSearch from "../modules/CommonFileSearch.vue";
import CommonFileShare from "../modules/CommonFileShare.vue";

export default {
  name: "Main",
  components: {
    MyHeader,
    MySidebar,
    CommonFileSearch,
    CommonFileShare,
  },
  setup() {
    const store = useSidebarStore(); // Storeにアクセス
    const toggleSidebar = () => {
      store.toggleSidebar(); // アクションを実行
    };
    const { collapsed } = storeToRefs(store)
    const isCollapsed = collapsed
    return { toggleSidebar, isCollapsed};
  }
}
</script>
