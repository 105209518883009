import axios from "@/axios";
import "firebase/auth"
import "firebase/firestore"

export default {
  data() {
    return {
    };
  },
  methods: {
    async getUserOperationAuthority() {
      try {
        const response = await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_user_operation_authority",
          );
        if (response.data) {
          return response.data.user_operation_authority;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    // メンバー登録上限取得
    async getMaximumMembersCount() {
      try {
        const response = await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_maximum_members_count",
          );
        if (response.data) {
          return response.data.maximum_members_count;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },

    // メンバー登録数取得
    async getCurrentMembersCount() {
      try {
        const response = await axios
          .post(
            process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/get_current_members_count",
          );
        if (response.data) {
          return response.data.members_count;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
  }
}
