<template>
  <div class="main-container overflow-auto">
    <div class="page-main d-flex">
      <SettingSidebar @componentChanged="changeComponent" :currentComponent="activeComponent" />
      <component :is="activeComponent" @componentChanged="changeComponent" />
    </div>
  </div>
</template>

<script>
// modules
import SettingSidebar from "../modules/SettingSidebar.vue";
import SettingCollapseList from "../modules/SettingCollapseList.vue";
import EditProfile from "../modules/EditProfile.vue";
import EditPlan from "../modules/EditPlan.vue";
import NotificationsSetting from "../modules/NotificationsSetting.vue";
import ContractDetails from "../modules/ContractDetails.vue";
import PaymentManagement from "../modules/PaymentManagement.vue";
import MemberManagement from "../modules/MemberManagement.vue";

export default {
  name: "Setting",
  components: {
    SettingSidebar,
    SettingCollapseList,
    EditProfile,
    EditPlan,
    NotificationsSetting,
    ContractDetails,
    PaymentManagement,
    MemberManagement,
  },
  data() {
    return {
      activeComponent: 'EditProfile'
    };
  },
  methods: {
    changeComponent(componentName) {
      this.activeComponent = componentName;
    }
  }
};
</script>

<style>
.settings-list {
  width: 15%;
}

.settings-content {
  width: 85%;
}

@media screen and (max-width: 1559px) {
  .settings-list {
    width: 20%;
  }

  .settings-content {
    width: 80%;
  }
}
</style>