import { useUserNotificationInfoStore } from '@/stores/index';
import axios from "@/axios";

export function useUserNotificationInfo() {
  const userNotificationInfoStore = useUserNotificationInfoStore();

  // 通知情報取得
  const getNotifications = async(lastUpdateAt) => {
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ENDPOINT}/texta-basic-api/get_notifications`,
        { last_update_at: lastUpdateAt }
      );

      const { status, notifications_count, notifications, lastest_time_stamp, is_update } = response.data;

      if (status) {
        if ((!notifications || notifications.length === 0) && notifications_count === 0) {
          // 通知が存在しない場合
          userNotificationInfoStore.setNotificationCount(0);
          userNotificationInfoStore.setNotificationInfo([]);
        } else if(is_update || notifications.length > 0) {
          // 通知が更新されている場合
          const count = Math.min(notifications_count, 99);
          userNotificationInfoStore.setNotificationCount(count);
          userNotificationInfoStore.setNotificationInfo(notifications);
        }
        if(lastest_time_stamp) {
          userNotificationInfoStore.setNotificationLastUpdated(new Date(lastest_time_stamp));
        }
      }
    } catch (error) {
    }
  };

  return {
    getNotifications,
  };
}