<template>
  <div class="search-box d-flex">
    <span class="material-symbols-outlined search-icon">search</span>
    <input class="form-control search-input" id="searchInput" placeholder="ファイルを検索" autocomplete="off" v-model="meetingName" @focus="meetingNameFocus" @blur="meetingNameBlur" />
    <div class="search-btn d-flex justify-content-center align-items-center" data-bs-toggle="modal"
      data-bs-target="#searchModal">
      <span class="material-symbols-outlined search-btn-icon">tune</span>
    </div>
    <div v-show="isShowSearchResult" class="quick-search-result shadow">
      <div class="result d-flex align-items-center" @click="detail(item.file_id)" v-for="item in items" :key="item.file_id">
        <div class="result-icon">
          <div v-if="item.file_type === 0" class="record-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="ファイル">
            <span class="material-symbols-outlined icon-fill">draft</span>
          </div>
          <div v-if="item.file_type === 1" class="record-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="収録したファイル">
            <span class="material-symbols-outlined icon-fill">mic</span>
          </div>
          <div v-if="item.file_type === 2" class="record-icon me-1" data-bs-toggle="tooltip" data-bs-placement="bottom" title="インポートしたファイル">
            <span class="material-symbols-outlined icon-fill">upload_file</span>
          </div>
        </div>
        <div class="result-content">
          <div class="result-title d-inline-block text-truncate">{{item.meeting_name}}</div>
          <div class="result-info">
            <span>作成者：{{item.created_member_name}}</span>&nbsp;
            <span>最終更新：{{item.updated_at}} {{item.updated_member_name}}</span>
          </div>
        </div>
      </div>
      <div v-if="isShowAllResult" class="result d-flex align-items-center" @click="toSearchResults">すべて表示</div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useFileSearchStore } from '@/stores/index';
import { useFileSearch } from "../composables/fileSearch";

export default {
  name: "FileKeywordSearch",
  components: {},
  setup() {
    const meetingName = ref('');
    const isShowSearchResult = ref(false);
    const isShowAllResult = ref(false);

    const {
      items,
      pagination,
      quickSearch,
    } = useFileSearch();

    const fileSearchStore = useFileSearchStore();


    watch(meetingName, (newMeetingName) => {
      quickSearch(newMeetingName);
    });

    watch(items, (newItems) => {
      if (newItems.length > 0) {
        isShowSearchResult.value = true;
      } else {
        isShowSearchResult.value = false;
      }
    });

    watch(pagination, (newPagination) => {
      if (newPagination.total_count > 10) {
        isShowAllResult.value = true;
      } else {
        isShowAllResult.value = false;
      }
    });

    const meetingNameBlur = (event) => {
      setTimeout(() => {
        isShowSearchResult.value = false;
      }, 200);
    };

    const meetingNameFocus = (event) => {
      if (items.value.length > 0) {
        isShowSearchResult.value = true;
      }
    };

    const router = useRouter();
    const detail = (fileId) => {
      const query = {};
      query.fileId = fileId;
      router.push({ path: '/detail', query });
    };

    const toSearchResults = () => {
      fileSearchStore.setMeetingName(meetingName.value);

      router.push({
        name: 'FileManagement',
        query: {
          quickSearch: true,
          searchDate: Date.now(),  // クエリパラメータの変更で検索実行
        }
      });
    };

    return {
      meetingName,
      isShowSearchResult,
      isShowAllResult,
      items,
      pagination,
      quickSearch,
      meetingNameFocus,
      meetingNameBlur,
      detail,
      toSearchResults,
    };
  },
}
</script>