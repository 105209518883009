export function useFileStatus() {
  // 状態判定（文字起こし済み）
  const isTranscriptionCompleted = (fileType, fileTranscriptionStatus) => {
    // 移行データ、リアルタイム文字起こしは「文字起こし済み」
    if (fileType === 0 || fileType === 1) {
      return true;
    }
    // ファイル読み込みは、完了していれば「文字起こし済み」
    if (fileType === 2 && fileTranscriptionStatus === 2) {
      return true;
    }

    return false;
  };

  // 状態判定（処理中）
  const isTranscriptionProcessing = (fileType, fileTranscriptionStatus) => {
    // ファイル読み込みは、文字起こし中の場合「処理中」
    if (fileType === 2 && fileTranscriptionStatus === 1) {
      return true;
    }

    return false;
  };

  // 状態判定（エラー）
  const isTranscriptionError = (fileType, fileTranscriptionStatus) => {
    // ファイル読み込みは、エラーの場合「エラー」
    if (fileType === 2 && fileTranscriptionStatus === 3) {
      return true;
    }

    return false;
  };

  return {
    isTranscriptionCompleted,
    isTranscriptionProcessing,
    isTranscriptionError,
  };
}