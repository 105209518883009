<template>
  <div class="main-container">
    <div class="page-title">
      <h3>ホーム</h3>
    </div>
    <div class="page-main d-flex">
      <div class="left-section overflow-auto">
        <div class="system_notifications">
          <div
            v-for="(notification, index) in systemNotifications"
            :key="index"
            class="site-message site-message-primary"
            :class="{
              'site-message-primary': notification.notification_category === 0,
              'site-message-warning': notification.notification_category === 1,
              'site-message-alert': notification.notification_category === 2,
            }"
          >
            <div class="d-flex">
              <span class="material-symbols-outlined icon-fill">
                {{
                  notification.notification_category === 0
                    ? "campaign"
                    : notification.notification_category === 1
                    ? "notification_important"
                    : notification.notification_category === 2
                    ? "error"
                    : ""
                }}
              </span>
              <div class="message">
                {{ notification.notification_title }}
                <a
                  v-if="notification.notification_url"
                  :href="notification.notification_url"
                  target="_blank"
                  :class="{
                    'site-message-primary':
                      notification.notification_category === 0,
                    'site-message-warning':
                      notification.notification_category === 1,
                    'site-message-alert':
                      notification.notification_category === 2,
                  }"
                >
                  <b><u>詳細</u></b>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="record-group d-flex">
          <div class="right">
            <div class="language-selector border d-flex">
              <label for="languageList">
                <span class="material-symbols-outlined">graphic_eq</span
                >収録言語：
              </label>
              <select
                class="language-list form-select border-0"
                id="languageList"
                v-model="language"
                aria-label=".form-select example"
              >
                <option selected value="ja-JP">日本語</option>
                <option value="en-US">英語</option>
                <option value="zh-CN">中国語</option>
                <option value="zh-HK">香港</option>
                <option value="zh-TW">台湾</option>
                <option value="ko-KR">韓国語</option>
                <option value="hi-IN">ヒンディー語</option>
                <option value="es-ES">スペイン語</option>
                <option value="ru-RU">ロシア語</option>
                <option value="pt-BR">ポルトガル語</option>
                <option value="fr-FR">フランス語</option>
                <option value="de-DE">ドイツ語</option>
              </select>
            </div>
          </div>
          <div class="left d-flex">
            <a
              class="realtime-recorder btn btn-primary pt-2"
              type="button"
              id="realtimeCollapse"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
              data-bs-offset="-163,0"
              @click="checkAPI"
            >
              <span class="material-symbols-outlined icon-fill"> mic </span>
              <span class="btn-title">収録開始</span>
            </a>
            <div
              class="realtime-collapse dropdown-menu border-0"
              aria-labelledby="realtimeCollapse"
            >
              <div class="recorder">
                <div class="recorder-select">
                  <div class="micro-record">
                    <p class="method-name mb-2">音声収録</p>
                    <p class="method-description mb-1">
                      リアルタイム音声認識を開始します。
                    </p>
                    <div class="method-switch mb-1 form-check form-switch">
                      <input
                        :disabled="!planStore.isPaidPlan || remainingTime <= 0"
                        class="form-check-input"
                        type="checkbox"
                        v-model="isGCPRecordAPI"
                        id="flexSwitchCheckDefault"
                        checked
                      />
                      <label
                        class="form-check-label method-switch-label"
                        for="flexSwitchCheckDefault"
                        >補正文字起こし</label
                      >
                    </div>
                    <button
                      class="btn btn-primary record-start mb-2 w-100"
                      @click="toRealtimeTranscription('micro')"
                    >
                      <span class="material-symbols-outlined icon-fill">
                        mic
                      </span>
                      音声収録開始
                    </button>
                  </div>
                  <div class="tab-record mt-2">
                    <p class="method-name mb-2">画面収録</p>
                    <p class="method-description mb-1">
                      画面と音声を同時に収録することが出来ます
                    </p>
                    <button
                      :disabled="!planStore.isPaidPlan || remainingTime <= 0"
                      class="btn btn-primary record-start w-100"
                      @click="toRealtimeTranscription('screen')"
                    >
                      <span class="material-symbols-outlined icon-fill">
                        devices
                      </span>
                      画面収録開始
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="upload_loading"
              class="ms-2 progress file-upload-progress"
            >
              <div
                class="progress-bar"
                role="progressbar"
                :aria-valuenow="progress_rate"
                :style="{ width: progress_rate + '%' }"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <b>{{ progress_rate + "%" }}</b>
              </div>
            </div>
            <a
              v-show="!upload_loading"
              class="ms-2 file-importer btn btn-outline-primary pt-2"
              type="button"
              id="importCollapse"
              data-bs-toggle="dropdown"
              data-bs-auto-close="outside"
              aria-expanded="false"
              data-bs-offset="-465,0"
              :class="{ disabled: !planStore.isPaidPlan }"
              ref="importButton"
            >
              <span class="material-symbols-outlined"> upload </span>
              <span class="btn-title">インポート</span>
            </a>
            <div 
              v-if="!planStore.isPaidPlan || remainingTime <= 0"
              class="free-tooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="この機能を使用するには、有料プランへのアップグレードが必要です。また、利用時間が0の場合は、追加の利用時間を購入してください。"
              >
              <span class="material-symbols-outlined text-warning">
                info
              </span>
            </div>
            <div
              class="import-collapse dropdown-menu border-0"
              aria-labelledby="importCollapse"
              ref="importDropdownMenu"
            >
              <div class="uploader">
                <form class="drag-section border text-center">
                  <div
                    v-if="situation == 0"
                    @dragleave.prevent
                    @dragover.prevent
                    @drop.prevent="selectedFileTrans"
                    class="
                      h-100
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                    "
                  >
                    <span
                      class="material-symbols-outlined upload-cloud icon-fill"
                    >
                      cloud_upload
                    </span>
                    <div class="upload-title">
                      音声/動画ファイルをドロップ または
                      <label for="file-input">
                        <input
                          @change="selectedFileTrans"
                          type="file"
                          name="file"
                          class="file-input"
                          id="file-input"
                        />
                        <a type="button" class="upload-file fw-bold"
                          >ファイルを選択</a
                        >
                      </label>
                    </div>
                    <div class="upload-description mt-2 pb-3">
                      対応形式：.mp3 .wav .ogg .flac .aac .mp4 .webm .m4a<br />
                      （アップロードには時間を要します）<br />
                      ※現在、120MB以上のファイルは文字起こしに失敗する場合があります。<br />
                      ファイルを分割して文字起こししてください。
                    </div>
                  </div>
                  <div
                    v-else
                    class="
                      h-100
                      d-flex
                      flex-column
                      justify-content-center
                      align-items-center
                      uploaded-info
                    "
                  >
                    <span
                      class="
                        material-symbols-outlined
                        upload-cloud
                        text-primary
                        icon-fill
                        my-2
                      "
                    >
                      text_to_speech
                    </span>
                    <table class="table w-75 table-borderless text-start">
                      <tbody class="upload-body">
                        <tr class="upload-title text-secondary">
                          <td><b class="bg-transparent">ファイル名：</b></td>
                          <td>{{ filename }}</td>
                        </tr>
                        <tr class="upload-title text-secondary">
                          <td>
                            <b class="bg-transparent">ファイルの再生時間：</b>
                          </td>
                          <td>{{ audio_time }}秒</td>
                        </tr>
                        <tr class="upload-title text-secondary">
                          <td>
                            <b class="bg-transparent">ファイルのサイズ：</b>
                          </td>
                          <td>{{ displayFileSize }}MB</td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="mt-2">
                      <button
                        @click="uploadAudioFile"
                        type="button"
                        class="btn btn-primary"
                      >
                        <span class="material-symbols-outlined icon-fill">
                          cloud_upload
                        </span>
                        <span> 文字起こし開始</span>
                      </button>
                    </div>
                    <div class="mt-1">
                      <a @click="onReset()" type="button" class="btn">
                        <u>他のファイルを選択</u>
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <CommonFileList
          :items="items"
          :pagination="pagination"
          :sortBy="sort.sort_by"
          :sortOrder="sort.sort_order"
          :isDisplayCheckBox="false"
          :isDisplayTab="true"
          :isDisplayRowMenu="false"
          :fileTarget="fileTarget"
          :dateFilterValue="dateFilterValue"
          @search-files="searchFiles"
          @go-to-page="goToPage"
          @change-per-page="changePerPage"
          @sort="sortPage"
          @search-all-files="searchAllFiles"
          @search-my-files="searchMyFiles"
          @search-shared-files="searchSharedFiles"
          @clear-date-filter="clearDateFilter"
        />
      </div>
      <div class="right-section">
        <div class="calendar">
          <VDatePicker v-model="dateFilter" mode="date" />
        </div>
        <div class="usage-info border">
          <div class="usage-header d-flex justify-content-between">
            <div class="usage-title">利用状況</div>
            <div class="usage-plan">
              <span class="plan-badge">{{ planStore.planName }}</span>
            </div>
          </div>
          <div v-if="planStore.isCardPayment" class="usage-description">
            {{
              planStore.isPaidPlan
                ? "利用時間は " + planStore.subscriptionPeriodEndDateTime + " に追加されます。"
                : ""
            }}
          </div>
          <div class="using-info">
            <div class="usage-time d-flex justify-content-between mb-2 mt-1">
              <div class="using-label">高精度音声認識</div>
              <div class="using-remaind">
                <b>{{ displayUsageTime }} </b>
              </div>
            </div>
            <div
              v-if="planStore.isEnterprisePlan"
              class="usage-member d-flex justify-content-between"
            >
              <div class="using-label">メンバー</div>
              <div class="using-remaind">
                <b>{{ membersCount }}</b>
                <span class="material-symbols-outlined icon-fill person-icon"
                  >person</span
                >
                /
                <b>{{ planStore.maximumMembersCount }} </b>
                <span class="material-symbols-outlined icon-fill">groups</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="record-tool-sm">
      <a class="record-btn-sm btn btn-primary rounded-circle" @click="toggleRecordToolSm()">
        <span class="material-symbols-outlined icon-fill">
          note_stack_add
        </span>
      </a>
      <div id="recordToolSm" class="record-tool-sm-collapse">
        <div class="w-100 d-flex justify-content-center mt-2 mb-4">
          <a class="collapse-close" @click="toggleRecordToolSm()"></a>
        </div>
        <div class="ms-2">
          <h4 class="collapse-title mb-3 fw-bold">音声・ファイルを記録</h4>
          <div class="language-selector d-flex">
            <label for="languageListSm">
              <span class="material-symbols-outlined">graphic_eq</span>収録言語：
            </label>
            <select class="language-list form-select border-0" id="languageListSm"
              aria-label=".form-select example"
              v-model="language">
              <option selected value="ja-JP">日本語</option>
              <option value="en-US">英語</option>
              <option value="zh-CN">中国語</option>
              <option value="zh-HK">香</option>
              <option value="zh-TW">台湾</option>
              <option value="ko-KR">韓国語</option>
              <option value="hi-IN">ヒンディー語</option>
              <option value="es-ES">スペイン語</option>
              <option value="ru-RU">ロシア語</option>
              <option value="pt-BR">ポルトガル語</option>
              <option value="fr-FR">フランス語</option>
              <option value="de-DE">ドイツ語</option>
            </select>
          </div>
          <div class="record-function-sm d-flex align-items-center">
            <div v-if="situation == 0"
                @dragleave.prevent
                @dragover.prevent
                @drop.prevent="selectedFileTrans">
              <label for="file-input">
                <input
                  @change="selectedFileTrans"
                  type="file"
                  name="file"
                  class="file-input"
                  id="file-input"
                />
                <a type="button" class="text-primary">
                  <span class="material-symbols-outlined"> upload </span>
                  <span class="btn-title">インポート</span>
                </a>
              </label>
            </div>
            <div v-else-if="progress_rate !== ''">
              <div class="progress file-upload-progress sm">
                <div
                  class="progress-bar"
                  role="progressbar"
                  :aria-valuenow="progress_rate"
                  :style="{ width: progress_rate + '%' }"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  <b>{{ progress_rate + "%" }}</b>
                </div>
              </div>
            </div>
            <div v-else>
              <a
                @click="uploadAudioFile"
                type="button"
                class="text-primary me-2"
              >
                <span class="material-symbols-outlined icon-fill">
                  cloud_upload
                </span>
                <span> 文字起こし開始</span>
              </a>
              （
              <a @click="onReset()" type="button" class="text-secondary">
                <u>他のファイルを選択</u>
              </a>
              ）
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, inject } from "vue";
import axios from "@/axios";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import CommonFileList from "../modules/CommonFileList.vue";
import toggleFunctions from "../mixin/toggleFunctions";
import Authority from "@/components/mixin/authority.js";
import StripeFunc from "../mixin/StripeFunc";
import UsageTime from "../mixin/usageTime";
import { useFileSearch } from "../composables/fileSearch";
import { usePlanStore } from '@/stores/index';

export default {
  name: "Home",
  mixins: [StripeFunc, toggleFunctions, Authority, UsageTime],
  components: {
    CommonFileList
  },
  setup() {
    const loadingState = inject("loading");

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    // ファイル一覧
    const {
      items,
      conditions,
      sort,
      pagination,
      fileTarget,
      searchFiles,
      goToPage,
      changePerPage,
      sortPage,
      searchAllFiles,
      searchMyFiles,
      searchSharedFiles,
    } = useFileSearch();

    const planStore = usePlanStore();

    return {
      showLoading,
      hideLoading,
      items,
      conditions,
      sort,
      pagination,
      fileTarget,
      searchFiles,
      goToPage,
      changePerPage,
      sortPage,
      searchAllFiles,
      searchMyFiles,
      searchSharedFiles,
      planStore,
    };
  },
  data() {
    return {
      situation: 0, // 0:none, 1:select, 2:start, 3:finish
      language: "ja-JP",
      upload_loading: false,
      file_loading: false,
      text_loading: false,
      file_downloading: false,
      script_show: false,
      filename: "",
      splited_filename: "",
      filesize: 0,
      ext: "",
      filenamePlusTime: "",
      record_situation: 0, // 0:before, 1:start, 2:finish, 3:decide
      record_count: 0,
      timerId: null,
      startTime: 0, // 開始時刻
      elapsedTime: 0, // 経過時間
      timerToadd: 0, // 経過時間保持
      record_filename: "",
      localstream: "",
      mediaRecorder: null,
      recordedChunks: [],
      blob: null,
      remainingTime: null,

      // people: "1",
      // summary: [],
      script: "",
      uploadFile: "",
      audio_time: "--- ",
      progress_rate: "",
      user_id: firebase.auth().currentUser.uid,
      user_name: firebase.auth().currentUser.displayName,
      currentTab: "all",
      isDataFilteredByDate: false,
      dateFilterValue: null,
      dateFilter: ref(new Date()),
      isGCPRecordAPI: false,
      members: [],
      modalSelectedOption: [],
      corpMstIdByFirebaseUserid: [],
      corpId: "",
      isDropDownOpen: null,
      membersCount: "",
      systemNotifications: [],
      onMobileMenu: false,
    };
  },
  async created() {
    this.showLoading();
    await this.getInitialInfo();
    this.userIdToken = await firebase.auth().currentUser.getIdToken();

    if (!navigator.mediaDevices) {
      alert("mediaDevices is unavailable");
      // 「お使いのデバイスは録音に対応していません」と表示
    }
    // 利用時間の確認
    const userState = await this.getUsageTime();
    this.remainingTime = parseInt(userState.time);

    // 現在文字起こし中のファイルの時間取得
    const proceeding_time = await this.getProceedingTime(this.userIdToken);
    this.remainingTime = this.remainingTime - proceeding_time;

    // ファイル一覧
    await this.searchFiles(false);

    this.hideLoading();
  },
  computed: {
    displayUsageTime() {
      let self = this;
      let totalSeconds = self.remainingTime;

      if(self.remainingTime == 0) {
        return "--:--"
      } else {
        let hours = Math.floor(totalSeconds / 3600);
        let minutes = Math.floor((totalSeconds % 3600) / 60);
        let seconds = totalSeconds % 60;

        let displayTime = "";

        if (hours > 0) {
          displayTime += `${hours}時間`;
        }
        if (minutes > 0) {
          displayTime += `${minutes}分`;
        }
        if (seconds > 0) {
          displayTime += `${seconds}秒`;
        }

        return displayTime.trim();
      }
    },
    displayFileSize() {
      return (this.filesize / (1024 * 1024)).toFixed(2);
    },
  },
  methods: {
    toggleRecordToolSm() {
      const recordToolSm = document.getElementById("recordToolSm");
      if (this.onMobileMenu == false) {
        recordToolSm.classList.add("show");
      } else {
        recordToolSm.classList.remove("show");
      }
      this.onMobileMenu = !this.onMobileMenu;
    },
    checkAPI() {
      if (!this.planStore.isPaidPlan || this.remainingTime <= 0) {
        this.isGCPRecordAPI = false;
      } else {
        this.isGCPRecordAPI = true;
      }
    },
    toRealtimeTranscription(recordMode) {
      // リアルタイムページへ遷移
      this.$router.push({
        name: "RealtimeRecording",
        query: {
          recordMode: recordMode,
          isGCPRecord: this.isGCPRecordAPI ? 1 : 0,
          language: this.language,
        }
      });
    },
    clearDateFilter() {
      this.isDataFilteredByDate = false;
      this.dateFilterValue = null;
      this.conditions.filter_created_at_from = null;
      this.conditions.filter_created_at_to = null;
      this.searchFiles();
    },
    changeTab(tabName) {
      this.currentTab = tabName;
    },
    selectedFileTrans: function (e) {
      e.preventDefault();
      let self = this;

      self.file_loading = true;
      //inputから来たらe.target.files、ドロップ&ドラッグから来たらe.dataTransfer.files
      let files = e.target.files ? e.target.files : e.dataTransfer.files;
      self.uploadFile = files[0];
      self.filename = files[0].name;
      self.filesize = files[0].size;
      let filename = self.filename;
      if (self.filename.length > 40) {
        alert("ファイル名が長すぎます。40字以内で記入してください。");
        self.onReset();
        return false;
      }
      let type = filename.split(".");
      let available = [
        "mp3",
        "wav",
        "ogg",
        // "flv",
        "flac",
        "aac",
        // "wma",
        // "aiff",
        "mp4",
        "webm",
        "m4a",
      ];
      self.ext = type.pop().toLowerCase();

      // 再生時間取得
      const audio = new Audio();
      const file = files[0];
      const fileURL = URL.createObjectURL(file);
      audio.src = fileURL;
      audio.controls = true;

      audio.onloadeddata = () => {
        audio.currentTime = 7 * 24 * 60 * 1000;
        audio.onseeked = () => {
          self.audio_time = Math.ceil(audio.duration);
          if (!Number.isInteger(self.audio_time)) {
            alert(
              "音声時間を検出することができません。ファイルが破損していないかご確認ください。"
            );
          } else {
            audio.onseeked = undefined;
            const diff_time = self.remainingTime - self.audio_time;
            if (diff_time < 0) {
              alert(
                "利用可能時間を超過しています\n" +
                  "音声ファイルの総時間： " +
                  self.audio_time
              );
              self.onReset();
            }
          }
        };
      };
      if (!available.includes(self.ext)) {
        alert("対応していないファイル形式(拡張子)です");
        self.onReset();
      } else {
        self.splited_filename = type.join(".");
        self.situation = 1;
      }
      if (self.filesize > 125829120) {
        alert(
          "120MB以上の音声ファイルは文字起こしができません。音声ファイルを分割して実行してください。"
        );
        self.onReset();
      }
      self.file_loading = false;
    },

    uploadAudioFile(e) {
      let self = this;

      let now = new Date();
      let year = now.getFullYear();
      let month = ("0" + (now.getMonth() + 1)).slice(-2);
      let day = ("0" + now.getDate()).slice(-2);
      let hour = ("0" + now.getHours()).slice(-2);
      let minute = ("0" + now.getMinutes()).slice(-2);
      let second = ("0" + now.getSeconds()).slice(-2);
      let time = "" + year + month + day + hour + minute + second;
      self.filenamePlusTime =
        self.splited_filename + "-" + time + "." + self.ext;
      self.situation = 2;

      let storage = firebase.app().storage(process.env.VUE_APP_STORAGE_PATH);
      let dataRef = storage.ref(
        `${self.corpId}/${self.user_id}/tmp/original_audio/${self.filenamePlusTime}`
      );
      self.upload_loading = true;
      const importDropdownMenu = self.$refs.importDropdownMenu;
      const importButton = self.$refs.importButton;
      if (importDropdownMenu.classList.contains("show")) {
        importDropdownMenu.classList.remove("show");
        importButton.classList.remove("show");
      }
      let uploadTask = dataRef.put(self.uploadFile);

      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          let progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          self.progress_rate = parseInt(progress);
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              break;
          }
        },
        function (error) {
          // アップロード失敗時の処理
          self.upload_loading = false;
          alert(
            "不明なエラー(Error00)\n文字起こしに失敗しました。サービスが混み合っている可能性があります。時間をあけて再度お試しください。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
          );
          self.situation = 1;
        },
        function () {
          // GCSにアップロード後、文字起こし開始処理を行う
          let formData = new FormData();
          formData.append("file_name", self.filenamePlusTime);
          formData.append("audio_time", self.audio_time);
          formData.append("language", self.language);
          axios
            .post(
              process.env.VUE_APP_API_ENDPOINT +
                "/file-transcription/upload_and_transcript",
              formData
            )
            .then(function (res) {
              if (!res.data.status) {
                // 文字起こしの開始失敗時
                alert(res.data.message);
              }
              // アップロード成功時の処理
              self.upload_loading = false;
              self.situation = 3;
              self.searchFiles(false);
            })
            .catch(function (error) {
              // アップロード失敗時の処理
              self.upload_loading = false;
              alert(
                "不明なエラー(Error00)\n文字起こしに失敗しました。サービスが混み合っている可能性があります。時間をあけて再度お試しください。\n再度実行してもエラーになる場合はお問い合わせフォームからお問い合わせください。"
              );
              self.situation = 1;
            })
            .finally(() => {
              self.onReset();
            });
        }
      );
    },

    onReset() {
      const self = this;
      self.progress_rate = "";
      self.file_loading = false;
      self.filesize = 0;
      self.audio_time = "--- ";
      self.situation = 0;
      self.script_show = false;
      self.filename = "";
      self.splited_filename = "";
      self.ext = "";
      self.filenamePlusTime = "";
      self.record_situation = 0;
      self.record_count = 0;
      self.record_filename = "";
      self.localstream = "";
      self.mediaRecorder = null;
      self.recordedChunks = [];
      self.blob = null;
      self.script = "";
    },

    // システムお知らせ取得
    async getInitialInfo() {
      axios
        .get(
          process.env.VUE_APP_API_ENDPOINT +
            "/texta-basic-api/get_initial_info"
        )
        .then((response) => {
          if (response.data.status) {
            this.systemNotifications = response.data.system_notifications
            this.corpId = response.data.corp_id
            this.membersCount = response.data.members_count
          }
        })
        .catch((error) => {});
    },
  },
  watch: {
    dateFilter(newVal, oldVal) {
      // カレンダーの日付選択のフィルタ
      this.isDataFilteredByDate = true;
      if(newVal !== null) {
        this.dateFilterValue = newVal.toLocaleDateString("ja-JP", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        this.conditions.filter_created_at_from = this.dateFilterValue;
        this.conditions.filter_created_at_to = this.dateFilterValue;
        this.searchAllFiles();
      }
    },
  },
};
</script>

<style scoped>
.site-message {
  padding: 0 10px;
  height: 40px;
  width: calc(100%-40px);
  margin: 0 20px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.site-message-primary {
  background-color: var(--sub-primary);
  color: var(--sub-primary-text);
}

.site-message-warning {
  background-color: var(--sub-warning);
  color: var(--sub-warning-text);
}

.site-message-alert {
  background-color: var(--sub-alert);
  color: var(--sub-alert-text);
}

.site-message .material-symbols-outlined {
  line-height: 40px;
  font-size: 24px;
  font-weight: 300;
}

.site-message .message {
  line-height: 40px;
  font-size: 14px;
  padding-left: 10px;
}

.file-input {
  display: none;
}

.record-group {
  padding: 10px 20px;
  justify-content: space-between;
}

.language-selector,
.record-function-sm {
  height: 40px;
  border-radius: 3px;
  padding-left: 10px;
}

.language-selector .language-list {
  width: auto;
}

.language-selector label {
  line-height: 40px;
}

.language-selector .material-symbols-outlined,
.record-function-sm .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: -3px;
}

.usage-info {
  padding: 0 12px;
  background-color: var(--white);
  border-radius: 5px;
  width: 100%;
}

.usage-header {
  height: 35px;
}

.usage-header .usage-title {
  line-height: 44px;
  font-size: 16px;
  font-weight: bold;
}

.usage-header .usage-plan {
  padding-top: 10px;
}

.usage-header .usage-plan .plan-badge {
  height: 22px;
  font-size: 12px;
  color: var(--icon-target);
  font-weight: bold;
  padding: 2px 10px;
  border-radius: 3px;
  background-color: var(--actived);
}

.usage-description {
  font-size: 0.55em;
  color: var(--sub-text);
}

.usage-time,
.usage-member {
  background-color: var(--actived);
  border-radius: 3px;
  padding: 0 5px;
}

.usage-member {
  margin-bottom: 12px;
}

.using-label {
  padding: 1px 0;
  font-size: 0.75rem;
}

.using-remaind {
  color: var(--sub-text);
  padding: 2px 0;
  font-size: 0.7rem;
}

.using-remaind .material-symbols-outlined {
  font-size: 16px;
  vertical-align: -4px;
}

.using-remaind .material-symbols-outlined.person-icon {
  font-size: 14px;
  vertical-align: -3px;
}

.functions {
  padding: 0 20px;
  position: absolute;
  z-index: 1000;
  width: 67%;
}

.realtime-collapse {
  padding: 10px 0;
}

.realtime-collapse .recorder {
  background-color: var(--white);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 2px 10px 0 rgba(158, 159, 168, 0.7);
}

.method-switch-label {
  line-height: 18px;
}

.realtime-collapse .recorder .micro-record {
  color: var(--sub-text);
  font-size: 12px;
  line-height: 14px;
  border-bottom: solid 1px var(--sub-border);
}

.realtime-collapse .recorder .tab-record {
  color: var(--sub-text);
  font-size: 12px;
  line-height: 14px;
}

.realtime-collapse .recorder .micro-record .method-name,
.realtime-collapse .recorder .tab-record .method-name {
  color: var(--default-text);
  font-weight: bold;
}

.realtime-collapse .recorder .micro-record .record-start,
.realtime-collapse .recorder .tab-record .record-start {
  font-size: 14px;
}

.realtime-collapse .recorder .micro-record .material-symbols-outlined,
.realtime-collapse .recorder .tab-record .material-symbols-outlined {
  font-size: 20px;
}

.import-collapse {
  width: 600px;
  padding: 10px 0;
}

.import-collapse .uploader {
  background-color: var(--white);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 2px 2px 10px 0 rgba(158, 159, 168, 0.7);
}

.import-collapse .uploader .drag-section {
  height: 365px;
  background-color: var(--white);
  border-radius: 3px;
  border: 1px dotted !important;
}

.import-collapse
  .uploader
  .drag-section
  .material-symbols-outlined.upload-cloud {
  font-size: 65px;
  color: var(--actived);
}

.import-collapse .uploader .drag-section .upload-title {
  font-size: 14px;
  color: var(--default-text);
}

.import-collapse .uploader .drag-section .uploaded-info {
  background-color: var(--fade-background);
}

.import-collapse .uploader .drag-section .upload-description {
  font-size: 12px;
  color: var(--sub-text);
}

.import-collapse .uploader .drag-section:hover {
  border-color: var(--primary) !important;
}

.import-collapse
  .uploader
  .drag-section:hover
  .material-symbols-outlined.upload-cloud {
  color: var(--sub-primary);
}

.import-collapse .uploader .drag-section:hover .upload-title,
.import-collapse .uploader .drag-section:hover .upload-description {
  color: var(--primary);
}

.filename-title {
  max-width: 36ch;
}

.modal-title {
  display: inherit;
}

.sharing-search {
  border-bottom: solid 1px var(--actived);
}

.sharing-member-input {
  border: none;
}

.sharing-member-input:focus {
  box-shadow: none;
}

.record-tags {
  display: none;
}

.record-tags .options-icon {
  width: 16px;
  height: 16px;
}

.record-tags .options-icon .material-symbols-outlined {
  font-size: 12px;
}

.record-tags .options-icon .icon {
  width: 12px;
  height: 12px;
  vertical-align: baseline;
}

.file-importer.show,
.realtime-recorder.show {
  background-color: var(--actived) !important;
  color: var(--primary) !important;
}

.upload-body td{
  background-color: var(--fade-background);
}

.file-upload-progress {
  width: 135px;
  height: 40px;

  .progress-bar {
    background-color: var(--primary);
  }
}
.file-upload-progress.sm {
  height: 24px;
}
</style>
