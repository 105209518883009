<template>
  <div id="authAction" class="auth-action">
    <div class="auth-action-wrapper">
      <div class="logo">
        <img src="../../assets/img/texta-logo.svg" alt="" />
      </div>
      <div class="auth-action-form">
        <form v-if="isFormRequire === true" class="set-new-password">
          <div class="title mb-0 pb-0">パスワードの再設定</div>
          <p class="subtitle mt-0 pt-0">
            メールアドレス: <b>{{ email }}</b>
          </p>
          <hr class="opacity-25 border-secondary" />
          <div class="input-section">
            <label class="form-label input-label mb-1">パスワード </label>
            <p class="input-desc mb-1">
              12文字以上かつ『英大文字』『英小文字』『数字』『記号』の4種のうち3種以上必須
            </p>
            <input
              class="form-control form-input"
              type="password"
              v-model="new_password"
              autocomplete="new-password"
              minlength="12"
              required
            />
          </div>
          <div class="input-section my-2">
            <label class="form-label input-label mb-1"
              >新しいパスワード（確認）</label
            >
            <input
              class="form-control form-input"
              type="password"
              v-model="new_password_sub"
              minlength="12"
              required
            />
          </div>
          <div class="btn-column mb-3">
            <button
              @click="updateProfilePassword"
              type="button"
              class="reset-btn btn btn-primary"
            >
              保存
            </button>
          </div>
        </form>
        <template v-else>
          <div class="title mb-0 pb-0">
            {{ messageHeader }}
          </div>
          <hr class="opacity-25 border-secondary" />
          <p class="message">
            {{ messageContent }}
            <router-link :to="messageURL">
              <span>{{ messageLink }}</span>
            </router-link>
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import authentication from "../mixin/authentication";

export default {
  name: "AuthAction",
  mixins: [authentication],
  data() {
    return {
      email: "",
      actionInfo: "",
      actionCode: "",
      new_password: "",
      new_password_sub: "",
      messageHeader: "",
      messageContent: "",
      messageURL: "",
      messageLink: "",
      isFormRequire: false,
    };
  },
  computed: {},
  methods: {
    getActionInfo() {
      let self = this;
      this.actionCode = this.$route.query.oobCode;
      var auth = firebase.auth();
      auth
        .checkActionCode(this.actionCode)
        .then((response) => {
          self.actionInfo = response;
          self.email = self.actionInfo.data.email;
          self.applyAuthAction(self.actionInfo.operation);
        })
        .catch(() => {
          this.messageHeader = "再度確認してください";
          this.messageContent =
            "リクエストの期限が切れたか、リンクがすでに使用されています";
        });
    },
    applyAuthAction(actionOperation) {
      let self = this;
      var auth = firebase.auth();
      if (actionOperation === "VERIFY_EMAIL") {
        auth
          .applyActionCode(this.actionCode)
          .then(() => {
            this.messageHeader = "メールアドレスは確認済みです";
            this.messageContent =
              "新しいアカウントでログインできるようになりました";
          })
          .catch(() => {
            this.messageHeader = "メールアドレスを再度確認してください";
            this.messageContent =
              "メールアドレスの確認のリクエストの期限が切れたか、リンクがすでに使用されています";
          });
      } else if (actionOperation === "RECOVER_EMAIL") {
        auth
          .applyActionCode(this.actionCode)
          .then(() => {
            this.messageHeader = "メールアドレスを更新しました";
            this.messageContent =
              "ログイン メールアドレスが " +
              self.email +
              " に戻されました。 \nログイン メールアドレスの変更をリクエストしていない場合は、誰かがあなたのアカウントにアクセスしようとしている可能性があります。";
            this.messageLink = "今すぐパスワードを変更してください。";
            this.messageURL = "/setting";
          })
          .catch(() => {
            this.messageHeader = "メールアドレスを更新できません";
            this.messageContent =
              "ログイン メールアドレスを元に戻す際に問題が発生しました。 \n再度試してもメールアドレスを再設定できない場合は、管理者にお問い合わせください。";
          });
      } else if (actionOperation === "PASSWORD_RESET") {
        this.isFormRequire = true;
      }
    },
    updateProfilePassword(e) {
      let self = this;
      var auth = firebase.auth();
      if (this.new_password == this.new_password_sub) {
        const judge = this.validatePassword(this.new_password);
        if (!judge["status"]) {
          alert(judge["message"]);
          return false;
        } else {
          auth.verifyPasswordResetCode(self.actionCode).then(() => {
            auth
              .confirmPasswordReset(self.actionCode, self.new_password)
              .then(async () => {
                alert(
                  "パスワードが更新されました" +
                    "\n" +
                    "ログインページに移動します。"
                );
                this.$router.replace("/signin");
              })
              .catch((error) => {
                if (error.code == "auth/weak-password") {
                  alert(
                    "パスワードが弱いです。12文字以上かつ英字と数字を混ぜてください。"
                  );
                } else {
                  alert("パスワードの更新に失敗しました！");
                }
              });
          });
        }
      }
    },
  },
  mounted() {
    this.getActionInfo();
  },
};
</script>

<style scoped>
.auth-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100 * var(--screen-height));
  background-color: #f5f5f5;
}
.auth-action-wrapper {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.auth-action-form {
  padding: 25px;
  background-color: var(--white);
  width: 515px;
  border-radius: 10px;
  box-shadow: 30px 30px 50px 0 rgba(158, 159, 168, 0.7);
}
.set-new-password {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.password-input:focus {
  box-shadow: none !important;
  border: none;
}
.btn-column button {
  width: 100%;
  height: 45px;
  color: var(--white);
  font-size: 16px;
  font-weight: bold;
  border-radius: 0.375rem;
  background-color: var(--primary);
}

.reset-btn {
  background: #3f51b5;
  color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  height: 36px;
  line-height: 35px;
  padding: 1px 16px 0;
  border-radius: 2px;
  font-weight: 500;
  cursor: pointer;
}

.message {
  margin-top: 25px;
}
.input-desc {
  font-size: 0.73rem;
  color: var(--sub-text);
  line-height: 1rem;
}
</style>
