import firebase from "firebase/app";
import axios from 'axios';
import router from '@/router';

const axiosInstance = axios.create({
    withCredentials: true,
});

axiosInstance.interceptors.request.use(
    async config => {
        const userIdToken = await firebase.auth().currentUser.getIdToken();

        config.headers.Authorization = `Bearer ${userIdToken}`;

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        if (error.response) {
            const statusCode = error.response.status;
            const errorRoutes = {
                401: "/signin",
                404: "/404",
                500: "/500",
            };

            const route = errorRoutes[statusCode];
            if (route) {
                await Promise.resolve(router.push(route));
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;