<template>
  <div>
    <div class="meeting-minutes-content">
      <div v-if="isMeetingMinutesLoading" class="minutes-loading mt-4 text-center">
        <vue-element-loading
          background-color="rgba(255, 255, 255, .7)"
          :active="isMeetingMinutesLoading"
          :fullscreen="false"
          spinner="spinner"
          color="#7e858c"
        />
      </div>
      <div
        v-else-if="fileInfo.minutes_path == false"
        class="minutes-form ps-1 pe-3"
      >
        <div class="tab-button-group mt-0 mb-3 d-flex justify-content-between">
          <div class="right">
            <div class="language-selector border border-dark border-2 d-flex">
              <label for="minutesLanguageList">出力言語：</label>
              <select
                class="language-list form-select border-0"
                id="minutesLanguageList"
                aria-label=".form-select example"
                v-model="currentMeetingMinutesLanguage"
                @change="$emit('setMinutesLanguage', currentMeetingMinutesLanguage)"
              >
                <option selected value="日本語">日本語</option>
                <option value="英語">英語</option>
                <option value="中国語">中国語</option>
                <option value="香港">香港</option>
                <option value="台湾">台湾</option>
                <option value="韓国語">韓国語</option>
                <option value="ヒンディー語">ヒンディー語</option>
                <option value="スペイン語">スペイン語</option>
                <option value="ロシア語">ロシア語</option>
                <option value="ポルトガル語">ポルトガル語</option>
                <option value="フランス語">フランス語</option>
                <option value="ドイツ語">ドイツ語</option>
              </select>
            </div>
          </div>
          <div class="left">
            <button
              class="minutes-creater btn btn-primary"
              id="minutesCreateButton"
              @click="$emit('createMinutes')"
              :disabled="
                errors.inputMeetingName ||
                errors.inputParticipants ||
                errors.inputLocation ||
                errors.inputPurpose ||
                fileInfo.permission?.write_permission == false ||
                isPaidPlan == false
              "
            >
              <span class="material-symbols-outlined icon-fill">
                speech_to_text
              </span>
              <span class="btn-title">AI議事録作成</span>
            </button>
          </div>
        </div>
        <div class="mb-2">
          <label for="minutesFormInput1" class="form-label">会議タイトル</label>
          <input
            autocomplete="off"
            type="text"
            class="form-control minutes-input"
            id="minutesFormInput1"
            placeholder="例）経営会議"
            v-model="fileInfo.meeting_name"
          />
          <span v-if="errors.inputMeetingName" class="text-danger">{{
            errors.inputMeetingName
          }}</span>
        </div>
        <div class="mb-2">
          <label for="minutesFormInput2" class="form-label">開催日時</label>
          <div class="row">
            <div class="col col-12 col-md-5 mb-1">
              <input
                autocomplete="off"
                type="date"
                class="form-control minutes-input"
                placeholder="yyyy/mm/dd"
                id="minutesFormInput2"
                aria-label="開催日"
                v-model="fileInfo.meeting_date"
              />
            </div>
            <div class="col col-md-3">
              <input
                autocomplete="off"
                type="time"
                class="form-control minutes-input"
                placeholder="--:--"
                aria-label="開始"
                v-model="fileInfo.meeting_start_time"
              />
            </div>
            <div class="col col-1 text-center time-divider">〜</div>
            <div class="col col-md-3">
              <input
                autocomplete="off"
                type="time"
                class="form-control minutes-input"
                placeholder="--:--"
                aria-label="終了"
                v-model="fileInfo.meeting_end_time"
              />
            </div>
          </div>
        </div>
        <div class="mb-2">
          <label for="minutesFormInput3" class="form-label">参加者</label>
          <input
            autocomplete="off"
            type="text"
            class="form-control minutes-input"
            id="minutesFormInput3"
            placeholder="例）テキスタA、テキスタB"
            v-model="fileInfo.meeting_participants"
          />
          <span v-if="errors.inputParticipants" class="text-danger">{{
            errors.inputParticipants
          }}</span>
        </div>
        <div class="mb-2">
          <label for="minutesFormInput4" class="form-label">場所</label>
          <input
            autocomplete="off"
            type="text"
            class="form-control minutes-input"
            id="minutesFormInput4"
            placeholder="例）会議室A"
            v-model="fileInfo.meeting_location"
          />
          <span v-if="errors.inputLocation" class="text-danger">{{
            errors.inputLocation
          }}</span>
        </div>
        <div class="mb-2">
          <label for="minutesFormControlTextarea1" class="form-label"
            >目的</label
          >
          <textarea
            autocomplete="off"
            class="form-control minutes-input"
            id="minutesFormControlTextarea1"
            rows="3"
            placeholder="例）売り上げと目標の確認"
            v-model="fileInfo.meeting_purpose"
          ></textarea>
          <span v-if="errors.inputPurpose" class="text-danger">{{
            errors.inputPurpose
          }}</span>
        </div>
        <button
          class="minutes-creater-sm w-100 btn btn-primary"
          id="minutesCreateButtonSm"
          @click="$emit('createMinutes')"
          :disabled="
            errors.inputMeetingName ||
            errors.inputParticipants ||
            errors.inputLocation ||
            errors.inputPurpose ||
            fileInfo.permission?.write_permission == false ||
            isPaidPlan == false
          "
        >
          <span class="material-symbols-outlined icon-fill">
            speech_to_text
          </span>
          <span class="btn-title">AI議事録作成</span>
        </button>
      </div>
      <div v-else-if="!isMinutesEdit" class="minutes-result">
        <div
          class="
            tab-button-group
            mt-0
            d-flex
            justify-content-between
            result-button-group
          "
        >
          <div class="right">
            <button
              class="minutes-edit btn"
              id="minutesEditorButton"
              @click="$emit('editMinutes')"
              :disabled="
                fileInfo.permission?.write_permission == false
              "
            >
              <span class="material-symbols-outlined"> edit_square </span>
              <span class="btn-title">編集</span>
            </button>
            <button
              class="minutes-download btn"
              @click="$emit('downloadMinutes')"
            >
              <span class="material-symbols-outlined"> download </span>
              <span class="btn-title">ダウンロード</span>
            </button>
          </div>
          <div class="left">
            <button
              type="button"
              class="minutes-result-menu btn"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span class="material-symbols-outlined"> more_vert </span>
            </button>
            <ul class="details-dropdown dropdown-menu dropdown-menu-end">
              <li>
                <a
                  type="button"
                  class="dropdown-item py-2"
                  @click="$emit('copyMinutesToClipboard', 'plain')"
                >
                  <span class="material-symbols-outlined"> content_copy </span>
                  クリップボードへコピー
                </a>
              </li>
              <li>
                <a
                  type="button"
                  class="dropdown-item py-2"
                  @click="$emit('copyMinutesToClipboard', 'markdown')"
                >
                  <span class="material-symbols-outlined"> markdown_copy </span>
                  クリップボードへコピー（markdown）
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item py-2 details-remove"
                  type="button"
                  id="minutesRemoveButton"
                  @click="$emit('deleteMinutes')"
                  :class="{disabled: fileInfo.permission?.write_permission == false}"
                >
                  <span class="material-symbols-outlined"> ink_eraser </span>
                  議事録を取り消し
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="minutes-content result result_display-contents-summarize" v-html="meetingMinutesToHtml"></div>
      </div>
      <div v-else class="minutes-editor ps-1 pe-3">
        <div
          class="
            tab-button-group
            mt-0
            d-flex
            justify-content-between
            result-button-group
            editing
          "
        >
          <div class="right">
            <button class="minutes-cancel btn" id="minutesCancel" @click="$emit('cancelEditMinutes')">
              <span class="material-symbols-outlined"> undo </span>
              <span class="btn-title">戻る</span>
            </button>
            <button class="minutes-save btn" id="minutesSave" @click="$emit('saveEditMinutes')">
              <span class="material-symbols-outlined"> save </span>
              <span class="btn-title">保存</span>
            </button>
          </div>
        </div>
        <div class="minutes-content editing">
          <div v-if="isMeetingMinutesPreview" class="h-100 w-100 result_display-contents-summarize" v-html="meetingMinutesToHtml"></div>
          <textarea
            v-else
            class="form-control minutes-input w-100 h-100 overflow-y-auto"
            id="minutesEditor"
            v-model="currentMeetingMinutesContent"
            @input="editMinutesContent"
          ></textarea>
        </div>
        <div
          class="
            tab-button-group
            mt-0
            d-flex
            justify-content-between
            result-button-group
            editing
            text-toolbar
          "
        >
          <div class="right"></div>
          <div class="left">
            <button type="button" class="btn btn-sm me-1" @click="openMdHelpModal">
              <span class="material-symbols-outlined"> question_mark </span>
            </button>
            <button type="button" class="btn btn-sm border bg-white me-3 preview-btn" @click="previewMinutes">
              {{ isMeetingMinutesPreview ? "編集" : "プレビュー" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal modal-lg fade" ref="mdHelpModal" tabindex="-1" aria-labelledby="mdHelpModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content result_display-contents-summarize">
          <div class="modal-header fs-4 py-2">
            <h5 class="modal-title" id="mdHelpModalLabel">
              テキスト整形ルール
            </h5>
            <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body pt-3 pb-1">
            <div class="table-container">
              <table class="table table-bordered">
                <thead class="table-light">
                  <tr>
                    <th>ルール</th>
                    <th>整形前</th>
                    <th>整形後</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>太字</td>
                    <td>
                      **ABC**<br>
                      __abc__
                    </td>
                    <td>
                      <strong>ABC</strong><br>
                      <strong>abc</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>斜体</td>
                    <td>
                      *ABC*<br>
                      _abc_
                    </td>
                    <td>
                      <em>ABC</em><br>
                      <em>abc</em>
                    </td>
                  </tr>
                  <tr>
                    <td>太字&斜体</td>
                    <td>
                      ***ABC***<br>
                      ___abc___
                    </td>
                    <td>
                      <em><strong>ABC</strong></em><br>
                      <em><strong>abc</strong></em>
                    </td>
                  </tr>
                  <tr>
                    <td>取り消し線</td>
                    <td>
                      ~~ABC~~
                    </td>
                    <td>
                      <del>ABC</del>
                    </td>
                  </tr>
                  <tr>
                    <td>リンク</td>
                    <td>
                      [TEXTA](https://texta.cloud/)
                    </td>
                    <td>
                      <a href="https://texta.cloud/">TEXTA</a>
                    </td>
                  </tr>
                  <tr>
                    <td>外部参照リンク</td>
                    <td>
                      [TEXTA]: https://texta.cloud/<br>
                      [TEXTAを見る][TEXTA]
                    </td>
                    <td>
                      <a href="https://texta.cloud/">TEXTAを見る</a>
                    </td>
                  </tr>
                  <tr>
                    <td>箇条書き</td>
                    <td>
                      - リスト１<br>
                      &nbsp;&nbsp;- リスト１－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;- リスト１－１－１<br>
                      + リスト２<br>
                      &nbsp;&nbsp;+ リスト２－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;+ リスト２－１－１<br>
                      * リスト３<br>
                      &nbsp;&nbsp;* リスト３－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;* リスト３－１－１<br>
                    </td>
                    <td>
                      <ul>
                        <li>
                          リスト１
                          <ul>
                            <li>
                              リスト１－１
                              <ul>
                                <li>リスト１－１－１</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          リスト２
                          <ul>
                            <li>
                              リスト２－１
                              <ul>
                                <li>リスト２－１－１</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul>
                        <li>
                          リスト３
                          <ul>
                            <li>
                              リスト３－１
                              <ul>
                                <li>リスト３－１－１</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>番号付き箇条書き</td>
                    <td>
                      1. リスト１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－１－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－２<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－２－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト１－２－２<br>
                      1. リスト２<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;1. リスト２－１<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. リスト２－１－１<br>
                    </td>
                    <td>
                      <ol>
                        <li>
                          リスト１
                          <ol>
                            <li>
                              リスト１－１
                              <ol>
                                <li>リスト１－１－１</li>
                              </ol>
                            </li>
                            <li>
                              リスト１－２
                              <ol>
                                <li>リスト１－２－１</li>
                                <li>リスト１－２－２</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                        <li>
                          リスト２
                          <ol>
                            <li>
                              リスト２－１
                              <ol>
                                <li>リスト２－１－１</li>
                              </ol>
                            </li>
                          </ol>
                        </li>
                      </ol>
                    </td>
                  </tr>
                  <tr>
                    <td>見出し</td>
                    <td>
                      # 見出し１<br>
                      ## 見出し２<br>
                      ### 見出し３
                    </td>
                    <td>
                      <h1>見出し１</h1>
                      <h2>見出し２</h2>
                      <h3>見出し３</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>引用</td>
                    <td>
                      > 引用<br>
                      >> 多重引用
                    </td>
                    <td>
                      <blockquote>
                        引用
                        <blockquote>
                          多重引用
                        </blockquote>
                      </blockquote>
                    </td>
                  </tr>
                  <tr>
                    <td>水平線</td>
                    <td>
                      ___<br>
                      ***<br>
                      ---
                    </td>
                    <td>
                      <hr><br>
                      <hr><br>
                      <hr>
                    </td>
                  </tr>
                  <tr>
                    <td>表</td>
                    <td>
                      |&nbsp;AAAAA&nbsp;|&nbsp;BBBBB&nbsp;|&nbsp;CCCCC&nbsp;|<br>
                      |---|---|---|<br>
                      |&nbsp;a&nbsp;|&nbsp;b&nbsp;|&nbsp;c&nbsp;|<br>
                      |&nbsp;d&nbsp;|&nbsp;e&nbsp;|&nbsp;f&nbsp;|<br>
                      <br>
                      |&nbsp;左揃え&nbsp;|&nbsp;中央揃え&nbsp;|&nbsp;右揃え&nbsp;|<br>
                      |:---|:---:|---:|<br>
                      |&nbsp;a&nbsp;|&nbsp;b&nbsp;|&nbsp;c&nbsp;|<br>
                      |&nbsp;d&nbsp;|&nbsp;e&nbsp;|&nbsp;f&nbsp;|<br>
                    </td>
                    <td>
                      <table>
                        <thead>
                          <tr>
                            <th>AAAAA</th>
                            <th>BBBBB</th>
                            <th>CCCCC</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>a</td>
                            <td>b</td>
                            <td>c</td>
                          </tr>
                          <tr>
                            <td>d</td>
                            <td>e</td>
                            <td>f</td>
                          </tr>
                        </tbody>
                      </table>
                      <br>
                      <table>
                        <thead>
                          <tr>
                            <th align="left">左揃え</th>
                            <th align="center">中央揃え</th>
                            <th align="right">右揃え</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td align="left">a</td>
                            <td align="center">b</td>
                            <td align="right">c</td>
                          </tr>
                          <tr>
                            <td align="left">d</td>
                            <td align="center">e</td>
                            <td align="right">f</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>コード</td>
                    <td>
                      ```<br>
                      print 'hello'<br>
                      ```<br>
                      ~~~<br>
                      print 'hello'<br>
                      ~~~<br>
                      先頭に半角スペース4個<br>
                      &nbsp;&nbsp;&nbsp;&nbsp;print 'hello'
                    </td>
                    <td>
                      <pre><code>print 'hello'</code></pre>
                      <br>
                      <pre><code>print 'hello'</code></pre>
                      <br>
                      <pre><code>print 'hello'</code></pre>
                    </td>
                  </tr>
                  <tr>
                    <td>インラインコード</td>
                    <td>
                      これは`インラインコード`です
                    </td>
                    <td>
                      これは<code>インラインコード</code>です
                    </td>
                  </tr>
                  <tr>
                    <td>チェックリスト</td>
                    <td>
                      - [ ] リスト１<br>
                      - [x] リスト２
                    </td>
                    <td>
                      <ul>
                        <li>
                          <input type="checkbox" disabled>&nbsp;リスト１
                        </li>
                        <li>
                          <input type="checkbox" disabled checked>&nbsp;リスト２
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer py-1 border-0">
            <button type="button" class="btn" data-bs-dismiss="modal">
              閉じる
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: "MeetingMinutes",
  props: {
    isMeetingMinutesLoading: Boolean,
    fileInfo: Object,
    errors: Object,
    minutesLanguage: String,
    isMinutesEdit: Boolean,
    meetingMinutesToHtml: String,
    meetingMinutesContent: String,
    isPaidPlan: Boolean,
  },
  data() {
    return {
      currentMeetingMinutesContent: "",
      mdHelpModal: null,
      isMeetingMinutesPreview: false,
      currentMeetingMinutesLanguage: "日本語",
    };
  },
  mounted() {
    this.mdHelpModal = new Modal(this.$refs.mdHelpModal);
  },
  watch: {
    meetingMinutesContent: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentMeetingMinutesContent = newVal;
      }
    },
  },
  methods: {
    editMinutesContent() {
      this.$emit('editMinutesContent', this.currentMeetingMinutesContent);
    },
    openMdHelpModal() {
      this.mdHelpModal.show();
    },
    previewMinutes() {
      this.isMeetingMinutesPreview = !this.isMeetingMinutesPreview;
    },
  },
};
</script>

<style scoped>
.dropdown-item .material-symbols-outlined {
  vertical-align: -5px;
  font-size: 1.5rem;
}

.minutes-input::placeholder {
  font-size: 14px;
  color: var(--sub-text);
}

.time-divider {
  line-height: 38px;
}

.language-selector {
  height: 40px;
  border-radius: 3px;
  padding-left: 10px;
}

.language-selector .language-list {
  width: auto;
}

.language-selector label {
  line-height: 38px;
}

.language-selector .material-symbols-outlined {
  margin-right: 5px;
  font-size: 18px;
  font-weight: bold;
  vertical-align: -3px;
}

.minutes-content {
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 325px);
  max-height: calc(100 * var(--screen-height) - 325px);
}

.minutes-content.result {
  padding-left: 10px;
  overflow-y: auto;
  height: calc(100 * var(--screen-height) - 300px);
  max-height: calc(100 * var(--screen-height) - 300px);
}

.result-button-group {
  background-color: var(--background);
  border-radius: 3px;
}

.details-dropdown {
  font-size: 0.9rem;
}

.details-dropdown .material-symbols-outlined {
  vertical-align: middle;
  font-size: 1.3rem;
}

.details-dropdown .details-remove {
  color: var(--removed);
}

.details-dropdown .details-remove:active {
  color: var(--white);
  background-color: var(--removed);
}

.minutes-content textarea {
  overflow: hidden;
  border: 0;
  background-color: var(--editable);
  resize: none;
}

.result-button-group.editing {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.minutes-content.editing {
  max-height: calc(100 * var(--screen-height) - 335px);
}

.minutes-form,
.file-info {
  height: calc(100 * var(--screen-height) - 295px);
  overflow-y: auto;
}

.table-container {
  overflow-x: auto;
  height: calc(100% - 50px);
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}
.preview-btn {
  width: 90px;
}


.result_display-contents-summarize {
  --base-size-16: 0.015rem;
  text-align: left;
  padding: 0 20px 20px 20px;
  height: calc(100% - 30px);
  overflow-y: auto;
}

.result_display-contents-summarize :deep(table) {
  border-collapse: collapse;
  max-width: 100%;
}

.result_display-contents-summarize :deep(thead th) {
  background-color: #f2f2f2;
  font-weight: bold;
}

.result_display-contents-summarize :deep(th), 
.result_display-contents-summarize :deep(td) {
  border: 1px solid #000000;
  padding: 8px;
}

.result_display-contents-summarize :deep(ul) {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.result_display-contents-summarize :deep(li) {
  margin-left: 20px;
}

.result_display-contents-summarize :deep(ul > li::marker) {
  content: "• ";
}

.result_display-contents-summarize :deep(ul ul > li::marker) {
  content: "◦ ";
}

.result_display-contents-summarize :deep(ul ul ul > li::marker) {
  content: "▪ ";
}

.result_display-contents-summarize :deep(ol) {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.result_display-contents-summarize :deep(ol li) {
  list-style-type: decimal;
}

.result_display-contents-summarize :deep(ol ol li) {
  list-style-type: lower-roman;
}

.result_display-contents-summarize :deep(ol ol ol li) {
  list-style-type: lower-alpha;
}

.result_display-contents-summarize :deep(h1, h2, h3, h4, h5, h6) {
  margin-bottom: var(--base-size-16);
}

.result_display-contents-summarize :deep(p) {
  margin-bottom: var(--base-size-16);
}

.result_display-contents-summarize :deep(blockquote) {
    padding: 0 1em;
    color: #636c76;
    border-left: .25em solid #d0d7de;
    margin: 0;
}

.result_display-contents-summarize :deep(pre) {
    padding: var(--base-size-16);
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    color: #1f2328;
    background-color: #f6f8fa;
    border-radius: 6px;
    word-wrap: normal;
    margin-top: 0;
    margin-bottom: var(--base-size-16);
}

.result_display-contents-summarize :deep(pre code) {
    display: inline;
    max-width: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    line-height: inherit;
    word-wrap: normal;
    background-color: transparent;
    border: 0;
    font-size: 100%;
}

.result_display-contents-summarize :deep(pre>code) {
    padding: 0;
    margin: 0;
    word-break: normal;
    white-space: pre;
    background: transparent;
    border: 0;
}

.result_display-contents-summarize :deep(code) {
    padding: .2em .4em;
    margin: 0;
    font-size: 85%;
    white-space: break-spaces;
    background-color: #afb8c133;
    border-radius: 6px;
}

.result_display-contents-summarize-edit {
  padding: 0 0 0 0;
  height: calc(100% - 30px);
}

.result_display-contents-summarize-edit .summarize-edit {
  resize: none;
  height: 100%;
  width: 100%;
  padding: 0 0 0 20px;
}

.result_display-contents-summarize-edit .summarize-edit:focus {
  background-color: #fcfade;
}

.result_display-contents-translate {
  text-align: left;
  padding: 0 20px 20px 20px;
  height: calc(100% - 30px);
  overflow-y: auto;
}

.result_display-contents-translate pre {
    white-space: pre-wrap;
    font-family: -apple-system,BlinkMacSystemFont,游ゴシック体,YuGothic,Yu Gothic Medium,Helvetica Neue,Verdana,sans-serif;
    font-size: 14px;
    font-size: 1.4rem;
    text-align: left;
}

.result_display-contents {
  padding-left: 20px;
}
</style>
