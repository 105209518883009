<template>
  <div class="settings-content">
    <div class="content">
      <div class="tab-content" id="nav-tabContent">
        <div class="tab-pane fade active show" id="nav-payment-info-setting" role="tabpanel" aria-labelledby="nav-payment-info-setting-tab">
          <div class="page-title ps-0 mb-2">
            <h3>お支払い情報</h3>
          </div>
          <div class="setting-titles">
            <a
              class="settings-selector btn w-100 d-flex justify-content-between align-items-center"
              data-bs-toggle="collapse"
              href="#settingsCollapse"
              role="button"
              aria-expanded="false"
              aria-controls="settingsCollapse"
            >
              <h5 class="mb-0">設定: お支払い情報</h5>
              <span class="material-symbols-outlined fs-1"> keyboard_arrow_down </span>
            </a>
            <SettingCollapseList @componentChanged="changeComponent" currentComponent="PaymentManagement" />
            <hr class="mt-2" />
          </div>
          <div class="setting-item border-bottom-0">
            <div class="setting-name mb-3">
              <span class="fw-bold">ご請求の方法</span>
            </div>
            <div :class="{'collapse':!isRegister}" id="changePaymentCollapse">
              <div class="d-flex">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">取扱カードブランド</p>
                </div>
                <div class="col-5 d-flex align-items-center">
                  <img class="me-1" src="../../assets/img/visa.svg" alt="" />
                  <img class="me-1" src="../../assets/img/mastercard.svg" alt="" />
                  <img class="me-1" src="../../assets/img/jcb.svg" alt="" />
                  <img class="me-1" src="../../assets/img/american-express.svg" alt="" />
                </div>
              </div>
              <div class="d-flex my-2 user-payment-info">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">カード番号(半角数字)</p>
                </div>
                <div class="col pe-3 d-flex flex-column justify-content-between align-items-start">
                  <div class="input-group mb-2">
                    <form method="post" id="payment-form">
                      <div>
                        <div id="card-element" style="width: 30vw">
                          <!-- stripe elements  -->
                        </div>
                        <!-- stripe elements のエラー -->
                        <div v-if="!error_message" class="w-100 text-danger small" id="card-errors" role="alert">{{error_message}}</div>
                      </div>
                    </form>
                  </div>
                  <p class="subtitle fw-normal">
                    ご購入をもって<a class="text-primary fw-bold" target="_blank" href="https://texta.cloud/user-policy">利用規約</a>および<a
                      class="text-primary fw-bold"
                      target="_blank"
                      href="https://www.tcdigital.jp/assets/privacy.html"
                      >プライバシーポリシー</a
                    >に同意したものとみなします。<br />
                    この契約は自動的に更新されます。解約したい場合は次の契約更新日までに解約してください。
                  </p>
                  <button
                    v-if="isRegister"
                    @click="registerCard"
                    class="btn btn-outline-primary pt-2 w-100"
                    type="button"
                  >
                    支払い情報を登録する
                  </button>
                  <button
                    v-else
                    @click="updateCard"
                    class="btn btn-outline-primary pt-2 w-100"
                    data-bs-toggle="collapse"
                    href="#changePaymentCollapse"
                    type="button"
                    aria-expanded="false"
                    aria-controls="changePaymentCollapse"
                  >
                    支払い情報を変更する
                  </button>
                  <button
                    @click="hideEdit"
                    class="btn btn-outline-secondary pt-2 mt-1 w-100"
                    data-bs-toggle="collapse"
                    href="#changePaymentCollapse"
                    type="button"
                    aria-expanded="false"
                    aria-controls="changePaymentCollapse"
                  >
                    キャンセル
                  </button>
                </div>
              </div>
            </div>
            <div v-if="!isEdit && !isRegister" id="paymentInfo">
              <div class="d-flex user-payment-info">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">クレジットカード</p>
                </div>
                <div class="col d-flex flex-column justify-content-between align-items-start">
                  <p class="form-control border-0 m-0">{{ cardNumber }}</p>
                </div>
                <div class="col">
                  <button
                    @click="showEdit"
                    class="btn btn-outline-primary pt-2"
                    data-bs-toggle="collapse"
                    href="#changePaymentCollapse"
                    type="button"
                    aria-expanded="false"
                    aria-controls="changePaymentCollapse"
                  >
                    支払い情報を変更する
                  </button>
                </div>
              </div>
              <div class="d-flex my-2">
                <div class="col-5">
                  <p class="setting-label form-control border-0 px-0 m-0">有効期限</p>
                </div>
                <div class="col-5 d-flex flex-column justify-content-between align-items-start">
                  <p class="form-control border-0 m-0">{{ expMonth }}/{{ expYear }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// official
import { inject } from "vue";
import axios from "@/axios";
import "firebase/auth";
import "firebase/firestore";
import { usePlanStore } from '@/stores/index';
import { usePlanInfo } from "@/components/composables/planInfo";

// modules
import SettingCollapseList from "@/components/modules/SettingCollapseList.vue";

// mixin
import ArrangeDate from "@/components/mixin/ArrangeDate.js";
import BaseFunctions from "@/components/mixin/BaseFunctions.js";
import StripeFunc from "@/components/mixin/StripeFunc.js";

export default {
  mixins: [ArrangeDate, BaseFunctions, StripeFunc],
  name: "PaymentManagement",
  emits: ["componentChanged"],
  components: {
    SettingCollapseList,
  },
  data() {
    return {
      error_message: "",

      // 判定
      isEdit: false,

      // カード情報の登録
      stripe: "",
      items: [
        {
          sku: "sku_FdQKocNoVzznpJ",
          quantity: 1,
        },
      ],
      cardElement: "",

      // stripe
      cardNumber: "",
      expYear: "",
      expMonth: "",
    };
  },
  async created() {
    this.showLoading();
    if (!this.isRegister) {
      // カード情報がある場合、カード情報詳細画面を表示
      // stripe情報取得
      await this.getStripeInfo().then((res) => {
        if (res.status) {
          this.cardNumber = res.cardNumber;
          this.expYear = res.expYear;
          this.expMonth = res.expMonth;
        } else {
          alert("カード情報の取得に失敗しました。");
        }
      });
    }
    this.hideLoading();
  },

  async mounted() {
    this.createCardElement();
  },

  setup() {
    const loadingState = inject("loading");

    const showLoading = () => {
      loadingState.updateLoading(true);
    };

    const hideLoading = () => {
      loadingState.updateLoading(false);
    };

    const planStore = usePlanStore();
    const isRegister = !planStore.isCardRegistration;

    return {
      showLoading,
      hideLoading,
      isRegister,
    };
  },

  methods: {
    async showEdit() {
      this.isEdit = true;
    },
    async hideEdit() {
      if (this.isRegister) {
        this.returnEditPlan();
      } else {
        this.isEdit = false;
      }
    },

    async createCardElement() {
      const cardElementContainer = document.getElementById("card-element");
      if (cardElementContainer) {
        // DOM要素を空にする
        cardElementContainer.innerHTML = "";
      }
      await this.getStripe().then((res) => {
        // stripe と elements のインスタンス作成
        let stripe = res;
        this.stripe = stripe;
        let elements = res.elements();

        var style = {
          base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#aab7c4",
            },
          },
          invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
          },
        };

        // card Element のインスタンスを作成
        var card = elements.create("card", { style: style, hidePostalCode: true });

        // 要素にマウント
        card.mount("#card-element");

        // イベントリスナーを追加する
        card.addEventListener("change", (event) => {
          var displayError = document.getElementById("card-errors");
          if (event.error) {
            displayError.textContent = event.error.message;
          } else {
            displayError.textContent = "";
          }
        });
        // card element をメモリに残しておく
        this.card = card;
      });
    },

    // カード情報の更新
    async updateCard() {
      const self = this;
      this.showLoading();

      // SetupIntent作成
      let clientSecret;
      let customerId;
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/create_setup_intent")
        .then((response) => {
          if (response.data.status) {
            clientSecret = response.data.setup_intent.client_secret;
            customerId = response.data.setup_intent.customer;
          } else {
            alert("SetupIntentの作成に失敗しました。\n再度お試しください。");
            this.hideLoading();
            self.hideEdit();
            return;
          }
        })
        .catch(() => {
          alert("SetupIntentの作成に失敗しました。\n再度お試しください。");
          this.hideLoading();
          self.hideEdit();
          return;
        });

      // 3DS認証
      const { setupIntent, error } = await this.stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            address: {
              country: "JP",
            }
          }
        },
      });

      if (error) {
        alert(error.message);
        this.hideLoading();
        self.hideEdit();
        return;
      }

      // stripe と DB に登録
      const paymentMethod = setupIntent.payment_method;
      const params = {
        payment_method_id: paymentMethod,
      };
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_card", params)
        .then((response) => {
          if (response.data.status) {
            // 入力内容を表示用に保持
            this.getStripeInfo().then((res) => {
              if (res.status) {
                this.cardNumber = res.cardNumber;
                this.expYear = res.expYear;
                this.expMonth = res.expMonth;
              } else {
                alert("カード情報の取得に失敗しました。");
              }
            });
          } else {
            alert("カード情報の更新に失敗しました。\n再度お試しください。");
          }
        })
        .catch(() => {
          alert("カード情報の更新に失敗しました。\n再度お試しください。");
        });

      this.hideLoading();
      self.hideEdit();
      this.createCardElement();
    },

    changeComponent(componentName) {
      this.$emit("componentChanged", componentName);
    },

    // カード情報の登録
    async registerCard() {
      const self = this;
      this.showLoading();

      // SetupIntent作成
      let clientSecret;
      let customerId;
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/create_setup_intent")
        .then((response) => {
          if (response.data.status) {
            clientSecret = response.data.setup_intent.client_secret;
            customerId = response.data.setup_intent.customer;
          } else {
            alert("SetupIntentの作成に失敗しました。\n再度お試しください。");
            this.hideLoading();
            self.hideEdit();
            return;
          }
        })
        .catch(() => {
          alert("SetupIntentの作成に失敗しました。\n再度お試しください。");
          this.hideLoading();
          self.hideEdit();
          return;
        });

      // 3DS認証
      const { setupIntent, error } = await this.stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: this.card,
          billing_details: {
            address: {
              country: "JP",
            }
          }
        },
      });

      if (error) {
        alert(error.message);
        this.hideLoading();
        this.returnEditPlan();
        return;
      }

      // stripe と DB に登録
      const paymentMethod = setupIntent.payment_method;
      const params = {
        payment_method_id: paymentMethod,
      };
      await axios
        .post(process.env.VUE_APP_API_ENDPOINT + "/texta-basic-api/update_card", params)
        .then((response) => {
          if (response.data.status) {
            this.successRegisterCard();
          } else {
            this.failureRegisterCard();
          }
        })
        .catch(() => {
          alert("カード情報の更新に失敗しました。\n再度お試しください。");
        });

      this.hideLoading();
      this.returnEditPlan();
    },

    returnEditPlan() {
      this.changeComponent("EditPlan");
    },

    async successRegisterCard() {
      await usePlanInfo().getPlanInfo();
      alert("カード情報の登録に成功しました。\nプランを開始してください。");
      this.hideLoading();
      this.returnEditPlan();
    },

    async failureRegisterCard() {
      alert("カード情報の登録に失敗しました。\n再度お試しください。");
      this.hideLoading();
      this.returnEditPlan();
    },
  },
};
</script>

<style scoped>
.data-table tr:first-child th {
  background-color: transparent;
}
.data-table thead {
  background-color: var(--disabled);
}
.data-table thead tr th,
.data-table tbody tr td,
.dt-info {
  font-size: 0.9rem;
  line-height: 1rem;
}
tbody,
td,
tfoot,
th,
thead,
td {
  border-color: var(--actived);
}
.table-action {
  width: 2%;
}
.table-check {
  width: 2%;
}
.table-membername {
  width: 25%;
}
.table-updated-at,
.table-updated-by,
.table-created-by,
.table-created-at {
  width: 12%;
}
.data-table thead tr th:hover {
  outline: 2px solid var(--sub-border) !important;
  outline-offset: -2px;
}
.member-info.member-authority,
.member-info.member-files-count,
.member-info.member-updated-by,
.member-info.member-updated-at,
.member-info.member-created-by,
.member-info.member-created-at {
  color: var(--sub-text);
  font-size: 0.7rem;
}
.member-info.member-actions .dropdown-menu {
  font-size: 14px;
  color: var(--secondary);
}
.member-info.member-actions .dropdown-menu .material-symbols-outlined {
  font-size: 21px;
  vertical-align: -4px;
}

.files-filter {
  padding: 5px 10px 10px 0px;
}
.files-filter .filter {
  font-size: 14px;
  font-weight: bold;
  height: 34px;
  border-radius: 19px;
  background-color: var(--fade-background);
  align-items: center;
  margin-right: 10px;
}
.options-filter {
  margin-left: 5px;
}
.options-filter:focus {
  box-shadow: none;
}
.reset-opt {
  font-size: 22px;
  margin-left: 10px;
}
.select-title {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
  vertical-align: 5px;
}
.settings-list {
  width: 15%;
}
.settings-content {
  width: 85%;
}
.list-groups {
  font-size: 16px;
  padding: 0 5px;
}
.list-groups .group-title {
  color: var(--sub-text);
  padding-left: 15px;
}
.list-groups .group-items .item {
  padding: 8px 0;
  border: 0;
  padding-left: 15px;
  color: var(--default-text);
  border-radius: 5px;
}
.list-groups .group-items .item.active {
  background-color: transparent;
  font-weight: bold;
}
.list-groups .group-items .item .setting-icon {
  font-size: 21px;
  margin-right: 5px;
}
.list-groups .group-items .item .setting-title {
  padding-top: 4px;
}
.setting-item .setting-name {
  font-size: 16px;
}
.setting-item .text-secondary {
  font-size: 0.8rem;
}
.setting-item {
  padding-bottom: 25px;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--actived);
}
.setting-description {
  font-size: 0.73rem;
  color: var(--sub-text);
  display: inline-block;
  transform: translateY(-10px);
}

.email-verification {
  padding: 2px 10px 5px 10px;
  margin-right: 0.6rem;
  background-color: var(--sub-alert);
  border-radius: 5px;
}
.email-verification .setting-description {
  transform: translateY(0px);
  color: var(--sub-alert-text);
}

.input-group input {
  padding-left: 10px;
}
.input-group {
  border-radius: 5px;
}
.input-group button:hover {
  background-color: #fafbff;
}
.input-group:focus-within {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  border-radius: 4px;
}
.input-group .edit-setting {
  display: none;
}
.input-group:hover {
  background-color: var(--background);
}
.input-group:hover .edit-setting {
  display: block;
}
.input-group:focus-within .btn {
  background-color: var(--white);
  border: none !important;
}
.input-group:focus-within .profile-info-input {
  background-color: var(--white);
  border: none !important;
  box-shadow: none !important;
}
.input-group:focus-within .btn:hover {
  background-color: var(--white);
}
.profile-info-input:focus,
.profile-info-input:focus-visible {
  box-shadow: none !important;
  border: none !important;
  outline: none;
}
.usaging-progress {
  height: 30px;
}
.bg-remaining {
  background-color: var(--primary);
}
.bg-usaging {
  background-color: var(--sub-primary);
}
.bg-personal-plan {
  background-color: var(--shared);
}
.square-icon {
  width: 20px;
  height: 20px;
}
.material-symbols-outlined.usaging {
  color: var(--sub-primary);
}
.material-symbols-outlined.remaining {
  color: var(--primary);
}
.plan-detail {
  background-color: var(--fade-background);
}
.plan-card {
  border: none;
  border-top: 12px solid;
  height: calc(45 * var(--screen-height));
  min-height: 400px;
}
.plan-card .border-top {
  align-items: center;
}
.plan-card.recommend-plan .border-top {
  border-top: 1px solid var(--sub-primary-text) !important;
}
.plan-card p {
  font-size: 0.8rem;
}
.plan-card h5 {
  font-size: 1.6rem;
}
.plan-card.light {
  border-color: var(--hoved);
}
.plan-card.standard {
  border-color: var(--standard);
}
.plan-card.pro {
  border-color: var(--primary);
}
.plan-card.enterprise {
  border-color: var(--icon-target);
}
@media screen and (max-width: 1559px) {
  .settings-list {
    width: 20%;
  }
  .settings-content {
    width: 80%;
  }
}
</style>
