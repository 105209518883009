import { useFileShareStore } from '@/stores/index';

export function useFileShare() {
  const fileShareStore = useFileShareStore();

  const showFileShareModal = async (targetFileId) => {
    fileShareStore.setFileId(targetFileId);
  };

  return {
    showFileShareModal,
  };
}