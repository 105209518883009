import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/stylesheet/reset.css";
import "./assets/stylesheet/stylesheet.css";
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { createApp, h, configureCompat } from 'vue'
import App from "./App.vue";
import router from "./router";
import Firebase from "./firebase.js";
import { createPinia } from 'pinia';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
// 新デザイン対応により旧CSSは、コメントアウト
// import "./assets/stylesheet/common.css";
// import "./assets/stylesheet/icon.css";
import TextareaAutosize from "vue-textarea-autosize";
import VueElementLoading from "vue-element-loading";
import "./vee-validate";
import {
  AVPlugin,
  AVMedia,
} from "vue-audio-visual";
import VueVirtualScroller from 'vue-virtual-scroller'
import Toasted from 'vue-toasted';

Firebase.init();

const app = createApp({
  render: () => h(App)
});
const pinia = createPinia();

app.component("VueElementLoading", VueElementLoading)
app.use(router)
app.use(pinia)
app.use(VCalendar)
app.use(TextareaAutosize)
app.use(AVPlugin);
app.component("AVMedia", AVMedia);
app.use(VueVirtualScroller)
app.use(Toasted, {
  duration: 3000,
  position: 'top-center',
  theme: 'toasted-primary',
  iconPack : 'fontawesome',
  className: 'toasted-custom',
  containerClass: 'toasted-custom-container',
});

configureCompat({
  MODE: 3,
  ATTR_FALSE_VALUE: 'suppress-warning'
});

app.mount('#app');
